import {
    AUTO_LOGIN_LOADER_SUCCESS,
    AUTO_LOGIN_LOADER_FAILURE,
  } from "../constants/MyM";
  
  const initStat = { isAutoLogin: false };
  
  export default (state = initStat, action) => {
    switch (action.type) {
      case AUTO_LOGIN_LOADER_SUCCESS:
        return {
          ...state,
          isAutoLogin: action.payload
        };
      case AUTO_LOGIN_LOADER_FAILURE:
        return {
          ...state,
          isAutoLogin: action.payload
        };
      default:
        return { ...state };
    }
  };
  