import { GET_FACILITY_FOR_HOLIDAY_SUCCESS, GET_FACILITY_FOR_HOLIDAY_FAILURE } from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const getFacilityForHolidayListSuccess = (data) => {
  return {
    type: GET_FACILITY_FOR_HOLIDAY_SUCCESS,
    payload: data,
  };
};

const getFacilityForHolidayListFailure = (data) => {
  return {
    type: GET_FACILITY_FOR_HOLIDAY_FAILURE,
    payload: data,
  };
};

export const getFacilityForHoliday = () => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/get_facilities_for_manage_holiday`);
    if (!error) {
      return dispatch(getFacilityForHolidayListSuccess(result));
    }
    return dispatch(getFacilityForHolidayListFailure(result));
  };
};
