import React, { memo, useEffect, useCallback, useState, Fragment } from "react";
import {
  Table,
  Card,
  Menu,
  Form,
  Button,
  Select,
  Row,
  Col,
  Upload,
  Tooltip,
  Tabs,
} from "antd";
import { StickyContainer, Sticky } from "react-sticky";
import {
  getApiSingleDetails,
  getApiArrayDetails,
  isValidArray,
} from "../../../utils/MyMUtils";
import { getPublicLiability } from "../../../redux/actions/publicLiability.action";
import { getWorkCover } from "../../../redux/actions/workCover.action";
import { useDispatch, useSelector } from "react-redux";
import { EyeOutlined, MailOutlined, UploadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import WorkCover from "./workover"
import PublicLiability from "./publicLiability"

const { Option } = Select;
const { TabPane } = Tabs;

const BusinessInsurance = () => {
  const url = window.location.pathname;
  const [componentSize, setComponentSize] = useState("small");
  const [activeUrl, setActiveUrl] = useState("1");

  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };
  const history = useHistory();
  const dispatch = useDispatch();
 
  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar
          {...props}
          style={{ ...style, zIndex: 1, background: "#fff" }}
        />
      )}
    </Sticky>
  );

  return (
    <Card>
      <StickyContainer>
        <Tabs
          renderTabBar={renderTabBar}
          // activeKey={activeUrl}
          defaultActiveKey={activeUrl}
        >
          <TabPane tab="Public Liability" key="1">
            <PublicLiability />
          </TabPane>
          <TabPane tab="Work Cover" key="2">
            <WorkCover />
          </TabPane>
        </Tabs>
      </StickyContainer>
    </Card>
  );
};

export default memo(BusinessInsurance);
