import { GET_ALL_GROUP_SUCCESS, GET_ALL_GROUP_FAILURE } from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const getGroupSuccess = (data) => {
  return {
    type: GET_ALL_GROUP_SUCCESS,
    payload: data,
  };
};

const getGroupFailure = (data) => {
  return {
    type: GET_ALL_GROUP_FAILURE,
    payload: data,
  };
};

export const getGroupInfo = () => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/get_all_org_list`);
    if (!error) {
      return dispatch(getGroupSuccess(result));
    }
    return dispatch(getGroupFailure(result));
  };
};
