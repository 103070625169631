import {
  POST_UPLOAD_STATE_DECLARATION_SUCCESS,
  POST_UPLOAD_STATE_DECLARATION_FAILURE,
  POST_UPLOAD_STATE_DECLARATION_REQUEST,
} from "../constants/MyM";
import { postApi } from "../../utils/apiCall";
import { apiToastError, apiToastSuccess } from "../../utils/MyMUtils";

const uploadStateDeclarationSuccess = (data) => {
  return {
    type: POST_UPLOAD_STATE_DECLARATION_SUCCESS,
    payload: data,
  };
};

const uploadStateDeclarationFailure = (data) => {
  return {
    type: POST_UPLOAD_STATE_DECLARATION_FAILURE,
    payload: data,
  };
};

export const uploadStateDeclarationRequest = () => ({
  type: POST_UPLOAD_STATE_DECLARATION_REQUEST,
});

export const uploadStateDeclaration = (uploadInfo) => (dispatch, getState) => {
  dispatch(uploadStateDeclarationRequest());
  return postApi(`/upload_state_declare_certificate`, {
    ...uploadInfo,
  })
    .then((data) => {
      apiToastSuccess(data);
      return dispatch(uploadStateDeclarationSuccess(data));
    })
    .catch((error) => {
      apiToastError(error);
      return dispatch(uploadStateDeclarationFailure(error));
    });
};
