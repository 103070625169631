import {
  POST_NEW_CONTRACTOR_SUCCESS,
  POST_NEW_CONTRACTOR_FAILURE,
} from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const postNewContractorSuccess = (data) => {
  return {
    type: POST_NEW_CONTRACTOR_SUCCESS,
    payload: data,
  };
};

const postNewContractorFailure = (data) => {
  return {
    type: POST_NEW_CONTRACTOR_FAILURE,
    payload: data,
  };
};

export const postNewContractor = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await postApi(`/contractor_contact_details`, {
      ...data,
    });
    if (!error) {
      return dispatch(postNewContractorSuccess(result));
    }
    return dispatch(postNewContractorFailure(result));
  };
};
