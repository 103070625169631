import {
  GET_WORK_COVER_SUCCESS,
  GET_WORK_COVER_FAILURE,
} from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const workCoverSuccess = (data) => {
  return {
    type: GET_WORK_COVER_SUCCESS,
    payload: data,
  };
};

const workCoverFailure = (data) => {
  return {
    type: GET_WORK_COVER_FAILURE,
    payload: data,
  };
};

export const getWorkCover = () => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/get_workcover_certificate`);
    if (!error) {
      return dispatch(workCoverSuccess(result));
    }
    return dispatch(workCoverFailure(result));
  };
};
