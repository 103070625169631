import {
    GET_WORK_COVER_STATE_LIST_SUCCESS,
    GET_WORK_COVER_STATE_LIST_FAILURE,
  } from "../constants/MyM";
  
  const initStat = { data: [], error: false, errorData: "", isLoading: true };
  
  export default (state = initStat, action) => {
    switch (action.type) {
      case GET_WORK_COVER_STATE_LIST_SUCCESS:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
        };
      case GET_WORK_COVER_STATE_LIST_FAILURE:
        return {
          ...state,
          isLoading: false,
          errorData: action.payload,
          error: true,
        };
      default:
        return { ...state };
    }
  };
  