import { FILE_LOADING_STATE } from "../constants/MyM";

const initStat = { fileLoadingState: '' };

export default (state = initStat, action) => {
  switch (action.type) {
    case FILE_LOADING_STATE:
      return {
        fileLoadingState: action.payload,
      }
    default:
      return { ...state };
  }
};
