import { getAllRequiredHeaders } from "./MyMUtils";
import { API_SUCCESS } from "constants/ApiConstant";
import client from "./interceptors/axios";

export const postApi = async (url, data) => {
  try {
    const result = await client.post(`${url}`, data, {
      headers: {...getAllRequiredHeaders({ru: url})}
    });
    if (result.status === API_SUCCESS) {
      return { error: false, result: result.data };
    }
    return { error: true, result: [] };
  } catch (error) {
    return { error: true, result: [] };
  }
};

export const putApi = async (url, data) => {
  try {
    const result = await client.put(`${url}`, data, {
      headers: {...getAllRequiredHeaders({ru: url})}
    });
    if (result.status === API_SUCCESS) {
      return { error: false, result: result.data };
    }
    return { error: true, result: [] };
  } catch (error) {
    return { error: true, result: [] };
  }
};

export const getApi = async (url) => {
  try {
    const result = await client.get(`${url}`, {
      headers: {...getAllRequiredHeaders({ru: url})}
    });
    if (result.status === API_SUCCESS) {
      return { error: false, result: result.data };
    }
    return { error: true, result: [] };
  } catch (error) {
    return { error: true, result: [] };
  }
};

export const deleteApi = async (url) => {
  try {
    const result = await client.delete(`${url}`, {
      headers: {...getAllRequiredHeaders({ru: url})}
    });
    if (result.status === API_SUCCESS) {
      return { error: false, result: result.data };
    }
    return { error: true, result: [] };
  } catch (error) {
    return { error: true, result: [] };
  }
};
