import { combineReducers } from "redux";
import Theme from "./Theme";
import Login from "./login.reducer";
import UserProfile from "./userProfile.reducer";
import Group from "./group.reducer";
import Facilities from "./facility.reducer";
import SetNewPassword from "./setNewPassword.reducer";
import ForgotPassword from "./forgotPassword.reducer";
import EditUserProfile from "./editProfile.reducer";
import PublicLiability from "./publicLiability.reducer";
import WorkCover from "./workCover.reducer";
import UploadStatement from "./uploadStatement.reducer";
import Survey from "./survey.reducer";
import Cleaning from "./cleaning.reducer";
import Laundry from "./laundry.reducer";
import VaccinationFacility from "./vacination.reducer";
import PoliceStatement from "./policestatement.reducer";
import CovidStatement from "./covid.reducer";
import Contractor from "./contractor.reducer";
import Management from "./management.reducer";
import Vehicale from "./vehicale.reducer";
import ContactFacility from "./contactFacility.reducer";
import StateDeclaration from "./stateDeclaration.reducer";
import UploadCertificate from "./uploadCertificate.reducer";
import UploadStateDeclaration from "./uploadStateDeclaration.reducer";
import PostNewContractorInfo from "./postNewContractor.reducer";
import UpdateContractorInfo from "./updateContractor.reducer";
import PostNewManagerInfo from "./postNewManager.reducer";
import UpdateManagerInfo from "./updateManager.reducer";
import StateList from "./stateList.reducer";
import UploadPoliceCheck from "./uploadPoliceCheck.reducer";
import PoliceCheckCertificate from "./policeCheck.reducer";
import GetUserList from "./getUserList.reducer";
import GetVaccineCertificate from "./vaccinationCertificate.reducer";
import GetCovidVaccine from "./covidVaccine.reducer";
import UploadLAbourHire from "./uploadLaboureHire.reducer";
import DeleteContractor from "./deleteContractor.reducer";
import DeleteManager from "./deleteManager.reducer";
import CovidVaccineCertificate from "./covidVaccineCertificate.reducer";
import isoCertificate from "./uploadIsoCertificate.reducer";
import StatementPolice from "./statementPolice.reducer";
import PostNewVehical from "./postVehical.reducer";
import UpdateVehicle from "./updateVehicle.reducer";
import DeleteVehical from "./deleteVehical.reducer";
import GetStaffUserManagement from "./getStaffUserManagement.reducer";
import GetUserManagementModule from "./userManagementModule.reducer";
import GetRole from "./getRole.reducer";
import GetFacilityByModule from "./getFacilityByModule.reducer";
import PostNewFacilityInfo from "./postNewFacility.reducer";
import UpdateFacilityInfo from "./updateFacility.reducer";
import DeleteFacility from "./deleteFacility.reducer";
import GetManagerList from "./getManager.reducer";
import GetExistStaffUserManagement from "./getExistStaffUserManagement.reducer";
import GetUserWiseFacility from "./getUserWiseFacility.reducer";
import GetKpiReport from "./getKpiReport.reducer";
import PostNewStaffReducer from "./postNewStaff.reducer";
import GetKpiField from "./getKpifieldDetail.reducer";
import DeleteKpiReport from "./deleteKpiReport.reducer";
import PostKpiReport from "./postKpiReport.reducer";
import PostNewGroupInfo from "./postGroup.reducer";
import DeleteGroupInfo from "./deleteGroup.reducer";
import UpdateGroupInfo from "./updateGroup.reducer";
import GetGroupInfo from "./getGroup.reducer";
import DeleteUserInfo from "./deleteUser.reducer";
import GetExistEmailInfo from "./getEmailExist.reducer";
import GetEducationReport from "./getEducationreport.reducer";
import GetExistEmailAtEditInfo from "./getEmailExistAtEdit.reducer";
import GetEducationList from "./getEducationList.reducer";
import GetEducationRegister from "./getEducationRegister.reducer";
import PostMail from "./sendmail.reducer";
import PostMonthlyEducation from "./postMonthlyEducation.reducer";
import GetAllCleaning from "./allCleaningList.reducer";
import GetAllLaundry from "./allLaundryList.reducer";
import PostSurveyUploadPdf from "./postSurveyUploadPdf.reducer";
import GetStaffList from "./getStaffList.reducer";
import GetStaffListOrg from "./getStaffListOrg.reducer";
import PostAutoLogin from "./postAutoLogin.reducer";
import GetMeetingData from "./getMeetingMinutes.reducer"
import PostMeetingData from "./postMeetingMinutes.reducer"
import DeleteMeetingMinutesData from "./deleteMeetingMinutes.reducer"
import GetFacilityUserList from "./getFacilityUserById.reducer"
import GetEquipmentReport from "./getEquipmentReport.reducer"
import UploadTimeshhetData from "./uploadTimesheet.reducer"
import DeleteTimesheetData from "./deleteTimesheet.reducer"
import GetTimesheetData from "./getuploadTimesheet.reducer"
import DeleteWorkcoverRecord from "./deleteWorkcover.reducer"
import ServiceType from "./getServiceType.reducer"
import ContractedHours from "./postContractedHours.reducer"
import GetContractedHours from "./getContractedHours.reducer"
import GetContractedHoursById from "./getContractedHoursById.reducer"
import DeleteContractedHours from "./deleteContractedHours.reducer"
import UpdateContractedHours from "./updateContractedHours.reducer"
import GetHolidayListData from "./getHolidayList.reducer"
import PostHoliday from "./postHoliday.reducer"
import DeleteHoliday from "./deleteHoliday.reducer"
import UpdateHoliday from "./updateHoliday.reducer"
import GetStateList from "./getAllStateList.reducer"
import GetFacilityList from "./getAllFacilityList.reducer"
import GetNationList from "./getAllNationList.reducer"
import PostManageHoliday from "./postManageHoliday.reducer"
import GetManageHoliday from "./getManageHoliday.reducer"
import GetFacilityForHolidayList from "./getFacilityForHoliday.reducer"
import DeleteManageFacility from "./deleteManageHoliday.reducer"
import HolidayListForManageHoliday from "./getHolidayListForManageHoliday.reducer"
import GetAdhocFacility from "./getAllAdhocFacility.reducer"
import DeleteAdhocFacility from "./deleteAdhocFacility.reducer"
import PostAdhocFacility from "./postAdhocFacility.reducer"
import UpdateAdhocFacility from "./updateAdhocFacility.reducer"
import GetExtraHoursCategoryList from "./getExtraHoursCategory.reducer"
import PostExtraHoursList from "./postExtraHours.reducer"
import GetExtraHoursDetail from "./getExtraHours.reducer"
import GetOutstandingFacility from "./postOutstandingFacility.reducer"
import DeleteExtraHours from "./deleteExtraHours.reducer"
import AddCommunicationLogin from "./postCommunicationLogin.reducer"
import GetCommunicationLogin from "./getCommunicationLogin.reducer"
import DeleteCommunicationLogin from "./deleteCommunicationLogin.reducer"
import UpdateCommunicationLogin from "./updateCommunicationLogin.reducer"
import postAgencyStaffList from "./postAgencyStaff.reducer"
import GetAgencyStaffList from "./getAgencyStaff.reducer"
import fileLoadingReducer from "./fileLoading.reducer";
import addNewStaffReducer from "./addNewStaff.reducer";
import autoLoginLoaderReducer from "./autoLoginLoader.reducer";
import getAllAirPurifierData from "./getAllAirPurifierData.reducer"
import allStateListForHolidayReducer from "./allStateListForHoliday.reducer";
import workCoverStateListReducer from "./workCoverStateList.reducer";

const reducers = combineReducers({
  theme: Theme,
  userLogin: Login,
  userProfile: UserProfile,
  GroupList: Group,
  FacilitiesList: Facilities,
  setNewPassword: SetNewPassword,
  forgotPassword: ForgotPassword,
  editUserProfile: EditUserProfile,
  publicLiability: PublicLiability,
  workCover: WorkCover,
  uploadStatement: UploadStatement,
  surveyList: Survey,
  laundryList: Laundry,
  cleaningList: Cleaning,
  vaccinationFacility: VaccinationFacility,
  policeStatement: PoliceStatement,
  covidStatement: CovidStatement,
  contractot: Contractor,
  management: Management,
  vehicale: Vehicale,
  contactFacility: ContactFacility,
  stateDeclaration: StateDeclaration,
  uploadCertificate: UploadCertificate,
  uploadStateDeclaration: UploadStateDeclaration,
  postNewContractorInfo: PostNewContractorInfo,
  updateContractorInfo: UpdateContractorInfo,
  postNewManagerInfo: PostNewManagerInfo,
  updateManagerInfo: UpdateManagerInfo,
  stateList: StateList,
  uploadPoliceCheck: UploadPoliceCheck,
  policeStatement: PoliceCheckCertificate,
  getUserList: GetUserList,
  getVaccineCertificate: GetVaccineCertificate,
  getCovidVaccine: GetCovidVaccine,
  uploadLabourHire: UploadLAbourHire,
  deleteContractor: DeleteContractor,
  deleteManager: DeleteManager,
  covidVaccineCertificate: CovidVaccineCertificate,
  isoCertificate: isoCertificate,
  statementPolice: StatementPolice,
  postNewVehical: PostNewVehical,
  updateVehicle: UpdateVehicle,
  deleteVehical: DeleteVehical,
  getStaffUserManagement: GetStaffUserManagement,
  getUserManagementModule: GetUserManagementModule,
  getRole: GetRole,
  getFacilityByModule: GetFacilityByModule,
  postNewFacilityInfo: PostNewFacilityInfo,
  updateFacilityInfo: UpdateFacilityInfo,
  deleteFacility: DeleteFacility,
  getManagerList: GetManagerList,
  getExistStaffUserManagement: GetExistStaffUserManagement,
  getUserWiseFacility: GetUserWiseFacility,
  getKpiReport: GetKpiReport,
  postNewStaffReducer: PostNewStaffReducer,
  getKpiField: GetKpiField,
  deleteKpiReport: DeleteKpiReport,
  postKpiReport: PostKpiReport,
  postNewGroupInfo: PostNewGroupInfo,
  deleteGroupInfo: DeleteGroupInfo,
  updateGroupInfo: UpdateGroupInfo,
  getGroupInfo: GetGroupInfo,
  deleteUserInfo: DeleteUserInfo,
  getExistEmailInfo: GetExistEmailInfo,
  getEducationReport: GetEducationReport,
  getExistEmailAtEditInfo: GetExistEmailAtEditInfo,
  getEducationList: GetEducationList,
  getEducationRegister: GetEducationRegister,
  postMail: PostMail,
  postMonthlyEducation: PostMonthlyEducation,
  getAllCleaning: GetAllCleaning,
  getAllLaundry: GetAllLaundry,
  postSurveyUploadPdf: PostSurveyUploadPdf,
  getStaffList: GetStaffList,
  getStaffListOrg: GetStaffListOrg,
  postAutoLogin: PostAutoLogin,
  getMeetingData:GetMeetingData,
  postMeetingData:PostMeetingData,
  deleteMeetingMinutesData:DeleteMeetingMinutesData,
  getFacilityUserList:GetFacilityUserList,
  getEquipmentReport:GetEquipmentReport,
  uploadTimeshhetData:UploadTimeshhetData,
  deleteTimesheetData:DeleteTimesheetData,
  getTimesheetData:GetTimesheetData,
  deleteWorkcoverRecord:DeleteWorkcoverRecord,
  ServiceType:ServiceType,
  contractedHours:ContractedHours,
  getContractedHours:GetContractedHours,
  getContractedHoursById:GetContractedHoursById,
  deleteContractedHours:DeleteContractedHours,
  updateContractedHours:UpdateContractedHours,
  getHolidayListData:GetHolidayListData,
  PostHoliday:PostHoliday,
  deleteHoliday:DeleteHoliday,
  updateHoliday:UpdateHoliday,
  getStateList:GetStateList,
  getFacilityList:GetFacilityList,
  getNationList:GetNationList,
  postManageHoliday:PostManageHoliday,
  getManageHoliday:GetManageHoliday,
  getFacilityForHolidayList:GetFacilityForHolidayList,
  deleteManageFacility:DeleteManageFacility,
  holidayListForManageHoliday:HolidayListForManageHoliday,
  getAdhocFacility:GetAdhocFacility,
  deleteAdhocFacility:DeleteAdhocFacility,
  postAdhocFacility:PostAdhocFacility,
  updateAdhocFacility:UpdateAdhocFacility,
  getExtraHoursCategoryList:GetExtraHoursCategoryList,
  postExtraHoursList:PostExtraHoursList,
  getExtraHoursDetail:GetExtraHoursDetail,
  getOutstandingFacility:GetOutstandingFacility,
  deleteExtraHours:DeleteExtraHours,
  AddCommunicationLogin:AddCommunicationLogin,
  getCommunicationLogin:GetCommunicationLogin,
  deleteCommunicationLogin:DeleteCommunicationLogin,
  updateCommunicationLogin:UpdateCommunicationLogin,
  postAgencyStaffList:postAgencyStaffList,
  getAgencyStaffList:GetAgencyStaffList,
  fileLoadingReducer: fileLoadingReducer,
  addNewStaffReducer: addNewStaffReducer,
  autoLoginReducer: autoLoginLoaderReducer,
  allAirPurifierReducer: getAllAirPurifierData,
  holidayStateList: allStateListForHolidayReducer,
  workCoverStateList: workCoverStateListReducer
});

export default reducers;
