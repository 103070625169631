import { UPDATE_FACILITY_SUCCESS, UPDATE_FACILITY_FAILURE } from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const updateFacilitySuccess = (data) => {
  return {
    type: UPDATE_FACILITY_SUCCESS,
    payload: data,
  };
};

const updateFacilityFailure = (data) => {
  return {
    type: UPDATE_FACILITY_FAILURE,
    payload: data,
  };
};

export const updateFacility = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await postApi(`/add_fac_details_mym_web`, {
      ...data,
    });
    if (!error) {
      return dispatch(updateFacilitySuccess(result));
    }
    return dispatch(updateFacilityFailure(result));
  };
};
