import React, { useEffect, useState } from "react";
import { Button, Row, Col, Divider, Radio, message, Form } from "antd";
import { RightSquareOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getRoleList } from "redux/actions/getRole.action";
import { getApiArrayDetails, isValidArray } from "../../../utils/MyMUtils";

function Step1(props) {
  const [role, setRole] = useState();
  const dispatch = useDispatch();

  const getRoleData = useSelector((state) => getApiArrayDetails(state.getRole.data));

  useEffect(() => {
    dispatch(getRoleList());
  }, []);

  const onChange = (e) => {
    props.selectRole(e.target.value);
  };

  const next = () => {
    if (props.passData.role > 0) {
      props.next();
    } else {
      message.error("Please Select Role");
    }
  };

  return (
    <>
      <div className="steps-content">
        <p></p>
        <Divider style={{ fontSize: "22px" }}>Select Role</Divider>
        <p></p>
        <Form hideRequiredMark>
          <Row gutter={24} style={{ marginLeft: "0px", marginRight: "0px" }} className="mt-4">
            <Radio.Group
              onChange={onChange}
              style={{ width: "100%", display: "contents" }}
              value={props.passData.role}
            >
              {getRoleData &&
                getRoleData.map((role) => {
                  return (
                    <Col lg={12} md={12} sm={24} xs={24}>
                      <div className="outer-radio-role">
                        <Radio value={role.role_id} className="d-flex justify-content-start">
                          {role.role_name}
                        </Radio>
                      </div>
                    </Col>
                  );
                })}
            </Radio.Group>
          </Row>
        </Form>
      </div>

      <Row gutter={24} className="mt-2">
        <Col lg={12} md={12} sm={24} xs={24}></Col>
        <Col lg={12} md={12} sm={24} xs={24} className="text-right">
          <Button shape="round" icon={<RightSquareOutlined />} onClick={next}>
            Next
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default Step1;
