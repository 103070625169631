import React, { memo, useEffect, useCallback, useState, Fragment } from "react";
import {
  Table,
  Card,
  Menu,
  Form,
  Button,
  Select,
  Row,
  Col,
  Upload,
  Tabs,
  message,
  Tooltip,
} from "antd";
import { StickyContainer, Sticky } from "react-sticky";
import { getApiArrayDetails, isValidArray } from "../../../utils/MyMUtils";
import { useDispatch, useSelector } from "react-redux";
import { EyeOutlined, MailOutlined, UploadOutlined } from "@ant-design/icons";
import { getPoliceStatement } from "redux/actions/policestatement.action";
import { uploadStatement } from "redux/actions/uploadStatement.action";
import { getContractorData } from "redux/actions/contractor.action";

const { Option } = Select;
const { TabPane } = Tabs;

const LasaCertificate = () => {
  const [facilityName, setFacilityName] = useState("");
  const [facilityId, setFacilityId] = useState("");

  const dispatch = useDispatch();

  const contractorList = useSelector((state) =>
    getApiArrayDetails(state.contractot.data)
  );

  useEffect(() => {
    dispatch(getContractorData());
  }, []);

  const uploadSettings = {
    accept: ".pdf",
    beforeUpload: (file) => {
      if (file.type !== "application/pdf") {
        message.error(`${file.name} is not a pdf file`);
      }
      uploadStatement(file.type !== "application/pdf" ? false : true);
      return false;
    },
    onChange: (info) => {
      let selectedFile = info.fileList;
      let file = null;
      let fileName;
      if (selectedFile.length > 0) {
        let fileToLoad = selectedFile[0].originFileObj;
        fileName = fileToLoad.name;
        let reader = new FileReader();
        reader.onload = function (fileEvent) {
          file = fileEvent.target.result;
          let name = fileName.split(".");
          const uploadInfo = {
            facility_id: facilityId,
            file: file,
            filename: name[0],
            facility_name: facilityName,
          };

          dispatch(uploadStatement(uploadInfo));
        };
        reader.readAsDataURL(fileToLoad);
      }
    },
  };

  const facilityFunction = (elm) => {
    setFacilityName(elm?.facility_name);
    setFacilityId(elm?.facility_id);
  };

  const columns = [
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
      sorter: true,
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      sorter: true,
    },
    {
      title: "Contact Person",
      dataIndex: "contacted_person",
      key: "contacted_person",
      sorter: true,
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    //   sorter: true,
    // },
    // {
    //   title: "Number",
    //   dataIndex: "number",
    //   key: "number",
    //   sorter: true,
    // },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, elm, row) => (
        <div>
          <span className="mr-3">
            <Tooltip title="View" color="rgb(20, 72, 129)">
              <Button>
                <EyeOutlined />
              </Button>
            </Tooltip>
          </span>
        </div>
      ),
    },
  ];

  return (
    <Form layout="horizontal">
      {/* <Table
        columns={columns}
        dataSource={contractorList}
        rowKey="id"
      /> */}
    </Form>
  );
};

export default memo(LasaCertificate);
