import { DELETE_MEETING_MINUTES_SUCCESS, DELETE_MEETING_MINUTES_FAILURE } from "../constants/MyM";
import { deleteApi } from "../../utils/apiCall";

const deleteMeetingSuccess = (data) => {
  return {
    type: DELETE_MEETING_MINUTES_SUCCESS,
    payload: data,
  };
};

const deleteMeetingFailure = (data) => {
  return {
    type: DELETE_MEETING_MINUTES_FAILURE,
    payload: data,
  };
};

export const deleteMeeting = (id) => {
  return async (dispatch, getState) => {
    const { error, result } = await deleteApi(`/delete_meeting_minutes/${id}`);
    if (!error) {
      return dispatch(deleteMeetingSuccess(result));
    }
    return dispatch(deleteMeetingFailure(result));
  };
};
