import {
  GET_STAFF_USER_MANAGEMENT_SUCCESS,
  GET_STAFF_USER_MANAGEMENT_FAILURE,
} from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const getStaffUserManagementSuccess = (data) => {
  return {
    type: GET_STAFF_USER_MANAGEMENT_SUCCESS,
    payload: data,
  };
};

const getStaffUserManagementFailure = (data) => {
  return {
    type: GET_STAFF_USER_MANAGEMENT_FAILURE,
    payload: data,
  };
};

export const getStaffUserManagement = () => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(
      `/get_all_staff_user_management?type=new`
    );
    if (!error) {
      return dispatch(getStaffUserManagementSuccess(result));
    }
    return dispatch(getStaffUserManagementFailure(result));
  };
};
