import React, { memo } from "react";
import {
  Form,

} from "antd";
import ExtraHoursTable from "./dynamicTable"
import ExtraHoursForm from "./dynamicForm"




const ExtraHours = () => {


  return (
    <Form layout="horizontal">
      {/* <ExtraHoursTable /> */}
      <ExtraHoursForm />
    </Form>
  );
};

export default memo(ExtraHours);
