import { GET_ALL_NATION_LIST_SUCCESS, GET_ALL_NATION_LIST_FAILURE } from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const getAllNationListSuccess = (data) => {
  return {
    type: GET_ALL_NATION_LIST_SUCCESS,
    payload: data,
  };
};

const getAllNationListFailure = (data) => {
  return {
    type: GET_ALL_NATION_LIST_FAILURE,
    payload: data,
  };
};

export const getCountryInfo = () => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/get_all_countries_list`);
    if (!error) {
      return dispatch(getAllNationListSuccess(result));
    }
    return dispatch(getAllNationListFailure(result));
  };
};
