import { POST_EXTRA_HOURS_SUCCESS, POST_EXTRA_HOURS_FAILURE } from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const postExtraHoursSuccess = (data) => {
  return {
    type: POST_EXTRA_HOURS_SUCCESS,
    payload: data,
  };
};

const postExtraHoursFailure = (data) => {
  return {
    type: POST_EXTRA_HOURS_FAILURE,
    payload: data,
  };
};

export const postExtraHours = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await postApi(`/add_facility_extra_hours`, {
      ...data,
    });
    if (!error) {
      return dispatch(postExtraHoursSuccess(result));
    }
    return dispatch(postExtraHoursFailure(result));
  };
};
