import { GET_ROLE_SUCCESS, GET_ROLE_FAILURE } from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const getRoleListSuccess = (data) => {
  return {
    type: GET_ROLE_SUCCESS,
    payload: data,
  };
};

const getRoleListFailure = (data) => {
  return {
    type: GET_ROLE_FAILURE,
    payload: data,
  };
};

export const getRoleList = () => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/role_details`);
    if (!error) {
      return dispatch(getRoleListSuccess(result));
    }
    return dispatch(getRoleListFailure(result));
  };
};
