import React, { memo, useEffect, useState, Fragment } from "react";
import { Table, Form, Button, Select, Row, Col, Tooltip, Card, DatePicker, Input } from "antd";
import { getStateList } from "redux/actions/stateList.action";
import { getApiArrayDetails, isValidArray } from "../../../utils/MyMUtils";
import { useDispatch, useSelector } from "react-redux";
import { EyeOutlined, MailOutlined, UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import TextEditor from "./TextEditor";
import { postNewMonthlyEducation } from "redux/actions/postMonthlyEducation.action";

const { Option } = Select;

function MonthlyEducation() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStateList());
  }, []);

  const stateList = useSelector((state) => getApiArrayDetails(state.stateList.data));

  const getStateListDetails = () => {
    if (isValidArray(stateList)) {
      return stateList.map((state) => <Option value={state.state_id}>{state.state_name}</Option>);
    }
  };

  const postMonthlyEducation = (data) => {
    const Month_dateeee = moment(data.month).format("MMMM");
    const Year_dateeee = moment(data.month).format("YYYY");
    const postData = {
      ...data,
      education_date: `${Month_dateeee} ${Year_dateeee}`,
    }
    console.log(postData, "PostData")
    dispatch(postNewMonthlyEducation(postData));
  };

  return (
    <>
      <Form layout="vertical" onFinish={postMonthlyEducation}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item label="Enter Title:" name="title">
              <Input placeholder="Enter Title" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="Enter Topic:" name="topic">
              <Input placeholder="Enter Topic" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Enter Purpose:" name="purpose">
              <Input placeholder="Enter purpose" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="Select Month:" name="month">
              <DatePicker format="MM/YYYY" picker="month" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Select State:" name="state_id">
              <Select placeholder="Select State" mode="multiple">
                {getStateListDetails()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="new_content">
          <TextEditor />
        </Form.Item>
        <Row>
          <Col span={2} offset={11}>
            <Form.Item>
              <Button htmlType="submit" block>
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default MonthlyEducation;
