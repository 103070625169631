import { GET_EXTRA_HOURS_CATEGORY_SUCCESS, GET_EXTRA_HOURS_CATEGORY_FAILURE } from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const getExtraHoursCategorySuccess = (data) => {
  return {
    type: GET_EXTRA_HOURS_CATEGORY_SUCCESS,
    payload: data,
  };
};

const getExtraHoursCategoryFailure = (data) => {
  return {
    type: GET_EXTRA_HOURS_CATEGORY_FAILURE,
    payload: data,
  };
};

export const getExtraHoursCategoryInfo = () => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/get_extra_hours_category`);
    if (!error) {
      return dispatch(getExtraHoursCategorySuccess(result));
    }
    return dispatch(getExtraHoursCategoryFailure(result));
  };
};
