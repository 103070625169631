import { GET_COMMUNICATION_LOGIN_SUCCESS, GET_COMMUNICATION_LOGIN_FAILURE } from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const getCommunicationLoginSuccess = (data) => {
  return {
    type: GET_COMMUNICATION_LOGIN_SUCCESS,
    payload: data,
  };
};

const getCommunicationLoginFailure = (data) => {
  return {
    type: GET_COMMUNICATION_LOGIN_FAILURE,
    payload: data,
  };
};

export const getCommunicationLogin = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await postApi(`/get_communication_login_details`, {
      ...data,
    });
    if (!error) {
      return dispatch(getCommunicationLoginSuccess(result));
    }
    return dispatch(getCommunicationLoginFailure(result));
  };
};
