import { DELETE_MANAGE_HOLIDAY_SUCCESS, DELETE_MANAGE_HOLIDAY_FAILURE } from "../constants/MyM";
import { deleteApi } from "../../utils/apiCall";

const deleteManageHolidaySuccess = (data) => {
  return {
    type: DELETE_MANAGE_HOLIDAY_SUCCESS,
    payload: data,
  };
};

const deleteManageHolidayFailure = (data) => {
  return {
    type: DELETE_MANAGE_HOLIDAY_FAILURE,
    payload: data,
  };
};

export const deleteManageHoliday = (id) => {
  return async (dispatch, getState) => {
    const { error, result } = await deleteApi(`/delete_manage_holiday_by_id/${id}`);
    if (!error) {
      return dispatch(deleteManageHolidaySuccess(result));
    }
    return dispatch(deleteManageHolidayFailure(result));
  };
};
