export const LOGIN_SUCCESS = "login_succees";
export const LOGIN_FAILURE = "login_failure";

export const FORGOT_PASSWORD_SUCCESS = "forgot_password_succees";
export const FORGOT_PASSWORD_FAILURE = "forgot_password_failure";

export const SET_NEW_PASSWORD_SUCCESS = "set_new_password_succees";
export const SET_NEW_PASSWORD_FAILURE = "set_new_password_failure";

export const GET_USER_SUCCESS = "get_user_succees";
export const GET_USER_FAILURE = "get_user_failure";

export const EDIT_USER_SUCCESS = "edit_user_succees";
export const EDIT_USER_FAILURE = "edit_user_failure";

export const GET_GROUP_SUCCESS = "get_group_succees";
export const GET_GROUP_FAILURE = "get_group_failure";

export const GET_FACILITY_SUCCESS = "get_facility_succees";
export const GET_FACILITY_FAILURE = "get_facility_failure";

export const GET_PUBLIC_LIABILITY_SUCCESS = "get_public_liability_succees";
export const GET_PUBLIC_LIABILITY_FAILURE = "get_public_liability_failure";

export const GET_WORK_COVER_SUCCESS = "get_work_cover_succees";
export const GET_WORK_COVER_FAILURE = "get_work_cover_failure";

export const POST_UPLOAD_STATEMENT_SUCCESS = "post_upload_statement_success";
export const POST_UPLOAD_STATEMENT_FAILURE = "post_upload_statement_failure";
export const POST_UPLOAD_STATEMENT_REQUEST = "post_upload_statement_request";

export const POST_FACILITY_SURVEY_SUCCESS = "post_facility_survey_success";
export const POST_FACILITY_SURVEY_FAILURE = "post_facility_survey_failure";

export const POST_LAUNDRY_SUCCESS = "post_laundry_success";
export const POST_LAUNDRY_FAILURE = "post_laundry_failure";

export const POST_CLEANING_SUCCESS = "post_cleaning_success";
export const POST_CLEANING_FAILURE = "post_cleaning_failure";

export const GET_FACILITY_VACCINATION_SUCCESS =
  "get_facility_vaccination_success";
export const GET_FACILITY_VACCINATION_FAILURE =
  "get_facility_vaccination_failure";

export const GET_POLICE_STATEMENT_SUCCESS = "get_police_statement_success";
export const GET_POLICE_STATEMENT_FAILURE = "get_police_statement_failure";

export const GET_FACILITY_COVID_SUCCESS = "get_facility_covid_success";
export const GET_FACILITY_COVID_FAILURE = "get_facility_covid_failure";

export const GET_MANAGER_CONTRACTOR_SUCCESS = "get_manager_contractor_success";
export const GET_MANAGER_CONTRACTOR_FAILURE = "get_manager_contractor_failure";

export const GET_MANAGER_MANAGEMENT_SUCCESS = "get_manager_manager_success";
export const GET_MANAGER_MANAGEMENT_FAILURE = "get_manager_manager_failure";

export const GET_VEHICLE_SUCCESS = "get_vehicle_success";
export const GET_VEHICLE_FAILURE = "get_vehicle_failure";

export const GET_CONTACT_FACILITY_SUCCESS = "get_contact_facility_success";
export const GET_CONTACT_FACILITY_FAILURE = "get_contact_facility_failure";

export const GET_STATE_DECLARATION_SUCCESS = "get_state_declaration_success";
export const GET_STATE_DECLARATION_FAILURE = "get_state_declaration_failure";

export const POST_UPLOAD_STATE_DECLARATION_SUCCESS =
  "post_upload_state_declaration_success";
export const POST_UPLOAD_STATE_DECLARATION_FAILURE =
  "post_upload_state_declaration_failure";
export const POST_UPLOAD_STATE_DECLARATION_REQUEST =
  "post_upload_state_declaration_request";

export const POST_UPLOAD_CERTIFICATE_SUCCESS =
  "post_upload_certificate_success";
export const POST_UPLOAD_CERTIFICATE_FAILURE =
  "post_upload_certificate_failure";
export const POST_UPLOAD_CERTIFICATE_REQUEST =
  "post_upload_certificate_request";

export const POST_NEW_CONTRACTOR_SUCCESS = "post_new_contractor_success";
export const POST_NEW_CONTRACTOR_FAILURE = "post_new_contractor_failure";

export const UPDATE_CONTRACTOR_SUCCESS = "update_contractor_success";
export const UPDATE_CONTRACTOR_FAILURE = "update_contractor_failure";

export const POST_NEW_MANAGER_SUCCESS = "post_new_manager_success";
export const POST_NEW_MANAGER_FAILURE = "post_new_manager_failure";

export const UPDATE_MANAGER_SUCCESS = "update_manager_success";
export const UPDATE_MANAGER_FAILURE = "update_manager_failure";

export const GET_STATE_LIST_SUCCESS = "get_state_list_success";
export const GET_STATE_LIST_FAILURE = "get_state_list_failure";
export const GET_POLICE_CHECK_SUCCESS = "get_police_check_success";
export const GET_POLICE_CHECK_FAILURE = "get_police_check_failure";

export const GET_VACCINATION_CERTIFICATE_SUCCESS =
  "get_vaccination_certificate_success";
export const GET_VACCINATION_CERTIFICATE_FAILURE =
  "get_vaccination_certificate_failure";

export const GET_COVID_VACCINE_SUCCESS = "get_covid_vaccine_success";
export const GET_COVID_VACCINE_FAILURE = "get_covid_vaccine_failure";

export const GET_USER_LIST_SUCCESS = "get_user_list_success";
export const GET_USER_LIST_FAILURE = "get_user_list_failure";

export const POST_UPLOAD_POLICE_CHECK_SUCCESS =
  "post_upload_police_check_success";
export const POST_UPLOAD_POLICE_CHECK_FAILURE =
  "post_upload_police_check_failure";
export const POST_UPLOAD_POLICE_CHECK_REQUEST =
  "post_upload_police_check_request";

export const POST_UPLOAD_LABOUR_HIRE_SUCCESS =
  "post_upload_labour_hire_success";
export const POST_UPLOAD_LABOUR_HIRE_FAILURE =
  "post_upload_labour_hire_failure";
export const POST_UPLOAD_LABOUR_HIRE_REQUEST =
  "post_upload_labour_hire_request";

export const DELETE_CONTRACTOR_SUCCESS = "delete_contractor_success";
export const DELETE_CONTRACTOR_FAILURE = "delete_contractor_failure";

export const DELETE_MANAGER_SUCCESS = "delete_manager_success";
export const DELETE_MANAGER_FAILURE = "delete_manager_failure";

export const POST_UPLOAD_COVID_VACCINE_CERTIFICATE_SUCCESS =
  "post_upload_covid_vaccine_certificate_success";
export const POST_UPLOAD_COVID_VACCINE_CERTIFICATE_FAILURE =
  "post_upload_covid_vaccine_certificate_failure";
export const POST_UPLOAD_COVID_VACCINE_CERTIFICATE_REQUEST =
  "post_upload_covid_vaccine_certificate_request";

export const POST_UPLOAD_ISO_SUCCESS = "post_upload_iso_certificate_success";
export const POST_UPLOAD_ISO_FAILURE = "post_upload_iso_certificate_failure";
export const POST_UPLOAD_ISO_REQUEST = "post_upload_iso_certificate_request";

export const GET_STATEMENT_POLICE_SUCCESS = "get_statement_police_success";
export const GET_STATEMENT_POLICE_FAILURE = "get_statement_police_failure";

export const POST_NEW_VEHICAL_SUCCESS = "post_new_vehical_success";
export const POST_NEW_VEHICAL_FAILURE = "post_new_vehical_failure";

export const UPDATE_VEHICLE_SUCCESS = "update_vehicle_success";
export const UPDATE_VEHICLE_FAILURE = "update_vehicle_failure";

export const DELETE_VEHICAL_SUCCESS = "delete_vehical_success";
export const DELETE_VEHICAL_FAILURE = "delete_vehical_failure";

export const GET_STAFF_USER_MANAGEMENT_SUCCESS =
  "get_staff_user_management_success";
export const GET_STAFF_USER_MANAGEMENT_FAILURE =
  "get_staff_user_management_failure";

export const GET_USER_MANAGEMENT_MODULE_FAILURE =
  "get_user_management_module_success";
export const GET_USER_MANAGEMENT_MODULE_SUCCESS =
  "get_user_management_module_failure";

export const GET_ROLE_SUCCESS = "get_role_success";
export const GET_ROLE_FAILURE = "get_role_failure";

export const GET_FACILITY_BY_MODULE_SUCCESS = "get_facility_by_module_success";
export const GET_FACILITY_BY_MODULE_FAILURE = "get_facility_by_module_failure";

export const POST_NEW_FACILITY_SUCCESS = "post_new_facility_success";
export const POST_NEW_FACILITY_FAILURE = "post_new_facility_failure";

export const UPDATE_FACILITY_SUCCESS = "update_facility_success";
export const UPDATE_FACILITY_FAILURE = "update_facility_failure";

export const DELETE_FACILITY_SUCCESS = "delete_facility_success";
export const DELETE_FACILITY_FAILURE = "delete_facility_failure";

export const GET_MANAGER_SUCCESS = "get_manager_success";
export const GET_MANAGER_FAILURE = "get_manager_failure";

export const GET_EXIST_STAFF_USER_MANAGEMENT_SUCCESS =
  "get_exist_staff_user_management_success";
export const GET_EXIST_STAFF_USER_MANAGEMENT_FAILURE =
  "get_exist_staff_user_management_failure";

export const GET_USER_WISE_FACILITY_SUCCESS = "get_user_wise_facility_success";
export const GET_USER_WISE_FACILITY_FAILURE = "get_user_wise_facility_failure";

export const GET_FACILITY_BY_KPI_REPORT_SUCCESS =
  "get_facility_by_kpi_report_success";
export const GET_FACILITY_BY_KPI_REPORT_FAILURE =
  "get_facility_by_kpi_report_failure";

export const POST_NEW_STAFF_INFO_SUCCESS = "post_new_staff_info_success";
export const POST_NEW_STAFF_INFO_FAILURE = "post_new_staff_info_failure";

export const GET_KPI_FIELD_SUCCESS = "get_kpi_field_success";
export const GET_KPI_FIELD_FAILURE = "get_kpi_field_failure";

export const DELETE_KPI_REPORT_SUCCESS = "delete_kpi_report_sucess";
export const DELETE_KPI_REPORT_FAILURE = "delete_kpi_report_failure";

export const POST_KPI_REPORT_SUCCESS = "post_kpi_report_success";
export const POST_KPI_REPORT_FAILURE = "post_kpi_report_failure";
export const POST_NEW_GROUP_SUCCESS = "post_new_group_success";
export const POST_NEW_GROUP_FAILURE = "post_new_group_failure";

export const UPDATE_GROUP_SUCCESS = "update_group_success";
export const UPDATE_GROUP_FAILURE = "update_group_failure";

export const DELETE_GROUP_SUCCESS = "delete_group_success";
export const DELETE_GROUP_FAILURE = "delete_group_failure";

export const GET_ALL_GROUP_SUCCESS = "get_all_group_success";
export const GET_ALL_GROUP_FAILURE = "get_all_group_failure";

export const DELETE_USER_SUCCESS = "delete_user_success";
export const DELETE_USER_FAILURE = "delete_user_failure";

export const GET_EXITS_EMAIL_SUCCESS = "get_exits_email_success";
export const GET_EXITS_EMAIL_FAILURE = "get_exits_email_failure";

export const GET_EDUCATION_REPORT_SUCCESS = "get_education_report_success";
export const GET_EDUCATION_REPORT_FAILURE = "get_education_report_failure";

export const GET_EXITS_EMAIL_EDIT_SUCCESS = "get_exits_email_edit_success";
export const GET_EXITS_EMAIL_EDIT_FAILURE = "get_exits_email_edit_failure";

export const GET_ALL_EDUCATION_LIST_SUCCESS = "get_all_education_list_success";
export const GET_ALL_EDUCATION_LIST_FAILURE = "get_all_education_list_failure";

export const GET_EDUCATION_REGISTER_SUCCESS = "get_education_register_success";
export const GET_EDUCATION_REGISTER_FAILURE = "get_education_register_failure";

export const POST_SEND_MAIL_SUCCESS = "post_send_mail_success";
export const POST_SEND_MAIL_FAILURE = "post_send_mail_failure";

export const POST_NEW_MONTHLY_EDUCATION_SUCCESS =
  "post_new_monthly_education_success";
export const POST_NEW_MONTHLY_EDUCATION_FAILURE =
  "post_new_monthly_education_failure";

export const GET_ALL_CLEANING_SUCCESS = "get_all_cleaning_success";
export const GET_ALL_CLEANING_FAILURE = "get_all_cleaning_failure";

export const GET_ALL_LAUNDRY_SUCCESS = "get_all_laundry_success";
export const GET_ALL_LAUNDRY_FAILURE = "get_all_laundry_failure";

export const POST_SURVEY_UPLOAD_SUCCESS = "post_survey_upload_success";
export const POST_SURVEY_UPLOAD_FAILURE = "post_survey_upload_failure";

export const GET_STAFF_LIST_SUCCESS = "get_staff_list_succees";
export const GET_STAFF_LIST_FAILURE = "get_staff_list_failure";

export const GET_STAFF_LIST_ORG_SUCCESS = "get_staff_list_org_succees";
export const GET_STAFF_LIST_ORG_FAILURE = "get_staff_list_org_failure";

export const POST_AUTO_LOGIN_SUCCESS = "post_auto_login_success";
export const POST_AUTO_LOGIN_FAILURE = "get_auto_login_failure";

export const GET_MEETING_MINUTES_SUCCESS = "get_Meeting_minutes_success";
export const GET_MEETING_MINUTES_FAILURE = "get_Meeting_minutes_failure";

export const POST_MEETING_MINUTES_SUCCESS = "post_Meeting_minutes_success";
export const POST_MEETING_MINUTES_FAILURE = "post_Meeting_minutes_failure";

export const DELETE_MEETING_MINUTES_SUCCESS = "delete_Meeting_minutes_success";
export const DELETE_MEETING_MINUTES_FAILURE = "delete_Meeting_minutes_failure";

export const GET_FACILITY_USER_LIST_SUCCESS = "get_facility_user_list_success";
export const GET_FACILITY_USER_LIST_FAILURE = "get_facility_user_list_failure";

export const GET_EQUIPMENT_REPORT_SUCCESS = "get_equipment_report_success";
export const GET_EQUIPMENT_REPORT_FAILURE = "get_equipment_report_failure";

export const POST_UPLOAD_TIMESHEET_SUCCESS = "post_upload_timesheet_success";
export const POST_UPLOAD_TIMESHEET_FAILURE = "post_upload_timesheet_failure";

export const DELETE_TIMESHEET_SUCCESS = "delete_timesheet_success";
export const DELETE_TIMESHEET_FAILURE = "delete_timesheet_failure";

export const GET_UPLOAD_TIMESHEET_SUCCESS = "get_upload_timesheet_success";
export const GET_UPLOAD_TIMESHEET_FAILURE = "get_upload_timesheet_failure";

export const DELETE_WORKCOVER_SUCCESS = "delete_workcover_success";
export const DELETE_WORKCOVER_FAILURE = "delete_workcover_failure";

export const GET_SERVICE_TYPE_SUCCESS = "get_service_type_success";
export const GET_SERVICE_TYPE_FAILURE = "get_service_type_failure";

export const POST_CONTRACTED_HOURS_SUCCESS = "post_contracted_hours_success";
export const POST_CONTRACTED_HOURS_FAILURE = "post_contracted_hours_failure";

export const GET_CONTRACTED_HOURS_SUCCESS = "get_contracted_hours_success";
export const GET_CONTRACTED_HOURS_FAILURE = "get_contracted_hours_failure";

export const GET_CONTRACTED_HOURS_BY_ID_SUCCESS = "get_contracted_hours_by_id_success";
export const GET_CONTRACTED_HOURS_BY_ID_FAILURE = "get_contracted_hours_by_id_failure";

export const DELETE_CONTRACTD_HOURS_SUCCESS = "delete_contracted_hours_success";
export const DELETE_CONTRACTD_HOURS_FAILURE = "delete_contracted_hours_failure";

export const UPDATE_CONTRACTED_HOURS_SUCCESS = "update_contracted_hours_success";
export const UPDATE_CONTRACTED_HOURS_FAILURE = "update_contracted_hours_failure";

export const GET_HOLIDAY_LIST_SUCCESS = "get_holiday_list_success";
export const GET_HOLIDAY_LIST_FAILURE = "get_holiday_list_failure";

export const POST_HOLIDAY_SUCCESS = "post_holiday_success";
export const POST_HOLIDAY_FAILURE = "post_holiday_failure";

export const DELETE_HOLIDAY_SUCCESS = "delete_holiday_success";
export const DELETE_HOLIDAY_FAILURE = "delete_holiday_failure";

export const UPDATE_HOLIDAY_SUCCESS = "update_holiday_success";
export const UPDATE_HOLIDAY_FAILURE = "update_holiday_failure";

export const GET_ALL_STATE_LIST_SUCCESS = "get_all_state_list_success";
export const GET_ALL_STATE_LIST_FAILURE = "get_all_state_list_failure";

export const GET_ALL_FACILITY_LIST_SUCCESS = "get_all_facility_list_success";
export const GET_ALL_FACILITY_LIST_FAILURE = "get_all_facility_list_failure";

export const GET_ALL_NATION_LIST_SUCCESS = "get_all_nation_list_success";
export const GET_ALL_NATION_LIST_FAILURE = "get_all_nation_list_failure";

export const POST_MANAGE_HOLIDAY_SUCCESS = "post_manage_holiday_success";
export const POST_MANAGE_HOLIDAY_FAILURE = "post_manage_holiday_failure";

export const GET_MANAGE_HOLIDAY_SUCCESS = "get_manage_holiday_success";
export const GET_MANAGE_HOLIDAY_FAILURE = "get_manage_holiday_failure";

export const GET_FACILITY_FOR_HOLIDAY_SUCCESS = "get_facility_for_holiday_success";
export const GET_FACILITY_FOR_HOLIDAY_FAILURE = "get_facility_for_holiday_failure";

export const DELETE_MANAGE_HOLIDAY_SUCCESS = "delete_manage_holiday_success";
export const DELETE_MANAGE_HOLIDAY_FAILURE = "delete_manage_holiday_failure";

export const GET_HOLIDAY_LIST_FOR_MANAGE_HOLIDAY_SUCCESS = "get_holiday_list_manage_holiday_success";
export const GET_HOLIDAY_LIST_FOR_MANAGE_HOLIDAY_FAILURE = "get_holiday_list_manage_holiday_failure";

export const GET_ADHOC_FACILITY_SUCCESS = "get_adhoc_facility_success";
export const GET_ADHOC_FACILITY_FAILURE = "get_adhoc_facility_failure";

export const DELETE_ADHOC_FACILITY_SUCCESS = "delete_adhoc_facility_success";
export const DELETE_ADHOC_FACILITY_FAILURE = "delete_adhoc_facility_failure";

export const POST_ADHOC_FACILITY_SUCCESS = "post_adhoc_facility_success";
export const POST_ADHOC_FACILITY_FAILURE = "post_adhoc_facility_failure";

export const UPDATE_ADHOC_FACILITY_SUCCESS = "update_adhoc_facility_success";
export const UPDATE_ADHOC_FACILITY_FAILURE = "update_adhoc_facility_failure";

export const GET_EXTRA_HOURS_CATEGORY_SUCCESS = "get_extra_hours_category_success";
export const GET_EXTRA_HOURS_CATEGORY_FAILURE = "get_extra_hours_category_failure";

export const POST_EXTRA_HOURS_SUCCESS = "post_extra_hours_success";
export const POST_EXTRA_HOURS_FAILURE = "post_extra_hours_failure";

export const GET_EXTRA_HOURS_SUCCESS = "get_extra_hours_success";
export const GET_EXTRA_HOURS_FAILURE = "get_extra_hours_failure";

export const POST_OUTSTANDING_FACILITY_SUCCESS = "post_outstanding_facility_success";
export const POST_OUTSTANDING_FACILITY_FAILURE = "post_outstanding_facility_failure";

export const DELETE_EXTRA_HOURS_SUCCESS = "delete_extra_hours_success";
export const DELETE_EXTRA_HOURS_FAILURE = "delete_extra_hours_failure";

export const POST_COMMUNICATION_LOGIN_SUCCESS = "post_communication_login_success";
export const POST_COMMUNICATION_LOGIN_FAILURE = "post_communication_login_failure";

export const GET_COMMUNICATION_LOGIN_SUCCESS = "get_communication_login_success";
export const GET_COMMUNICATION_LOGIN_FAILURE = "get_communication_login_failure";

export const DELETE_COMMUNICATION_LOGIN_SUCCESS = "delete_communication_login_success";
export const DELETE_COMMUNICATION_LOGIN_FAILURE = "delete_communication_login_failure";

export const UPDATE_COMMUNICATION_LOGIN_SUCCESS = "update_communication_login_success";
export const UPDATE_COMMUNICATION_LOGIN_FAILURE = "update_communication_login_failure";

export const POST_AGENCY_STAFF_SUCCESS = "post_agency_staff_success";
export const POST_AGENCY_STAFF_FAILURE = "post_agency_staff_failure";

export const GET_AGNCY_STAFF_SUCCESS = "get_agency_staff_success";
export const GET_AGNCY_STAFF_FAILURE = "get_agency_staff_failure";

// Created by Bhautik

export const GET_FACILITIES_BY_MANAGER_SUCCESS = 'GET_FACILITIES_BY_MANAGER_SUCCESS';
export const GET_FACILITIES_BY_MANAGER_FAILURE = 'GET_FACILITIES_BY_MANAGER_FAILURE';

export const FILE_LOADING_STATE = 'FILE_LOADING_STATE'

//Add new Staff
export const ADD_NEW_STAFF_SUCCESS = 'ADD_NEW_STAFF_SUCCESS'
export const ADD_NEW_STAFF_FAILURE = 'ADD_NEW_STAFF_FAILURE'

// Auto login loader
export const AUTO_LOGIN_LOADER_SUCCESS = 'AUTO_LOGIN_LOADER_SUCCESS'
export const AUTO_LOGIN_LOADER_FAILURE = 'AUTO_LOGIN_LOADER_FAILURE'

// Get All Air Purifier Data
export const GET_ALL_AIR_PURIFIER_DATA_SUCCESS = 'GET_ALL_AIR_PURIFIER_DATA_SUCCESS'
export const GET_ALL_AIR_PURIFIER_DATA_FAILURE = 'GET_ALL_AIR_PURIFIER_DATA_FAILURE'

// Holiday State List
export const GET_ALL_STATE_LIST_FOR_HOLIDAY_SUCCESS = 'GET_ALL_STATE_LIST_FOR_HOLIDAY_SUCCESS'
export const GET_ALL_STATE_LIST_FOR_HOLIDAY_FAILURE = 'GET_ALL_STATE_LIST_FOR_HOLIDAY_FAILURE'

// WOrk Cover State List
export const GET_WORK_COVER_STATE_LIST_SUCCESS = 'GET_WORK_COVER_STATE_LIST_SUCCESS'
export const GET_WORK_COVER_STATE_LIST_FAILURE = 'GET_WORK_COVER_STATE_LIST_FAILURE'