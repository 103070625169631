import React, { memo, useEffect, useCallback, useState, Fragment } from "react";
import {
  Table,
  Card,
  Menu,
  Form,
  Button,
  Select,
  Row,
  Col,
  Upload,
  Tabs,
  message,
  Tooltip,
} from "antd";
import { StickyContainer, Sticky } from "react-sticky";
import CleaningNew from "./cleaningNew";
import LaundryNew from "./laundryNew";

const { Option } = Select;
const { TabPane } = Tabs;

export const Survey = () => {
  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: "#fff" }} />
      )}
    </Sticky>
  );
  return (
    <Card>
      <StickyContainer>
        <Tabs renderTabBar={renderTabBar}>
          {/* <TabPane tab="Cleaning" key="1">
                        <Cleaning />
                    </TabPane>
                    <TabPane tab="Laundry" key="2">
                        <Laundry />
                    </TabPane> */}
          <TabPane tab="Cleaning" key="1">
            <CleaningNew />
          </TabPane>
          <TabPane tab="Laundry" key="2">
            <LaundryNew />
          </TabPane>
        </Tabs>
      </StickyContainer>
    </Card>
  );
};
export default Survey;
