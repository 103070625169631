import { UPDATE_ADHOC_FACILITY_SUCCESS, UPDATE_ADHOC_FACILITY_FAILURE } from "redux/constants/MyM";
import { putApi } from "utils/apiCall";

const putAdhocFacilitySuccess = (data) => {
    return {
        type: UPDATE_ADHOC_FACILITY_SUCCESS,
        payload: data
    }
}

const putAdhocFacilityFailure = (data) => {
    return {
        type: UPDATE_ADHOC_FACILITY_FAILURE,
        payload: data
    }
}

export const putAdhocFacility = (id, data) => {
    return async (dispatch, getState) => {
      const { error, result } = await putApi(`/edit_adhoc_facility_by_id/${id}`, data);
      if (!error) {
        return dispatch(putAdhocFacilitySuccess(result));
      }
      return dispatch(putAdhocFacilityFailure(result));
    };
  };
  