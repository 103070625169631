import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Card,
  Menu,
  Form,
  Button,
  Select,
  Row,
  Col,
  Upload,
  Popover,
  message,
  Tooltip,
  DatePicker,
  Input,
  Space,
  Modal,
  Spin,
} from "antd";
import {
  EyeOutlined,
  MailOutlined,
  UploadOutlined,
  SearchOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getApiArrayDetails,
  getPreSignedUrl,
  isValidArray,
  isValidObject,
} from "../../../utils/MyMUtils";
import { getAllLaundry } from "redux/actions/allLaundryList.action";
import { getGroup } from "redux/actions/group.action";
import { getFacility } from "redux/actions/facility.action";
import { uploadCertificate } from "redux/actions/uploadCertificate.action";
import { postNewSurveyUpload } from "redux/actions/postSurveyUploadPdf.action";
import moment from "moment";
import { toast } from "react-toastify";
import Email from "../mail";

const { Option } = Select;

function LaundryNew() {
  const dateFormat = "DD/MM/YYYY";
  const MonthFormat = "MMM YYYY";

  const dispatch = useDispatch();
  let searchInput = useRef(null);

  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [groupID, setGroupID] = useState("");
  const [facilityID, setFacilityID] = useState("");
  const [postFacilityID, setPostFacilityID] = useState("");
  const [postDate, setPostDate] = useState("");
  const [facilityName, setFacilityName] = useState("");
  const [facilityId, setFacilityId] = useState("");
  const [monthInfo, setMonthInfo] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const [visible, setVisible] = useState(false);
  const [uploadInfo, setUploadInfo] = useState({});
  const [uploadStatementLink, setUploadStatementLink] = useState("");
  const [visibleMail, setVisibleMail] = useState(false);
  const [addLaundryVisible, setAddLaundryVisible] = useState(false);

  const allLaundryList = useSelector((state) =>
    getApiArrayDetails(state.getAllLaundry.data)
  );

  const fileLoadingState = useSelector((state) => state.fileLoadingReducer.fileLoadingState);

  const groupList = useSelector((state) =>
    getApiArrayDetails(state.GroupList.data)
  );

  const facilityList = useSelector((state) =>
    getApiArrayDetails(state.FacilitiesList.data)
  );

  useEffect(() => {
    dispatch(getGroup());
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  useEffect(() => {
    form1.resetFields();
  }, [addLaundryVisible]);

  useEffect(() => {
    form2.resetFields();
  }, [facilityList]);

  useEffect(() => {
    setLoading(true);
    dispatch(getAllLaundry(visible, facilityID, monthInfo)).then(() => {
      setLoading(false);
    });
  }, [facilityID, monthInfo]);

  useEffect(() => {
    dispatch(getFacility(groupID));
  }, [groupID]);

  const showModal = () => {
    setAddLaundryVisible(true);
  };

  const getGroupListDetails = () => {
    if (isValidArray(groupList)) {
      return groupList.map((group) => (
        <Option name="state_id" key={group.org_id} value={group.org_id}>
          {group.org_name}
        </Option>
      ));
    }
  };

  const getFacilityListDetails = () => {
    if (isValidArray(facilityList)) {
      return facilityList.map((facility) => (
        <Option
          name="state_id"
          key={facility.facility_id}
          value={facility.facility_id}
        >
          {facility.facility_name}
        </Option>
      ));
    }
  };

  const closePopover = () => {
    setVisibleMail(false);
  };

  const setMail = (elm) => {
    setVisibleMail(elm.id);
    if (visibleMail === elm.id) {
      setVisibleMail(false);
    }
  };

  const handleOnChangeGroup = (event) => {
    setGroupID(event);
  };

  const handleOnChangeFacility = (facility) => {
    setFacilityID(facility);
  };

  const postFacility = (facility) => {
    setPostFacilityID(facility);
  };
  const handleOnChangeMonth = (date, info) => {
    const data = moment(info).format("MM-YYYY");
    setMonthInfo(data);
  };

  const setUploadModel = (elm) => {
    setVisible(true);
    setFacilityName(elm?.facility_name);
    setFacilityId(elm?.fac_id);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: "#1a3353" ? "#1a3353" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const uploadSettings = {
    accept: ".pdf",
    beforeUpload: (file) => {
      if (file.type !== "application/pdf") {
        message.error(`${file.name} is not a pdf file`);
      }
      // uploadStatement(file.type !== "application/pdf" ? false : true);
      return false;
    },
    onChange: (info) => {
      let selectedFile = info.fileList;
      let file = null;
      let fileName;
      if (selectedFile.length > 0) {
        let fileToLoad = selectedFile[selectedFile.length - 1].originFileObj;
        fileName = fileToLoad.name;
        let reader = new FileReader();
        setLoader(true);
        reader.onload = function (fileEvent) {
          file = fileEvent.target.result;
          let name = fileName.split(".");
          const uploadInfo = {
            file: file,
            filename: name[0],
            certificate_type: "Laundry",
          };

          setUploadInfo(uploadInfo);
          dispatch(uploadCertificate(uploadInfo)).then((record) => {
            if (isValidObject(record.payload.result)) {
              setUploadStatementLink(record?.payload?.result?.result);
              setLoader(false);
            }
          });
        };
        reader.readAsDataURL(fileToLoad);
      }
    },
  };

  const postNewCleaningUploadFunction = (data) => {
    const survey_day = moment(data?.issueDate).format("DD/MM/YYYY");
    const uploadPdf = {
      fac_id: facilityId,
      survey_for: "Laundry Survey",
      survey_pdf: uploadStatementLink,
      survey_date: survey_day,
    };
    dispatch(postNewSurveyUpload(uploadPdf)).then((record) => {
      if (record.payload.success) {
        dispatch(getAllLaundry(visible, facilityID, monthInfo)).then(() => {
          setLoading(false);
        });
        setVisible(false);
        toast.success(record.payload.message);
      } else {
        setVisible(false);
        toast.error(record.payload.message);
      }
    });
  };

  const AddNewLaundry = (data) => {
    const uploadPdf = {
      fac_id: postFacilityID,
      survey_for: "Laundry Survey",
      survey_pdf: uploadStatementLink,
      survey_date: postDate,
    };
    dispatch(postNewSurveyUpload(uploadPdf)).then((record) => {
      if (record.payload.success) {
        dispatch(getAllLaundry(addLaundryVisible, facilityID, monthInfo)).then(
          () => {
            setLoading(false);
          }
        );
        setAddLaundryVisible(false);
        toast.success(record.payload.message);
      } else {
        setAddLaundryVisible(false);
        toast.error(record.payload.message);
      }
    });
  };

  function onChange(value, dateString) {
    let passData = moment(dateString).format("MM-YYYY");
    setPostDate(passData);
  }

  function onOk(value) {}

  const columns = [
    {
      title: "Organization",
      dataIndex: "org_name",
      key: "org_name",
      width: "25%",
    },
    {
      title: "Facility Name",
      dataIndex: "facility_name",
      key: "facility_name",
      width: "25%",
      ...getColumnSearchProps("facility_name"),
    },
    {
      title: "Survey Date",
      dataIndex: "survey_date",
      key: "survey_date",
      width: "20%",
      // render: (text, record) => {
      //   return text;
      // },
      render: (elm) => {
        return moment(elm, "MM/YYYY").format("MMMM YYYY");
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "30%",
      render: (_, elm) => (
        <div>
          <span className="mr-3">
            <Tooltip title="View" color="rgb(20, 72, 129)">
              {allLaundryList
                .filter((item) => elm.id === item.id)
                .map((item) => {
                  return item?.pdf_link ? (
                    <Button onClick={() => getPreSignedUrl(item.pdf_link)}  >
                    { fileLoadingState !== item.pdf_link ? <EyeOutlined /> : <Spin size="small" />}
                  </Button>
                  ) : (
                    <a target="_blank" href="">
                      <Button disabled>
                        <EyeOutlined style={{ color: "#00000040" }} />
                      </Button>
                    </a>
                  );
                })}
            </Tooltip>
          </span>
          <span className="mr-3">
            {allLaundryList
              .filter((item) => elm.id == item.id)
              .map((item) => {
                return item?.pdf_link ? (
                  <Popover
                    title="Additional Emails"
                    content={
                      <Email passData={elm} visibleMailprop={closePopover} />
                    }
                    visible={elm.id === visibleMail}
                    trigger="click"
                  >
                    <Tooltip title="Mail" color="rgb(20, 72, 129)">
                      <Button onClick={() => setMail(elm)}>
                        <MailOutlined />
                      </Button>
                    </Tooltip>
                  </Popover>
                ) : (
                  <Tooltip title="Mail" color="rgb(20, 72, 129)">
                    <Button disabled>
                      <MailOutlined style={{ color: "#00000040" }} />
                    </Button>
                  </Tooltip>
                );
              })}
          </span>
          <span>
            <Tooltip title="Upload" color="rgb(20, 72, 129)">
              {/* <Upload> */}
              <Button onClick={() => setUploadModel(elm)}>
                <UploadOutlined />
              </Button>
              {/* </Upload> */}
            </Tooltip>
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <Form layout="horizontal" form={form2}>
        <Row gutter={24}>
          <Col span={5}></Col>
          <Col span={5}>
            <Form.Item>
              <div>
                <Select
                  allowClear
                  placeholder="Select Group"
                  onChange={handleOnChangeGroup}
                >
                  {getGroupListDetails()}
                </Select>
              </div>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="demo2">
              <div>
                <Select
                  allowClear
                  onChange={handleOnChangeFacility}
                  placeholder="Select Facility"
                >
                  {getFacilityListDetails()}
                </Select>
              </div>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="demo3">
              <DatePicker
                onChange={handleOnChangeMonth}
                picker="month"
                style={{ width: "100%" }}
                format={MonthFormat}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Button
              className="style-btn"
              onClick={showModal}
              style={{ float: "right", marginBottom: "20px" }}
              icon={<PlusCircleOutlined />}
              shape="round"
            >
              New Survey Report
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        loading={loading}
        columns={columns}
        dataSource={isValidArray(allLaundryList) ? allLaundryList : ""}
        rowKey="id"
      />

      <Modal
        title="Add Survey Report"
        centered
        visible={addLaundryVisible}
        footer={null}
        onCancel={() => setAddLaundryVisible(false)}
        width={700}
      >
        <Form layout="vertical" onFinish={AddNewLaundry} form={form1}>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item
                name="group"
                label="Select Group:"
                rules={[
                  {
                    required: true,
                    message: "Please Select Group Name",
                  },
                ]}
              >
                <Select
                  allowClear
                  onChange={handleOnChangeGroup}
                  placeholder="Select Group"
                >
                  {getGroupListDetails()}
                </Select>
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="facility"
                label="Select Facility:"
                rules={[
                  {
                    required: true,
                    message: "Please Select Facility Name",
                  },
                ]}
              >
                <Select
                  allowClear
                  onChange={postFacility}
                  placeholder="Select Facility"
                >
                  {getFacilityListDetails()}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item
                name="date"
                label="Select Month:"
                rules={[
                  {
                    required: true,
                    message: "Please Select Date",
                  },
                ]}
              >
                <DatePicker
                  format={MonthFormat}
                  picker="month"
                  onChange={onChange}
                  onOk={onOk}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="file"
                label="File:"
                rules={[
                  {
                    required: true,
                    message: "Please Upload File",
                  },
                ]}
              >
                <Upload {...uploadSettings} maxCount={1}>
                  <Button onClick={() => setAddLaundryVisible(true)}>
                    <UploadOutlined />
                    Click to Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button htmlType="submit" loading={loader}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Upload"
        centered
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        width={500}
      >
        <Form
          layout="vertical"
          onFinish={postNewCleaningUploadFunction}
          form={form}
        >
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item
                name="issueDate"
                label="Issue Date:"
                initialValue={moment(moment())}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Issue Date",
                  },
                ]}
              >
                <DatePicker
                  format={dateFormat}
                  onChange={onChange}
                  onOk={onOk}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item
                name="file"
                label="File:"
                rules={[
                  {
                    required: true,
                    message: "Please Upload File",
                  },
                ]}
              >
                <Upload {...uploadSettings} maxCount={1}>
                  <Button onClick={() => setVisible(true)}>
                    <UploadOutlined />
                    Click to Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button htmlType="submit" loading={loader}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default LaundryNew;
