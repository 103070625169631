import {
    DELETE_WORKCOVER_SUCCESS,
    DELETE_WORKCOVER_FAILURE,
  } from "../constants/MyM";
  import { deleteApi } from "../../utils/apiCall";
  
  const deleteWorkcoverSuccess = (data) => {
    return {
      type: DELETE_WORKCOVER_SUCCESS,
      payload: data,
    };
  };
  
  const deleteWorkcoverFailure = (data) => {
    return {
      type: DELETE_WORKCOVER_FAILURE,
      payload: data,
    };
  };
  
  export const deleteWorkcover = (id) => {
    return async (dispatch, getState) => {
      const { error, result } = await deleteApi(`/workcover_certificate/${id}`);
      if (!error) {
        return dispatch(deleteWorkcoverSuccess(result));
      }
      return dispatch(deleteWorkcoverFailure(result));
    };
  };
  