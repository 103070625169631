import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import StatmentVaccination from "./statements";
import BusinessInsurance from "./businessInsurance";
import Certificates from "./certificates";
import ContactDetail from "./contactDetail";
import Survey from "./survey";
import Vehicale from "./vehical";
import Staff from "./staff";
import UserManagement from "./userManagement";
import KPIData from "./kpiReport";
import facilityPortal from "./facilityPortal";
import Education from "./reports";
import MeetingMinutes from "./meetingMinutes"
import Timesheet from "./timesheet"
import Setting from "./settings"
import CommunicationManagement from "./communicationManagement"

export const AppViews = () => {
  return (
    <>
      <Suspense fallback={<Loading cover="content" />}>
        <Switch>
          <Route
            exact
            path={`${APP_PREFIX_PATH}/home`}
            component={lazy(() => import(`./home`))}
          />

          <Route
            exact
            path={`${APP_PREFIX_PATH}/statements`}
            component={StatmentVaccination}
          />

          <Route
            exact
            path={`${APP_PREFIX_PATH}/business_insurance`}
            component={BusinessInsurance}
          />
          <Route
            exact
            path={`${APP_PREFIX_PATH}/certificates`}
            component={Certificates}
          />
          <Route
            exact
            path={`${APP_PREFIX_PATH}/contact_detail`}
            component={ContactDetail}
          />
          {/* <Route exact path={`${APP_PREFIX_PATH}/survey`} component={Survey} /> */}
          <Route
            exact
            path={`${APP_PREFIX_PATH}/vehicle`}
            component={Vehicale}
          />
          <Route exact path={`${APP_PREFIX_PATH}/staff`} component={Staff} />
          {/* <Route
            exact
            path={`${APP_PREFIX_PATH}/kpi_report`}
            component={KPIData}
          /> */}
          {/* <Route
            exact
            path={`${APP_PREFIX_PATH}/education-report`}
            component={Education}
          /> */}
          <Route
            exact
            path={`${APP_PREFIX_PATH}/user-management`}
            component={UserManagement}
          />
          <Route
            exact
            path={`${APP_PREFIX_PATH}/user-management`}
            component={UserManagement}
          />
          <Route
            exact
            path={`${APP_PREFIX_PATH}/facility-portal`}
            component={facilityPortal}
          />
          <Route
            exact
            path={`${APP_PREFIX_PATH}/meeting-minutes`}
            component={MeetingMinutes}
          />
           <Route
            exact
            path={`${APP_PREFIX_PATH}/timesheet`}
            component={Timesheet}
          />
           <Route
            exact
            path={`${APP_PREFIX_PATH}/setting`}
            component={Setting}
          />
             <Route
            exact
            path={`${APP_PREFIX_PATH}/communication-management`}
            component={CommunicationManagement}
          />
          

          <Redirect exact to={`${APP_PREFIX_PATH}/dashboards`} />
        </Switch>
      </Suspense>
    </>
  );
};

export default React.memo(AppViews);
