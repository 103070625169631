import { POST_NEW_MONTHLY_EDUCATION_SUCCESS, POST_NEW_MONTHLY_EDUCATION_FAILURE } from "../constants/MyM";

const initStat = { data: [], error: false, errorData: "", isLoading: true };

export default (state = initStat, action) => {
    switch (action.type) {
        case POST_NEW_MONTHLY_EDUCATION_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            };
        case POST_NEW_MONTHLY_EDUCATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorData: action.payload,
                error: true,
            };
        default:
            return { ...state };
    }
};