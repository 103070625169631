import {
    AUTO_LOGIN_LOADER_SUCCESS,
    AUTO_LOGIN_LOADER_FAILURE,
  } from "../constants/MyM";
  
  const startAutoLoginLoader = (data) => {
    return {
      type: AUTO_LOGIN_LOADER_SUCCESS,
      payload: data,
    };
  };
  
  const stopAutoLoginLoader = (data) => {
    return {
      type: AUTO_LOGIN_LOADER_FAILURE,
      payload: data,
    };
  };
  
  export const AutoLoginLoaderAction = ({ start = false, stop = false }) => {
    return async (dispatch, getState) => {
      if (start) {
        return dispatch(startAutoLoginLoader(true));
      }
      if(stop){
          return dispatch(stopAutoLoginLoader(false));
      }
    };
  };