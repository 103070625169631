import { GET_FACILITY_SUCCESS, GET_FACILITY_FAILURE } from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const facilitySuccess = (data) => {
  return {
    type: GET_FACILITY_SUCCESS,
    payload: data,
  };
};

const facilityFailure = (data) => {
  return {
    type: GET_FACILITY_FAILURE,
    payload: data,
  };
};

export const getFacility = (id) => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/get_all_fac_list_by_org_id/${id}`);
    if (!error) {
      return dispatch(facilitySuccess(result));
    }
    return dispatch(facilityFailure(result));
  };
};
