import React, { memo, useEffect, useCallback, useState, Fragment } from "react";
import {
  Table,
  Card,
  Menu,
  Form,
  Button,
  Select,
  Row,
  Col,
  Upload,
  Tooltip,
  Tabs,
  message,
  Modal,
  DatePicker,
  Popover,
  Popconfirm,
  Spin
} from "antd";
import { StickyContainer, Sticky } from "react-sticky";
import { getApiSingleDetails, getApiArrayDetails, isValidArray, getPreSignedUrl, isValidObject, getBase64URI, sendNotification } from "../../../utils/MyMUtils";
import { getWorkCover } from "../../../redux/actions/workCover.action";
import { useDispatch, useSelector } from "react-redux";
import { EyeOutlined, MailOutlined, UploadOutlined ,DeleteOutlined} from "@ant-design/icons";
import { useHistory } from "react-router";
import { uploadLabourHire } from "redux/actions/uploadLabourHire.action";
import { uploadCertificate } from "redux/actions/uploadCertificate.action";
import {deleteWorkcover} from "redux/actions/deleteWorkcover.action"
import { toast } from "react-toastify";
import Email from "../mail";

import moment from "moment";
import { getWorkCoverStateList } from "redux/actions/getWorkCoverState.action";
const dateFormat = "DD/MM/YYYY";
const AnotherFormat = "YYYY/MM/DD";

const { Option } = Select;
const { TabPane } = Tabs;

const WorkCover = () => {
  const url = window.location.pathname;
  const [activeUrl, setActiveUrl] = useState("1");
  const [typeId, setTypeId] = useState("");
  const [stateId, setStateId] = useState("");
  const [uploadInfo, setUploadInfo] = useState({});
  const [visible, setVisible] = useState(false);
  const [visibleMail, setVisibleMail] = useState(false);
  const [workCoverUploadState, setWorkCoverUploadState] = useState(false);
  const [workCoverState, setWorkCoverState] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [uploadLoader, setUploadLoader] = useState(false)
  const [form] = Form.useForm();
  const [workCoverForm] = Form.useForm();

  const history = useHistory();
  const dispatch = useDispatch();
  const { data: { result: workCoverData = [] } } = useSelector((state) => state.workCover);
  const fileLoadingState = useSelector((state) => state.fileLoadingReducer.fileLoadingState);
  const workCoverStateList = useSelector((state) => state.workCoverStateList);


  useEffect(() => {
    dispatch(getWorkCover());
    dispatch(getWorkCoverStateList());
    return () => {};
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  useEffect(() => {
    const { data } = workCoverStateList

    if( isValidObject(data) && data.result.length){
        setWorkCoverState(data.result)
    }
  }, [workCoverStateList])

  const setUploadModel = (elm) => {
    setVisible(true);
    setTypeId("2");
    setStateId(elm?.state_id);
  };
  const postUploadPdfFunction = (data) => {
    const abc = moment(data.expiry_date).format(AnotherFormat);
    dispatch(uploadCertificate(uploadInfo)).then((record) => {
      const uploadFinal = {
        type_id: typeId,
        state_id: stateId,
        expiry_date: abc,
        pdf_link: record.payload.result.result,
      };
      dispatch(uploadLabourHire(uploadFinal)).then((record) => {
        if (record.payload.result.success === true) {
          dispatch(getWorkCover());
          toast.success(record.payload.result.message);
          setVisible(false);
        } else {
          toast.error(record.payload.result.message);
          setVisible(false);
        }
      });
    });
  };
  const closePopover = () => {
    setVisibleMail(false);
  };
  const setMail = (elm) => {
    setVisibleMail(elm.id);
    if (visibleMail === elm.id) {
      setVisibleMail(false);
    }
  };
  function onChange(value, dateString) {}

  function confirm(elm) {
    dispatch(deleteWorkcover(elm.id)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        dispatch(getWorkCover());
      } else {
        toast.error(record.payload.message);
        dispatch(getWorkCover());
      }
    }).finally(() => {
        dispatch(getWorkCoverStateList());
    });
  }

  function cancel(e) {}

  function onOk(value) {}

  const uploadSettings = {
    accept: ".pdf",
    beforeUpload: (file) => {
      if (file.type !== "application/pdf") {
        message.error(`${file.name} is not a pdf file`);
      }
      //   uploadStatement(file.type !== "application/pdf" ? false : true);
      return false;
    },
    onChange: (info) => {
      let selectedFile = info.fileList;
      let file = null;
      let fileName;
      if (selectedFile.length > 0) {
        let fileToLoad = selectedFile[selectedFile.length - 1].originFileObj;
        fileName = fileToLoad.name;
        let reader = new FileReader();
        reader.onload = function (fileEvent) {
          file = fileEvent.target.result;
          let name = fileName.split(".");
          const uploadInfo = {
            certificate_type: "workcover",
            filename: name[0],
            file: file,
          };
          setUploadInfo(uploadInfo);
          // return uploadInfo;
        };
        reader.readAsDataURL(fileToLoad);
      }
    },
  };
  const columnsOne = [
    {
      title: "State",
      dataIndex: "statename",
      key: "statename",
      width: "50%",
      // filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "50%",
      render: (_, record, row) => {
        return (
            <div>
                <span className="mr-3">
                    <Tooltip title="View" color="rgb(20, 72, 129)">
                        <Button
                            disabled={!record.pdf_link}
                            onClick={() => getPreSignedUrl(record.pdf_link)}
                        >
                            {fileLoadingState !== record.pdf_link ? (
                                <EyeOutlined
                                    style={{
                                        color: !record.pdf_link
                                            ? "#00000040"
                                            : "rgb(20, 72, 129)",
                                    }}
                                />
                            ) : (
                                <Spin size="small" />
                            )}
                        </Button>
                    </Tooltip>
                </span>
                <span className="mr-3">
                    <Popover
                        title="Additional Emails"
                        content={
                            <Email
                                passData={record}
                                visibleMailprop={closePopover}
                            />
                        }
                        visible={record.id === visibleMail}
                        trigger="click"
                    >
                        <Tooltip title="Mail" color="rgb(20, 72, 129)">
                            <Button
                                disabled={!record.pdf_link}
                                onClick={() => setMail(record)}
                            >
                                <MailOutlined
                                    style={{
                                        color: !record.pdf_link
                                            ? "#00000040"
                                            : "rgb(20, 72, 129)",
                                    }}
                                />
                            </Button>
                        </Tooltip>
                    </Popover>
                </span>
                <span className="mr-3">
                    <Tooltip title="Upload" color="rgb(20, 72, 129)">
                        <Button onClick={() => setUploadModel(record)}>
                            <UploadOutlined />
                        </Button>
                    </Tooltip>
                </span>
                <span>
                    <Popconfirm
                        title="Are you sure to delete this data?"
                        onConfirm={() => confirm(record)}
                        onCancel={() => cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Tooltip title="Delete" color="rgb(20, 72, 129)">
                            <Button disabled={!record.pdf_link}>
                                <DeleteOutlined
                                    style={{
                                        color: !record.pdf_link
                                            ? "#00000040"
                                            : "rgb(20, 72, 129)",
                                    }}
                                />
                            </Button>
                        </Tooltip>
                    </Popconfirm>
                </span>
            </div>
        );
      }
    },
  ];

  const handleChange = async info => {
      
    setFileList([info.file])

    if (info.file.status === 'removed') {
        workCoverForm.setFieldsValue({workCoverFile: []});
        setFileList([])
        return;
    }
};

const handleWorkCoverUpload = () => {
    workCoverForm.validateFields().then( async ({state: stateId, workCoverFile: file, expiry_date}) => {
        setUploadLoader(true)
        const { base64String } = await getBase64URI(file.file.originFileObj);
        
        const fileUploadBody = {
            certificate_type: "workcover",
            filename: file.file.name,
            file: base64String,
        }

        dispatch(uploadCertificate(fileUploadBody)).then(({payload}) => {

            if (!payload.error) {

                const certificateUploadBody = {
                    type_id: workCoverState.find((state) => state.state_id === stateId).type_id,
                    state_id: stateId,
                    expiry_date: moment(expiry_date).format(AnotherFormat),
                    pdf_link: payload.result.result,
                };

                dispatch(uploadLabourHire(certificateUploadBody)).then((response) => {
                    if(!response.payload.error){
                        sendNotification({notificationType: 'success', payload: response.payload.result.message})
                        setFileList([])
                        
                        dispatch(getWorkCover());
                    }
                }).then(() => {
                    dispatch(getWorkCoverStateList());
                })

            } else {
                sendNotification({notificationType: 'error', message: 'Something went wrong'})
                setFileList([])
            }

        }).finally(() => {
            setWorkCoverUploadState(false)
            setUploadLoader(false)
            workCoverForm.resetFields()
        })

    })
  }


  return (
      <>
          <Row justify="end" gutter={[0, 12]}>
              <Button
                  onClick={() => setWorkCoverUploadState(true)}
                  icon={<UploadOutlined />}
              >
                  Upload Work Cover
              </Button>
          </Row>
          <Table
              loading={false}
              columns={columnsOne}
              dataSource={ isValidArray(workCoverData) ? workCoverData : [] }
              rowKey="id"
          />
          <Modal
              title="Upload"
              centered
              visible={visible}
              footer={null}
              onCancel={() => setVisible(false)}
              width={500}
          >
              <Form
                  onFinish={postUploadPdfFunction}
                  layout="vertical"
                  form={form}
              >
                  <Row gutter={24} className="justify-content-center">
                      <Col md={24}>
                          <Form.Item
                              name="expiry_date"
                              label="Expiry Date:"
                              initialValue={moment(moment())}
                              rules={[
                                  {
                                      required: true,
                                      message: "Please Enter Expiry Date",
                                  },
                              ]}
                          >
                              <DatePicker
                                  format={dateFormat}
                                  onChange={onChange}
                                  onOk={onOk}
                              />
                          </Form.Item>
                      </Col>
                  </Row>
                  <Row gutter={24} className="justify-content-center">
                      <Col md={24}>
                          <Form.Item name="file" label="File:">
                              <Upload {...uploadSettings}>
                                  <Button onClick={() => setVisible(true)}>
                                      <UploadOutlined />
                                      Click to Upload
                                  </Button>
                              </Upload>
                          </Form.Item>
                      </Col>
                  </Row>
                  <Row gutter={24} className="justify-content-center">
                      <Col md={24} style={{ textAlign: "center" }}>
                          <Button htmlType="submit">Submit</Button>
                      </Col>
                  </Row>
              </Form>
          </Modal>

          <Modal
              title="Upload Work Cover"
              centered
              visible={workCoverUploadState}
              onCancel={() => setWorkCoverUploadState(false)}
              onOk={handleWorkCoverUpload}
              width={500}
              confirmLoading={uploadLoader}
          >
              <Form layout="vertical" form={workCoverForm}>
                  <Row gutter={[12, 0]}>
                      <Col span={12}>
                          <Form.Item
                              label="Select State"
                              name="state"
                              rules={[
                                  {
                                      required: true,
                                      message: "Please select state!",
                                  },
                              ]}
                          >
                              <Select placeholder="Please select state">
                                  {workCoverState.map(
                                      (
                                          { state_id = 45, pdf_link, state_name },
                                          index
                                      ) => {
                                          return (
                                              <Select.Option
                                                  key={index}
                                                  disabled={pdf_link}
                                                  value={state_id}
                                              >
                                                  {state_name}
                                              </Select.Option>
                                          );
                                      }
                                  )}
                              </Select>
                          </Form.Item>
                      </Col>
                      <Col span={12}>
                          <Form.Item
                              label="Expiry Date"
                              name="expiry_date"
                              initialValue={moment(moment())}
                              rules={[
                                  {
                                      required: true,
                                      message: "Please Enter Expiry Date",
                                  },
                              ]}
                          >
                              <DatePicker
                                  format={dateFormat}
                                  onChange={onChange}
                                  className="w-100"
                              />
                          </Form.Item>
                      </Col>
                  </Row>

                  <Form.Item
                      label="Select File"
                      name="workCoverFile"
                      rules={[
                          {
                              required: true,
                              message: "Please select file!",
                          },
                      ]}
                  >
                      <Upload
                          showUploadList={true}
                          fileList={fileList}
                          accept=".pdf"
                          customRequest={({ file, onSuccess }) => {
                              setTimeout(() => {
                                  onSuccess("Ok");
                              });
                          }}
                          onChange={handleChange}
                      >
                          <Button icon={<UploadOutlined />}>
                              Click to Upload
                          </Button>
                      </Upload>
                  </Form.Item>
              </Form>
          </Modal>
      </>
  );
};

export default memo(WorkCover);
