import React, { useEffect, Fragment, useState } from "react";
import {
  Table,
  DatePicker,
  Form,
  Button,
  Select,
  Row,
  Col,
  Tooltip,
  Popconfirm,
  Modal,
  Spin,
  Input,
  Popover
} from "antd";
import "../index.css";
import { getApiArrayDetails, getPreSignedUrl, isValidArray } from "../../../utils/MyMUtils";
import {
  EyeOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  ExpandAltOutlined,
  ExpandOutlined,
  MailOutlined
} from "@ant-design/icons";
import { getGroup } from "../../../redux/actions/group.action";
import { getFacilityByKpiData } from "../../../redux/actions/getKpiReport.action";
import { getFacility } from "../../../redux/actions/facility.action";
import { getKpiFieldData } from "../../../redux/actions/getKpifieldDetail.action";
import { useDispatch, useSelector } from "react-redux";
import { deleteKpi } from "../../../redux/actions/deleteKpiReport.action";
import { postKpiReportData } from "../../../redux/actions/postKpiReport.action";
import { toast } from "react-toastify";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import Email from "../mail/index"

import { values } from "lodash";

const { Option } = Select;
const { MonthPicker } = DatePicker;
const KPIReport = () => {
  const dateFormat = "MMM YYYY";
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [viewTable, setViewTable] = useState(false);
  const [orgId, setOrgId] = useState("");
  const [modalMinimize, setModalMinimize] = useState(true);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [myLoader, setMyLoader] = useState(false);
  const [myKpiLoader, setMyKpiLoader] = useState(false);
  const [visibleMail, setVisibleMail] = useState(false);


  const [data, setData] = useState([]);

  const groupList = useSelector((state) =>
    getApiArrayDetails(state.GroupList.data)
  );
  const facilitiesList = useSelector((state) =>
    getApiArrayDetails(state.FacilitiesList.data)
  );
  const KpiList = useSelector((state) =>
    getApiArrayDetails(state.getKpiReport.data)
  );
  const KpiField = useSelector((state) =>
    getApiArrayDetails(state.getKpiField.data)
  );
  const fileLoadingState = useSelector((state) => state.fileLoadingReducer.fileLoadingState);


  useEffect(() => {
    dispatch(getGroup());
    return () => {};
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [isModalVisible]);

  useEffect(() => {
    setData(KpiField);
  }, [KpiField]);

  useEffect(() => {
    form1.resetFields();
  }, [viewTable]);

  const getGroupList = () => {
    if (isValidArray(groupList)) {
      return groupList.map((group) => {
        return (
          <Fragment key={group?.org_id}>
            <Option value={group?.org_id}>{group?.org_name}</Option>
          </Fragment>
        );
      });
    }
  };

  const getAllFacility = () => {
    if (isValidArray(facilitiesList)) {
      return facilitiesList.map((FacilityData) => {
        return (
          <Fragment key={FacilityData?.facility_id}>
            <Option value={FacilityData?.facility_id}>
              {FacilityData?.facility_name}
            </Option>
          </Fragment>
        );
      });
    }
  };

  const handleFieldChange = (e, record) => {
    const { value } = e.target;
    const tempArr = KpiField?.map((d) => {
      if (record.id === d.id) {
        d.actual = value;
      }
      return d;
    });
    setData(tempArr);
  };

  const handleFieldChangeResult = (e, record) => {
    const { value } = e.target;
    const tempArr = KpiField?.map((d) => {
      if (record.id === d.id) {
        d.result = value;
      }
      return d;
    });
    setData(tempArr);
  };

  const handleOnChangeData = (event) => {
    setOrgId(event);
    const data = {
      org_id: event,
    };
    setMyKpiLoader(true);
    dispatch(getFacilityByKpiData(data)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        setMyKpiLoader(false);
      } else {
        toast.error(record.payload.message);
        setMyKpiLoader(false);
      }
    });
  };
  function onChange(date) {
    const format2 = "MM-YYYY";
    const start_date = moment(date).format(format2);
    const data = {
      org_id: orgId,
      kpi_inspection_date: start_date,
    };
    setMyKpiLoader(true);
    dispatch(getFacilityByKpiData(data)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        setMyKpiLoader(false);
      } else {
        toast.error(record.payload.message);
        setMyKpiLoader(false);
      }
    });
  }

  const handleOnChange = (event) => {
    setOrgId(event);
    dispatch(getFacility(event));
    dispatch(getKpiFieldData(event));
    setViewTable(true);
  };

  const postKpiReportFinish = (value) => {
    const { month, facility_id } = value;
    const monthDate = moment(month).format("MMMM");
    const yearDate = moment(month).format("YYYY");
    const apiData = {
      month: monthDate,
      year: yearDate,
      facility_id: facility_id,
      kpi_report_pdf: data || [],
    };
    let kpi_report_pdf_data = apiData.kpi_report_pdf.filter(
      (item) => item.result === "" || item.result === null
    );
    if (kpi_report_pdf_data.length < 1) {
      setMyLoader(true);
      dispatch(postKpiReportData(apiData)).then((record) => {
        if (record.payload.success == true) {
          toast.success(record.payload.message);
          setMyLoader(false);
          setIsModalVisible(false);
        } else {
          toast.error(record.payload.message);
          setMyLoader(false);
          setIsModalVisible(false);
        }
      });
    } else {
      setViewTable(true);
      toast.error("Please fill all result field in the table!!!");
    }
  };
  const deleteFacilityFunction = (elm) => {
    dispatch(deleteKpi(elm.id)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        dispatch(getFacilityByKpiData({ org_id: orgId }));
      } else {
        toast.error(record.payload.message);
        dispatch(getFacilityByKpiData({ org_id: orgId }));
      }
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const onCancelModel = () => {
    setIsModalVisible(false);
    setViewTable(false);
  };

  const onMinizeModel = (value) => {
    setModalMinimize(value);
  };

  const closePopover = () => {
    setVisibleMail(false);
  };

  const setMail = (elm) => {
    setVisibleMail(elm.id);
    if (visibleMail === elm.id) {
      setVisibleMail(false);
    }
  };

  function cancel(e) {}

  const columns = [
    {
      title: "Facility",
      dataIndex: "facility_name",
      key: "facility_name",
      width: "13  %",
    },
    {
      title: "Month",
      dataIndex: "kpi_inspection_date",
      key: "kpi_inspection_date",
      width: "13  %",
      render: (elm) => {
        return moment(elm, "MM/YYYY").format("MMMM YYYY");
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "30%",
      render: (_, elm, row) => (
        <div>
          <span className="mr-3">
            <Tooltip title="View" color="rgb(20, 72, 129)">
              {KpiList &&
                KpiList.filter((item) => elm.id == item.id).map((item) => {
                  return (
                   
                       <Button onClick={() => getPreSignedUrl(item.kpi_inspection_pdf_link)}  >
                        { fileLoadingState !== item.kpi_inspection_pdf_link ? <EyeOutlined /> : <Spin size="small" />}
                      </Button>
                 
                  );
                })}
            </Tooltip>
          </span>

          <span className="mr-3">
            {KpiList
              .filter((item) => elm.id == item.id)
              .map((item) => {
                return item?.pdf_link ? (
                  <Popover
                    title="Additional Emails"
                    content={<Email passData={elm} visibleMailprop={closePopover} />}
                    visible={elm.id === visibleMail}
                    trigger="click"
                  >
                    <Tooltip title="Mail" color="rgb(20, 72, 129)">
                      <Button onClick={() => setMail(elm)}>
                        <MailOutlined />
                      </Button>
                    </Tooltip>
                  </Popover>
                ) : (
                  <Tooltip title="Mail" color="rgb(20, 72, 129)">
                    <Button disabled>
                      <MailOutlined style={{ color: "#00000040" }} />
                    </Button>
                  </Tooltip>
                );
              })}
          </span>

          <span className="mr-3">
            <Popconfirm
              title="Are you sure to delete this data?"
              onConfirm={() => deleteFacilityFunction(elm)}
              onCancel={() => cancel}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete" color="rgb(20, 72, 129)">
                <Button>
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          </span>
        </div>
      ),
    },
  ];

  const columns1 = [
    {
      title: "Description",
      dataIndex: "measure_title",
      width: "20%",
      editable: true,
    },
    {
      title: "Measure",
      dataIndex: "measure_data",
      width: "25%",
      editable: true,
    },
    {
      title: "Actual",
      dataIndex: "actual",
      width: "30%",
      editable: true,
      render: (text, record) => {
        return (
          <Form.Item>
            <TextArea
              style={{
                width: "100%",
                height: "100px",
                marginBottom: "15px",
              }}
              value={values.actual}
              onChange={(e) => handleFieldChange(e, record)}
            />
          </Form.Item>
        );
      },
    },

    {
      title: "Target",
      dataIndex: "target",
      width: "10%",
      editable: true,
    },
    {
      title: "Result",
      dataIndex: "result",
      width: "15%",
      editable: true,
      render: (text, record) => {
        return (
          <Input
            style={{ width: "100%", height: "100px" }}
            value={values.result}
            defaultValue={"100%"}
            onChange={(e) => handleFieldChangeResult(e, record)}
          />
        );
      },
    },
  ];

  return (
    <>
      <Form layout="horizontal">
        <Row gutter={22}>
          <Col span={13}></Col>
          <Col span={5}>
            <Form.Item>
              <div>
                <Select
                  name="org_id"
                  onChange={handleOnChangeData}
                  placeholder="Select Organization"
                >
                  {getGroupList()}
                </Select>
              </div>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item>
              <div style={{ float: "right" }}>
                <MonthPicker
                  onChange={onChange}
                  name="kpi_inspection_date"
                  format={dateFormat}
                />
              </div>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Button
              className="style-btn"
              onClick={showModal}
              style={{ float: "right", marginBottom: "20px" }}
              icon={<PlusCircleOutlined />}
              shape="round"
            >
              New Report
            </Button>
          </Col>
        </Row>
        {myKpiLoader ? (
          <>
            <div className="d-flex justify-content-center align-items-center">
              <Spin size="large" />
            </div>
          </>
        ) : (
          <Table  columns={columns} dataSource={KpiList} rowKey="id" />
        )}
      </Form>
      <div>
        <Modal
          className="kpi-modal"
          title={
            <div>
              {modalMinimize === false ? (
                <div>
                  <span>Create KPI Report</span>
                  <ExpandAltOutlined
                    onClick={() => onMinizeModel(true)}
                    className="mt-1 mr-5 float-right"
                  />
                </div>
              ) : (
                <div>
                  <span>Create KPI Report</span>
                  <ExpandOutlined
                    onClick={() => onMinizeModel(false)}
                    className="mt-1 mr-5 float-right"
                  />
                </div>
              )}
            </div>
          }
          centered
          footer={null}
          visible={isModalVisible}
          onCancel={() => onCancelModel()}
          width={modalMinimize ? 900 : 2000}
        >
          <Form onFinish={postKpiReportFinish} layout="vertical" form={form}>
            <Row gutter={[16, 16]} className="justify-content-center">
              <Col span={8}>
                <Form.Item name="org_id" label="Organization Name:">
                  <Select
                    onChange={handleOnChange}
                    placeholder="Select Organization"
                  >
                    {getGroupList()}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="facility_id"
                  label="Facility Name:"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Facility Name",
                    },
                  ]}
                >
                  <Select placeholder="Select Facility">
                    {getAllFacility()}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  name="month"
                  label="Month:"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Month",
                    },
                  ]}
                >
                  <MonthPicker format={dateFormat} />
                </Form.Item>
              </Col>
            </Row>
            {myLoader ? (
              <>
                <div className="d-flex justify-content-center align-items-center">
                  <Spin size="large" />
                </div>
              </>
            ) : (
              <div>
                {viewTable === true ? (
                  <Row className="justify-content-center">
                    <Col>
                      <Form visible={viewTable} form={form1}>
                        <Table
                          rowKey="id"
                          style={{ width: "100%" }}
                          scroll={{ y: 500 }}
                          dataSource={KpiField}
                          columns={columns1}
                          pagination={false}
                          rowClassName={(record) =>
                            record.editable ? "editable" : ""
                          }
                        />
                      </Form>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </div>
            )}

            <div>
              <Row
                gutter={[16, 16]}
                style={{ marginTop: "15px" }}
                className="justify-content-center "
              >
                <Col>
                  <Button className="style-btn" htmlType="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default KPIReport;
