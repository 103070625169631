import { GET_ALL_FACILITY_LIST_SUCCESS, GET_ALL_FACILITY_LIST_FAILURE } from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const getAllFacilityListSuccess = (data) => {
  return {
    type: GET_ALL_FACILITY_LIST_SUCCESS,
    payload: data,
  };
};

const getAllFacilityListFailure = (data) => {
  return {
    type: GET_ALL_FACILITY_LIST_FAILURE,
    payload: data,
  };
};

export const getFacilityInfo = () => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/get_all_active_facility`);
    if (!error) {
      return dispatch(getAllFacilityListSuccess(result));
    }
    return dispatch(getAllFacilityListFailure(result));
  };
};
