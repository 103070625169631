import { GET_SERVICE_TYPE_SUCCESS, GET_SERVICE_TYPE_FAILURE } from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const serviceTypeSuccess = (data) => {
  return {
    type: GET_SERVICE_TYPE_SUCCESS,
    payload: data,
  };
};

const serviceTypeFailure = (data) => {
  return {
    type: GET_SERVICE_TYPE_FAILURE,
    payload: data,
  };
};

export const getServiceType = (id) => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/get_facility_service_type/${id}`);
    if (!error) {
      return dispatch(serviceTypeSuccess(result));
    }
    return dispatch(serviceTypeFailure(result));
  };
};
