import React, { memo, useEffect, useCallback, useState, Fragment } from "react";
import {
  Table,
  Card,
  Menu,
  Form,
  Button,
  Select,
  Row,
  Col,
  Upload,
  Tabs,
  message,
  Tooltip,
  Popover,
  Spin,
} from "antd";
import { getApiArrayDetails, getPreSignedUrl, isValidArray } from "../../../utils/MyMUtils";
import { getGroup } from "../../../redux/actions/group.action";
import { getFacility } from "../../../redux/actions/facility.action";
import { uploadStatement } from "redux/actions/uploadStatement.action";
import { useDispatch, useSelector } from "react-redux";
import { EyeOutlined, MailOutlined, UploadOutlined } from "@ant-design/icons";
import { getPoliceStatement } from "redux/actions/policestatement.action";
import { toast } from "react-toastify";
import { statementPolice } from "redux/actions/statementPoliceGet.action";
import Email from "../mail";

const { Option } = Select;
const { TabPane } = Tabs;

const PoliceStatement = () => {
  const url = window.location.pathname;
  const [orgId, setOrgId] = useState("");
  const [activeUrl, setActiveUrl] = useState("1");
  const [facilityName, setFacilityName] = useState("");
  const [facilityId, setFacilityId] = useState("");
  const [loading, setLoading] = useState(false);
  const [visibleMail, setVisibleMail] = useState(false);
  const dispatch = useDispatch();

  const groupList = useSelector((state) =>
    getApiArrayDetails(state.GroupList.data)
  );
  const facilitiesList = useSelector((state) =>
    getApiArrayDetails(state.FacilitiesList.data)
  );
  const policeList = useSelector((state) =>
    getApiArrayDetails(state.statementPolice.data)
  );
  const fileLoadingState = useSelector((state) => state.fileLoadingReducer.fileLoadingState);


  useEffect(() => {
    dispatch(getGroup());
    return () => {};
  }, []);

  useEffect(() => {
    setActiveUrl((state) => {
      switch (url) {
        case "/app/vaccine-statements":
          return "1";
        case "/app/covid-statements":
          return "2";
        case "/app/police-statements":
          return "3";
        default:
          return "1";
      }
    });
    return () => {
      setActiveUrl("");
    };
  }, [url]);

  useEffect(() => {
    return () => {};
  }, [activeUrl]);
  const getFacilityIds = () => {
    if (isValidArray(facilitiesList)) {
      let addIds = "";
      facilitiesList.map((facility) => {
        addIds += facility.facility_id + ",";
      });
      addIds = addIds.substring(0, addIds.length - 1);
      return addIds;
    }
    // return null;
  };

  let facilitiesIds = getFacilityIds();
  useEffect(() => {
    dispatch(statementPolice(facilitiesIds)).then((record) => {
      if (isValidArray(record.payload.result)) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, [facilitiesIds]);

  const closePopover = () => {
    setVisibleMail(false);
  };

  const getGroupList = () => {
    if (isValidArray(groupList)) {
      return groupList.map((group) => {
        return (
          <Fragment key={group?.org_id}>
            <Option value={group?.org_id}>{group?.org_name}</Option>
          </Fragment>
        );
      });
    }
    // return null;
  };
  // const getFacilityList = () => {
  //   if (isValidArray(facilitiesList)) {
  //     return facilitiesList.map((facility) => {
  //       return (
  //         <Fragment key={facility?.facility_id}>
  //           <Option value={facility?.facility_id}>
  //             {facility?.facility_name}
  //           </Option>
  //         </Fragment>
  //       );
  //     });
  //   }
  //   // return null;
  // };

  const handleOnChange = (event) => {
    setLoading(true);
    setOrgId(event);
    dispatch(getFacility(event));
  };

  const facilityFunction = (elm) => {
    setFacilityName(elm?.facility_name);
    setFacilityId(elm?.facility_id);
  };
  const setMail = (elm) => {
    setVisibleMail(elm.id);
    if (visibleMail === elm.id) {
      setVisibleMail(false);
    }
  };

  const uploadSettings = {
    accept: ".pdf",
    beforeUpload: (file) => {
      if (file.type !== "application/pdf") {
        message.error(`${file.name} is not a pdf file`);
      }
      uploadStatement(file.type !== "application/pdf" ? false : true);
      return false;
    },
    onChange: (info) => {
      let selectedFile = info.fileList;
      let file = null;
      let fileName;
      if (selectedFile.length > 0) {
        let fileToLoad = selectedFile[selectedFile.length - 1].originFileObj;
        fileName = fileToLoad.name;
        let reader = new FileReader();
        reader.onload = function (fileEvent) {
          file = fileEvent.target.result;
          let name = fileName.split(".");
          const uploadInfo = {
            facility_id: facilityId,
            file: file,
            filename: name[0],
            facility_name: facilityName,
            stmt_type: "police_check_stmt",
          };

          dispatch(uploadStatement(uploadInfo)).then(() => {
            dispatch(statementPolice(facilitiesIds));
            setFacilityName("");
            setFacilityId("");
            toast.success("File uploaded successfully");
          });
        };
        reader.readAsDataURL(fileToLoad);
      }
    },
  };
  // const handleVisibleChange = (elm) => {
  //   setVisibleMail(visibleMail === true);
  // };
  console.log(visibleMail, "visibleMail");

  const columns = [
    {
      title: "Facility Name",
      dataIndex: "facility_name",
      key: "FacilityName",
      width: "50%",
      sorter: (a, b) =>
        a.facility_name.toUpperCase().charCodeAt(0) -
        b.facility_name.toUpperCase().charCodeAt(0),
      filters: facilitiesList
        ?.map((f) => {
          return { text: f.facility_name, value: f.facility_name };
        })
        .sort(
          (a, b) =>
            a.text.toUpperCase().charCodeAt(0) -
            b.text.toUpperCase().charCodeAt(0)
        ),
      onFilter: (value, record) => record.facility_name.includes(value),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "50%",
      render: (_, elm, row) => (
        <div>
          <span className="mr-3">
            {policeList
              .filter((item) => elm.facility_id == item.facility_id)
              .map((item) => {
                return item?.pdf_link ? (
  
                    <Tooltip title="View" color="rgb(20, 72, 129)">
                    <Button onClick={() => getPreSignedUrl(item.pdf_link)}  >
                        { fileLoadingState !== item.pdf_link ? <EyeOutlined /> : <Spin size="small" />}
                      </Button>
                    </Tooltip>
                
                ) : (
                  <a target="_blank" href="">
                    <Tooltip title="View" color="rgb(20, 72, 129)">
                      <Button disabled>
                        <EyeOutlined style={{ color: "#00000040" }} />
                      </Button>
                    </Tooltip>
                  </a>
                );
              })}
          </span>
          <span className="mr-3">
            {policeList
              .filter((item) => elm.facility_id == item.facility_id)
              .map((item) => {
                return item?.pdf_link ? (
                  <Popover
                    title="Additional Emails"
                    content={
                      <Email passData={elm} visibleMailprop={closePopover} />
                    }
                    visible={elm.id === visibleMail}
                    trigger="click"
                  >
                    <Tooltip title="Mail" color="rgb(20, 72, 129)">
                      <Button onClick={() => setMail(elm)}>
                        <MailOutlined />
                      </Button>
                    </Tooltip>
                  </Popover>
                ) : (
                  <Tooltip title="Mail" color="rgb(20, 72, 129)">
                    <Button disabled>
                      <MailOutlined style={{ color: "#00000040" }} />
                    </Button>
                  </Tooltip>
                );
              })}
          </span>
          <span>
            <Tooltip title="Upload" color="rgb(20, 72, 129)">
              <Upload {...uploadSettings}>
                <Button onClick={() => facilityFunction(elm)}>
                  <UploadOutlined />
                </Button>
              </Upload>
            </Tooltip>
          </span>
        </div>
      ),
    },
  ];

  return (
    <Form layout="horizontal">
      <Row>
        <Col span={24}>
          <Form.Item>
            <div style={{ float: "right", width: "30%" }}>
              <Select onChange={handleOnChange} placeholder="Select Group">
                {getGroupList()}
              </Select>
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Table
        loading={loading}
        columns={columns}
        dataSource={policeList}
        rowKey="id"
      />
    </Form>
  );
};

export default PoliceStatement;
