import {
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAILURE,
} from "../constants/MyM";
import { putApi } from "../../utils/apiCall";

const updateVehicleSuccess = (data) => {
  return {
    type: UPDATE_VEHICLE_SUCCESS,
    payload: data,
  };
};

const updateVehicleFailure = (data) => {
  return {
    type: UPDATE_VEHICLE_FAILURE,
    payload: data,
  };
};

export const updateVehicle = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await putApi(`/vehicle_details`, {
      ...data,
    });
    if (!error) {
      return dispatch(updateVehicleSuccess(result));
    }
    return dispatch(updateVehicleFailure(result));
  };
};
