import { GET_MANAGE_HOLIDAY_SUCCESS, GET_MANAGE_HOLIDAY_FAILURE } from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const getManageHolidaySuccess = (data) => {
  return {
    type: GET_MANAGE_HOLIDAY_SUCCESS,
    payload: data,
  };
};

const getManageHolidayFailure = (data) => {
  return {
    type: GET_MANAGE_HOLIDAY_FAILURE,
    payload: data,
  };
};

export const getManageHoliday = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await postApi(`/get_manage_holiday_details`, {
      ...data,
    });
    if (!error) {
      return dispatch(getManageHolidaySuccess(result));
    }
    return dispatch(getManageHolidayFailure(result));
  };
};
