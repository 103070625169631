import React, { memo } from "react";
import { Card, Tabs } from "antd";
import { StickyContainer, Sticky } from "react-sticky";
import IsoCertificate from "./isoCertificate";
import Cm3Certificate from "./Cm3Certificate";
import LasaCertificate from "./LasaCertificate";
import LabourCertificate from "./LabourCertificate";

const { TabPane } = Tabs;

const Certificates = () => {
  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar
          {...props}
          style={{ ...style, zIndex: 1, background: "#fff" }}
        />
      )}
    </Sticky>
  );


  return (
    <Card>
      <StickyContainer>
        <Tabs
          defaultActiveKey="1"
          renderTabBar={renderTabBar}
        >
          <TabPane tab="ISO Certificates" key="1">
            <IsoCertificate />
          </TabPane>
          <TabPane tab="CM3 Certificate" key="2">
            <Cm3Certificate />
          </TabPane>
          {/* <TabPane tab="LASA Certificate" key="3">
            <LasaCertificate />
          </TabPane> */}
          <TabPane tab="Labour Hire Certificate" key="4">
            <LabourCertificate />
          </TabPane>
        </Tabs>
      </StickyContainer>
    </Card>
  );
};

export default memo(Certificates);
