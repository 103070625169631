import {
    ADD_NEW_STAFF_SUCCESS,
    ADD_NEW_STAFF_FAILURE,
  } from "../constants/MyM";
  import { postApi } from "../../utils/apiCall";
  
  const addNewStaffSuccess = (data) => {
    return {
      type: ADD_NEW_STAFF_SUCCESS,
      payload: data,
    };
  };
  
  const addNewStaffFailure = (data) => {
    return {
      type: ADD_NEW_STAFF_FAILURE,
      payload: data,
    };
  };
  
  export const addNewStaff = (data) => {
    return async (dispatch, getState) => {
      const { error, result } = await postApi(`/add_adhoc_staff`, {
        ...data,
      });
      if (!error) {
        return dispatch(addNewStaffSuccess(result));
      }
      return dispatch(addNewStaffFailure(result));
    };
  };
  