import {
  DELETE_TIMESHEET_SUCCESS,
  DELETE_TIMESHEET_FAILURE,
} from "../constants/MyM";
  
const initStat = { data: [], error: false, errorData: "", isLoading: true };
  
export default (state = initStat, action) => {
    switch (action.type) {
      case DELETE_TIMESHEET_SUCCESS:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
        };
      case DELETE_TIMESHEET_FAILURE:
        return {
          ...state,
          isLoading: false,
          errorData: action.payload,
          error: true,
        };
      default:
        return { ...state };
    }
};
  