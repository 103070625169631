import {
  DELETE_VEHICAL_SUCCESS,
  DELETE_VEHICAL_FAILURE,
} from "../constants/MyM";
import { deleteApi } from "../../utils/apiCall";

const deleteVehicalSuccess = (data) => {
  return {
    type: DELETE_VEHICAL_SUCCESS,
    payload: data,
  };
};

const deleteVehicalFailure = (data) => {
  return {
    type: DELETE_VEHICAL_FAILURE,
    payload: data,
  };
};

export const deleteVehical = (id) => {
  return async (dispatch, getState) => {
    const { error, result } = await deleteApi(`/vehicle_details/${id}`);
    if (!error) {
      return dispatch(deleteVehicalSuccess(result));
    }
    return dispatch(deleteVehicalFailure(result));
  };
};
