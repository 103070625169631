import {
  GET_PUBLIC_LIABILITY_SUCCESS,
  GET_PUBLIC_LIABILITY_FAILURE,
} from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const publicSuccess = (data) => {
  return {
    type: GET_PUBLIC_LIABILITY_SUCCESS,
    payload: data,
  };
};

const publicFailure = (data) => {
  return {
    type: GET_PUBLIC_LIABILITY_FAILURE,
    payload: data,
  };
};

export const getPublicLiability = () => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/get_certificate_details`);
    if (!error) {
      return dispatch(publicSuccess(result));
    }
    return dispatch(publicFailure(result));
  };
};
