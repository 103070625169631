import { GET_CONTACT_FACILITY_SUCCESS, GET_CONTACT_FACILITY_FAILURE } from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const contactFacilitySuccess = (data) => {
  return {
    type: GET_CONTACT_FACILITY_SUCCESS,
    payload: data,
  };
};

const contactFacilityFailure = (data) => {
  return {
    type: GET_CONTACT_FACILITY_FAILURE,
    payload: data,
  };
};

export const getContactFacility = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/all_fac_list_mym_web`, {
      ...data,
    });
    if (!error) {
      return dispatch(contactFacilitySuccess(result));
    }
    return dispatch(contactFacilityFailure(result));
  };
};
