import {
  GET_UPLOAD_TIMESHEET_SUCCESS,
  GET_UPLOAD_TIMESHEET_FAILURE,
} from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const getUploadTimesheetSuccess = (data) => {
  return {
    type: GET_UPLOAD_TIMESHEET_SUCCESS,
    payload: data,
  };
};

const getUploadTimesheetFailure = (data) => {
  return {
    type: GET_UPLOAD_TIMESHEET_FAILURE,
    payload: data,
  };
};

export const getUploadTimesheet = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await postApi(`/get_timesheet_details`, {
      ...data,
    });
    if (!error) {
      return dispatch(getUploadTimesheetSuccess(result));

    }
    return dispatch(getUploadTimesheetFailure(result));
  };
};
