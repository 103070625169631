import {
    GET_FACILITIES_BY_MANAGER_SUCCESS,
    GET_FACILITIES_BY_MANAGER_FAILURE,
  } from "../constants/MyM";
  import { getApi } from "utils/apiCall";
  
  const getFacilitiesByManagerSuccess = (data) => {
    return {
      type: GET_FACILITIES_BY_MANAGER_SUCCESS,
      payload: data,
    };
  };
  
  const getFacilitiesByManagerFailure = (data) => {
    return {
      type: GET_FACILITIES_BY_MANAGER_FAILURE,
      payload: data,
    };
  };
  
  export const getFacilitiesByManager = (id) => {
    return async (dispatch, getState) => {
      const { error, result } = await getApi(`/get_facilities_by_manager/${id}`);
      if (!error) {
        return dispatch(getFacilitiesByManagerSuccess(result));
      }
      return dispatch(getFacilitiesByManagerFailure(result));
    };
  };
