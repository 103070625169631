import { POST_OUTSTANDING_FACILITY_SUCCESS, POST_OUTSTANDING_FACILITY_FAILURE } from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const postOutstandingFacilitySuccess = (data) => {
  return {
    type: POST_OUTSTANDING_FACILITY_SUCCESS,
    payload: data,
  };
};

const postOutstandingFacilityFailure = (data) => {
  return {
    type: POST_OUTSTANDING_FACILITY_FAILURE,
    payload: data,
  };
};

export const getOutstandingFacility = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await postApi(`/get_outsatnding_facilities`, {
      ...data,
    });
    if (!error) {
      return dispatch(postOutstandingFacilitySuccess(result));
    }
    return dispatch(postOutstandingFacilityFailure(result));
  };
};
