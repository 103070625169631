import { GET_EXITS_EMAIL_EDIT_SUCCESS, GET_EXITS_EMAIL_EDIT_FAILURE } from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const getExistEmailAyEditSuccess = (data) => {
  return {
    type: GET_EXITS_EMAIL_EDIT_SUCCESS,
    payload: data,
  };
};

const getExistEmailAtEditFailure = (data) => {
  return {
    type: GET_EXITS_EMAIL_EDIT_FAILURE,
    payload: data,
  };
};

export const getExistEmailAtEditFacilityManagement = (email) => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/is_email_exist_edit?email=${email}`);
    if (!error) {
      return dispatch(getExistEmailAyEditSuccess(result));
    }
    return dispatch(getExistEmailAtEditFailure(result));
  };
};
