import React, { memo, useEffect, useState } from "react";
import { Table, Form, Button, Row, Col, Select } from "antd";
import { getApiArrayDetails, isValidArray, getApiSingleDetails } from "../../../utils/MyMUtils";
import { useDispatch, useSelector } from "react-redux";
import { DownloadOutlined } from "@ant-design/icons";
import { educationList } from "redux/actions/getEducationList.action";
import { getEducationRegisterData } from "redux/actions/getEducationRegister.action";
import { toast } from "react-toastify";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import generatePDFRegister from "./generatePdfRegister";

const { Option } = Select;

const EducationalRegister = ({ csvData }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const [loading, setLoading] = useState(false);
  const [register, setRegister] = useState([]);
  const [visibleMail, setVisibleMail] = useState(false);
  const [fileName, setFileName] = useState("Monthly Education Register");

  const dispatch = useDispatch();

  const EducationList = useSelector((state) => getApiArrayDetails(state.getEducationList.data));
  const getEducationRegister = useSelector((state) =>
    getApiSingleDetails(state.getEducationRegister.data)
  );

  useEffect(() => {
    dispatch(educationList());
  }, []);

  const getEducationListFunction = () => {
    if (isValidArray(EducationList)) {
      return EducationList?.map((item) => {
        return <Option value={(item?.title, item?.id)}>{item?.title}</Option>;
      });
    }
  };

  useEffect(() => {
    setRegister(getEducationRegister);
  }, [getEducationRegister]);

  const exportToCSV = (csvData, fileName) => {
    if (csvData) {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      toast.error("No Data in Table!");
    }
  };

  const excelData = () => {
    if (isValidArray(register)) {
      return register?.map((item) => {
        return {
          "User Name": item.user_name,
          State: item.state_name,
          Status: item.status === true ? "Yes" : "No",
        };
      });
    }
  };

  const handleOnChange = (id, object) => {
    setLoading(true);
    dispatch(getEducationRegisterData(object?.value, object?.children)).then((record) => {
      if (isValidArray(record.payload.result)) {
        setLoading(false);
      } else {
        setLoading(false);
        setRegister([]);
      }
    });
  };

  const closePopover = () => {
    setVisibleMail(false);
  };

  const setMail = (elm) => {
    setVisibleMail(elm.id);
    if (visibleMail === elm.id) {
      setVisibleMail(false);
    }
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "user_name",
      width: "33%",
    },
    {
      title: "State",
      dataIndex: "state_name",
      key: "state_name",
      width: "33%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "33%",
      render: (text) => {
        return <p className="text-capitalize">{text === true ? "Yes" : "No"}</p>;
      },
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   width: "50%",
    //   render: (_, elm, row) => (
    //     <div>
    //       <span className="mr-3">
    //         {register &&
    //           register
    //             ?.filter((item) => elm.file_name == item.file_name)
    //             .map((item) => {
    //               return item?.path ? (
    //                 <a target="_blank" href={item?.path}>
    //                   <Tooltip title="View" color="rgb(20, 72, 129)">
    //                     <Button>
    //                       <EyeOutlined />
    //                     </Button>
    //                   </Tooltip>
    //                 </a>
    //               ) : (
    //                 <a target="_blank" href="https://www.youtube.com/">
    //                   <Tooltip title="View" color="rgb(20, 72, 129)">
    //                     <Button disabled>
    //                       <EyeOutlined style={{ color: "#00000040" }} />
    //                     </Button>
    //                   </Tooltip>
    //                 </a>
    //               );
    //             })}
    //       </span>
    //       <span className="mr-3">
    //         {register &&
    //           register
    //             .filter((item) => elm.file_name == item.file_name)
    //             .map((item) => {
    //               return item?.path ? (
    //                 <Popover
    //                   title="Additional Emails"
    //                   content={
    //                     <Email passData={elm} visibleMailprop={closePopover} />
    //                   }
    //                   visible={elm.id === visibleMail}
    //                   trigger="click"
    //                 >
    //                   <Tooltip title="Mail" color="rgb(20, 72, 129)">
    //                     <Button onClick={() => setMail(elm)}>
    //                       <MailOutlined />
    //                     </Button>
    //                   </Tooltip>
    //                 </Popover>
    //               ) : (
    //                 <Tooltip title="Mail" color="rgb(20, 72, 129)">
    //                   <Button disabled>
    //                     <MailOutlined style={{ color: "#00000040" }} />
    //                   </Button>
    //                 </Tooltip>
    //               );
    //             })}
    //       </span>
    //     </div>
    //   ),
    // },
  ];

  return (
    <>
      <Form layout="horizontal">
        <Row gutter={24}>
          <Col span={14}></Col>
          <Col span={6}>
            <Form.Item>
              <div>
                <Select onChange={handleOnChange} placeholder="Select Option" allowClear>
                  {getEducationListFunction()}
                </Select>
              </div>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Button
              className="ml-2"
              style={{ float: "right" }}
              onClick={() => generatePDFRegister(register, columns)}
              icon={<DownloadOutlined />}
            >
              PDF
            </Button>
            <Button
              style={{ float: "right" }}
              onClick={(e) => exportToCSV(excelData(), fileName)}
              icon={<DownloadOutlined />}
            >
              Excel
            </Button>
          </Col>
        </Row>
        <Table
          loading={loading}
          dataSource={isValidArray(register) ? register : ""}
          columns={columns}
          rowKey="id"
        />
      </Form>
    </>
  );
};

export default memo(EducationalRegister);
