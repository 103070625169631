import React, { memo } from "react";
import { Card, Tabs } from "antd";
import { StickyContainer, Sticky } from "react-sticky";

import FacilityContact from "./FacilityContact";
import GroupContact from "./groupContact";
import ContractedHours from "./contractedHours"
import AdhocFacilityManagement from "./adhocFacilityManagement";
import OutstandingFacility from "./outstandingFacility"

const { TabPane } = Tabs;

const ContactDetail = () => {
  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: "#fff" }} />
      )}
    </Sticky>
  );

  return (
    <Card>
      <StickyContainer>
        <Tabs renderTabBar={renderTabBar}>
          <TabPane tab="Group" key="1">
            <GroupContact />
          </TabPane>
          <TabPane tab="Facility" key="2">
            <FacilityContact />
          </TabPane>
          <TabPane tab="Contracted Hours" key="3">
            <ContractedHours />
          </TabPane>
          <TabPane tab="Adhoc Facility" key="4">
            <AdhocFacilityManagement />
          </TabPane>
          <TabPane tab="Outstanding Facility" key="5">
            <OutstandingFacility />
          </TabPane>
        </Tabs>
      </StickyContainer>
    </Card>
  );
};

export default memo(ContactDetail);
