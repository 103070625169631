import React, { memo, useState, useEffect, useRef, useCallback } from "react";
import {
  Table,
  Form,
  Button,
  Space,
  Row,
  Col,
  Upload,
  Tooltip,
  Input,
  Modal,
  DatePicker,
} from "antd";
import {
  DownloadOutlined,
  UploadOutlined,
  CloudUploadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { WeeklyCalendar } from "react-week-picker";
import "react-week-picker/src/lib/calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { uploadTimesheet } from "../../../redux/actions/uploadTimesheet.action";
import { getUploadTimesheet } from "../../../redux/actions/getUploadTimesheet.action";
import { getApiArrayDetails } from "../../../utils/MyMUtils";
import moment from "moment";

const { Dragger } = Upload;

const TimesheetList = () => {
  const dispatch = useDispatch();
  let searchInput = useRef(null);
  const AnotherFormat = "YYYY-MM-DD";
  /* moment.updateLocale('en', {
    weekdaysMin : [ "Mon", "Tue", "Wed", "Thu", "Fri", "Sat","Sun",]
  }); */
  moment.locale("en", {
    week: {
      dow: 1, /// Date offset
    },
  });
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [downloadStartDate, setDownloadStartDate] = useState("");
  const [downloadEndDate, setDownloadEndDate] = useState("");
  const [currentStartDate, setCurrentStartDate] = useState("");
  const [currentEndDate, setCurrentEndDate] = useState("");
  const [uploadStartDate, setUploadStartDate] = useState("");
  const [uploadEndDate, setUploadEndDate] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [fileValue, setFileValue] = useState("");

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  var currentDate = moment();

  const TimesheetData = useSelector((state) =>
    getApiArrayDetails(state.getTimesheetData.data)
  );

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  useEffect(() => {
    var weekStart = currentDate.clone().startOf("week");
    var weekEnd = currentDate.clone().endOf("week");
    const start_date = moment(weekStart).format(AnotherFormat);
    const end_date = moment(weekEnd).format(AnotherFormat);
    setCurrentStartDate(start_date);
    setCurrentEndDate(end_date);
    const getuploadPdf = {
      from_date: start_date,
      to_date: end_date,
    };
    dispatch(getUploadTimesheet(getuploadPdf)).then(() => {
      setLoading(false);
    });
  }, []);

  const handleWeekPick = (startDate, endDate) => {
    setLoading(true);
    const start_date = moment(startDate).format(AnotherFormat);
    setDownloadStartDate(start_date);
    const end_date = moment(endDate).format(AnotherFormat);
    setDownloadEndDate(end_date);
    const getuploadPdf = {
      from_date: start_date,
      to_date: end_date,
    };
    dispatch(getUploadTimesheet(getuploadPdf)).then(() => {
      setLoading(false);
    });
  };

  const weekPicker = (value) => {
    setLoading(true);
    const start_date = moment(value).startOf("week").format(AnotherFormat);
    const end_date = moment(value).endOf("week").format(AnotherFormat);
    setDownloadStartDate(start_date);
    setDownloadEndDate(end_date);
    const getuploadPdf = {
      from_date: start_date,
      to_date: end_date,
    };
    dispatch(getUploadTimesheet(getuploadPdf)).then(() => {
      setLoading(false);
    });
  };

  const UploadWeekPick = (startDate, endDate) => {
    const start_date = moment(startDate).format(AnotherFormat);
    setUploadStartDate(start_date);
    const end_date = moment(endDate).format(AnotherFormat);
    setUploadEndDate(end_date);
  };

  const weekPickerforUpload = (value) => {
    const start_date = moment(value).startOf("week").format(AnotherFormat);
    const end_date = moment(value).endOf("week").format(AnotherFormat);
    setUploadStartDate(start_date);
    setUploadEndDate(end_date);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: "#1a3353" ? "#1a3353" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => text,
  });

  const columns = [
    {
      title: "Facility Name",
      dataIndex: "facility_name",
      key: "FacilityName",
      width: "20%",
      ...getColumnSearchProps("facility_name"),
    },
    {
      title: "Payroll Category",
      dataIndex: "payroll_category",
      key: "payroll_category",
      width: "20%",
      ...getColumnSearchProps("payroll_category"),
    },
    {
      title: "Hours",
      dataIndex: "total_hours",
      key: "total_hours",
      width: "20%",
    },
  ];

  const handleUpload = useCallback((info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    fileList.forEach(function (file, index) {
      let reader = new FileReader();
      reader.onload = (e) => {
        file.base64 = e.target.result;
        var data = file.base64.split(",");
        setFileValue(data[1]);
      };
      reader.readAsDataURL(file.originFileObj);
    });
  });

  const PostData = useCallback((data) => {
    setLoader(true);
    setLoading(true);
    const uploadPdf = {
      file: fileValue,
      from_date: uploadStartDate || currentStartDate,
      to_date: uploadEndDate || currentEndDate,
    };
    const getUploadPdf = {
      from_date: downloadStartDate || currentStartDate,
      to_date: downloadEndDate || currentEndDate,
    };
    dispatch(uploadTimesheet(uploadPdf)).then((record) => {
      if (record.payload.success) {
        toast.success(record.payload.message);
        setLoader(false);
        setVisible(false);
        dispatch(getUploadTimesheet(getUploadPdf)).then(() => {
          setLoading(false);
        });
      } else {
        toast.error(record.payload.message);
        setLoader(false);
        setVisible(false);
        dispatch(getUploadTimesheet(getUploadPdf)).then(() => {
          setLoading(false);
        });
      }
    });
  });

  const customWeekStartEndFormat = (value) => {
    return `${moment(value).startOf("week").format("DD-MM-YYYY")} ~ ${moment(
      value
    )
      .endOf("week")
      .format("DD-MM-YYYY")}`;
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
  };

  return (
    <Form layout="horizontal">
      <Row style={{ float: "right", marginBottom: "15px" }}>
        <div className="btn-weekpicker mr-2">
          <DatePicker
            onChange={weekPicker}
            defaultValue={moment()}
            format={customWeekStartEndFormat}
            picker="week"
          />
        </div>
        {/* <div>
          <Tooltip title="Download Sample Timesheet" color="rgb(20, 72, 129)">
            <a href="https://majestic-dev.s3.amazonaws.com/sample_timesheet.csv">
              <Button>
                <DownloadOutlined /> Sample Timesheet
              </Button>
            </a>
          </Tooltip>
        </div> */}
        <div style={{ marginLeft: "10px" }}>
          <Button
            className="style-btn"
            onClick={() => setVisible(true)}
            style={{ float: "right", marginBottom: "20px" }}
            icon={<UploadOutlined />}
            shape="round"
            size="default"
          >
            Upload Timesheet
          </Button>
        </div>
      </Row>
      <Table
        loading={loading}
        columns={columns}
        dataSource={TimesheetData}
        rowKey="id"
      />

      <Modal
        title="Upload"
        centered
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        width={700}
      >
        <Form onFinish={PostData} layout="vertical" form={form}>
          <h4>
            Download a sample{" "}
            <a href="https://majestic-dev.s3.amazonaws.com/sample_timesheet.csv">
              .csv formate
            </a>{" "}
            file and compare it with your import file to ensure that the file is
            ready to import.
          </h4>
          <Row gutter={24} className="justify-content-center mt-4">
            <Col md={24}>
              <Form.Item
                name="week"
                className="btn-weekpicker"
                label="Select Week:"
              >
                <DatePicker
                  onChange={weekPickerforUpload}
                  defaultValue={moment()}
                  format={customWeekStartEndFormat}
                  picker="week"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item
                name="file"
                label="Timesheet:"
                rules={[
                  {
                    required: true,
                    message: "Please Upload CSV File",
                  },
                ]}
              >
                <Dragger
                  onChange={(e) => handleUpload(e)}
                  maxCount={1}
                  accept=".csv"
                  showUploadList={true}
                  customRequest={dummyRequest}
                >
                  {/* <Button style={{ marginLeft: "15px" }}>
                    <UploadOutlined />
                    Click to Upload
                  </Button> */}

                  <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button htmlType="submit" loading={loader}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Form>
  );
};

export default memo(TimesheetList);
