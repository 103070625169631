import {
  POST_NEW_VEHICAL_SUCCESS,
  POST_NEW_VEHICAL_FAILURE,
} from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const postNewVehicalSuccess = (data) => {
  return {
    type: POST_NEW_VEHICAL_SUCCESS,
    payload: data,
  };
};

const postNewVehicalFailure = (data) => {
  return {
    type: POST_NEW_VEHICAL_FAILURE,
    payload: data,
  };
};

export const postNewVehical = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await postApi(`/vehicle_details`, {
      ...data,
    });
    if (!error) {
      return dispatch(postNewVehicalSuccess(result));
    }
    return dispatch(postNewVehicalFailure(result));
  };
};
