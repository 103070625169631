import React, { memo } from "react";
import { Card, Tabs } from "antd";
import { StickyContainer, Sticky } from "react-sticky";
import StaffList from "./StaffList";
import EducationalRegister from "./educationRegister";
import MonthlyEducation from "./monthlyEducation";
import EquipmentReport from "./EquipmentReport"
import AirPurifierReport from "./AirPurifierReport";

const { TabPane } = Tabs;

const Education = () => {
  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar
          {...props}
          style={{ ...style, zIndex: 1, background: "#fff" }}
        />
      )}
    </Sticky>
  );

  return (
    <Card>
      <StickyContainer>
        <Tabs renderTabBar={renderTabBar}>
          <TabPane tab="Staff List" key="1">
            <StaffList />
          </TabPane>
          <TabPane tab="Education Register" key="3">
            <EducationalRegister />
          </TabPane>
          <TabPane tab="Monthly Education" key="4">
            <MonthlyEducation />
          </TabPane>
          <TabPane tab="Equipment Report" key="5">
            <EquipmentReport />
          </TabPane>
          <TabPane tab="Air Purifier Scheduler" key="6">
            <AirPurifierReport />
          </TabPane>
          
        </Tabs>
      </StickyContainer>
    </Card>
  );
};

export default memo(Education);
