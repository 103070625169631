import {
    DELETE_MANAGER_SUCCESS,
    DELETE_MANAGER_FAILURE,
  } from "../constants/MyM";
  import { deleteApi } from "../../utils/apiCall";
  
  const deleteManagerSuccess = (data) => {
    return {
      type: DELETE_MANAGER_SUCCESS,
      payload: data,
    };
  };
  
  const deleteManagerFailure = (data) => {
    return {
      type: DELETE_MANAGER_FAILURE,
      payload: data,
    };
  };
  
  export const deleteManager = (id) => {
    return async (dispatch, getState) => {
      const { error, result } = await deleteApi(`/manager_contact_details/${id}`);
      if (!error) {
        return dispatch(deleteManagerSuccess(result));
      }
      return dispatch(deleteManagerFailure(result));
    };
  };
  