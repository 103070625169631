import {
    GET_ALL_LAUNDRY_SUCCESS,
    GET_ALL_LAUNDRY_FAILURE,
} from "../constants/MyM";

const initStat = { data: [], error: false, errorData: "", isLoading: true };

export default (state = initStat, action) => {
    switch (action.type) {
        case GET_ALL_LAUNDRY_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            };
        case GET_ALL_LAUNDRY_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorData: action.payload,
                error: true,
            };
        default:
            return { ...state };
    }
};
