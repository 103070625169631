import {
  GET_FACILITY_VACCINATION_SUCCESS,
  GET_FACILITY_VACCINATION_FAILURE,
} from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const vaccinationSuccess = (data) => {
  return {
    type: GET_FACILITY_VACCINATION_SUCCESS,
    payload: data,
  };
};

const vaccinationFailure = (data) => {
  return {
    type: GET_FACILITY_VACCINATION_FAILURE,
    payload: data,
  };
};

export const getVaccinationData = (ids) => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(
      `/vaccination_stmt?facility_id=${ids}&vaccination_type=1`
    );
    if (!error) {
      return dispatch(vaccinationSuccess(result));
    }
    return dispatch(vaccinationFailure(result));
  };
};
