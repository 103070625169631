import {
  POST_AUTO_LOGIN_SUCCESS,
  POST_AUTO_LOGIN_FAILURE,
} from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const postLoginSuccess = (data) => {
  return {
    type: POST_AUTO_LOGIN_SUCCESS,
    payload: data,
  };
};

const postLoginFailure = (data) => {
  return {
    type: POST_AUTO_LOGIN_FAILURE,
    payload: data,
  };
};

export const autoLogin = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await postApi(`/auto_login_app_web`, {
      ...data,
    });
    if (!error) {
      return dispatch(postLoginSuccess(result));

    }
    return dispatch(postLoginFailure(result));
  };
};
