import React, { memo, useEffect, useState } from "react";
import {
  Table,
  Modal,
  DatePicker,
  Form,
  Button,
  Select,
  Row,
  Col,
  Upload,
  Tabs,
  message,
  Tooltip,
  Popover,
  Spin,
} from "antd";
import { getApiArrayDetails, getPreSignedUrl, isValidArray } from "../../../utils/MyMUtils";
import { useDispatch, useSelector } from "react-redux";
import { EyeOutlined, MailOutlined, UploadOutlined } from "@ant-design/icons";
import { getPublicLiability } from "redux/actions/publicLiability.action";
import "../index.css";
import { toast } from "react-toastify";
import { uploadCertificate } from "redux/actions/uploadCertificate.action";
import { uploadIsoCertificate } from "redux/actions/uploadIsoCertificate.action";
import moment from "moment";
import Email from "../mail";

const dateFormat = "DD/MM/YYYY";
const AnotherFormat = "YYYY/MM/DD";

const IsoCertificate = () => {
  const [visible, setVisible] = useState(false);
  const [typeId, setTypeId] = useState("");
  const [stateId, setStateId] = useState("");
  const [name, setName] = useState("");
  const [uploadInfo, setUploadInfo] = useState({});
  const [visibleMail, setVisibleMail] = useState(false);

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const IsoCertifiacteDataList = useSelector((state) =>
    getApiArrayDetails(state.publicLiability.data)
  );
  const fileLoadingState = useSelector((state) => state.fileLoadingReducer.fileLoadingState);

  useEffect(() => {
    dispatch(getPublicLiability());
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  const getIsoCertificateData = () => {
    if (isValidArray(IsoCertifiacteDataList)) {
      return IsoCertifiacteDataList.filter((item) => item.type == "ISO").map(
        (dataValue) => {
          return dataValue;
        }
      );
    }
  };

  const setUploadModel = (elm) => {
    setVisible(true);
    setTypeId(elm?.type_id);
    setStateId(elm?.state_id);
    setName(elm?.name);
  };

  const postNewContractorFunction = (data) => {
    const abc = moment(data.expiry_date).format(AnotherFormat);
    dispatch(uploadCertificate(uploadInfo)).then((record) => {
      const uploadFinal = {
        type_id: typeId,
        state_id: stateId,
        name: name,
        expiry_date: abc,
        pdf_link: record.payload.result.result,
      };
      dispatch(uploadIsoCertificate(uploadFinal)).then((record) => {
        if (record.payload.result.success === true) {
          dispatch(getPublicLiability());
          toast.success(record.payload.result.message);
          setVisible(false);
        } else {
          toast.error(record.payload.result.message);
          setVisible(false);
        }
      });
    });
  };

  const uploadSettings = {
    accept: ".pdf",
    beforeUpload: (file) => {
      if (file.type !== "application/pdf") {
        message.error(`${file.name} is not a pdf file`);
      }
      return false;
    },
    onChange: (info) => {
      let selectedFile = info.fileList;
      let file = null;
      let fileName;
      if (selectedFile.length > 0) {
        let fileToLoad = selectedFile[selectedFile.length - 1].originFileObj;
        fileName = fileToLoad.name;
        let reader = new FileReader();
        reader.onload = function (fileEvent) {
          file = fileEvent.target.result;
          let name = fileName.split(".");
          const uploadInfo = {
            certificate_type: "ISO-certificates",
            filename: name[0],
            file: file,
          };
          setUploadInfo(uploadInfo);
        };
        reader.readAsDataURL(fileToLoad);
      }
    },
  };

  const closePopover = () => {
    setVisibleMail(false);
  };
  const setMail = (elm) => {
    setVisibleMail(elm.id);
    if (visibleMail === elm.id) {
      setVisibleMail(false);
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "50%",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "50%",
      render: (_, elm) => (
        <div>
          <span className="mr-3">
            <Tooltip title="View" color="rgb(20, 72, 129)">
              {getIsoCertificateData()
                .filter((item) => elm.name == item.name)
                .map((item, key) => {
                  return item?.pdf_link ? (
                    // <a target="_blank" href={item.pdf_link} key={key}>
                      <Button onClick={() => getPreSignedUrl(item.pdf_link)}  >
                        { fileLoadingState !== item.pdf_link ? <EyeOutlined /> : <Spin size="small" />}
                      </Button>
                    // </a>
                  ) : (
                    <a target="_blank" href="">
                      <Button disabled>
                        <EyeOutlined style={{ color: "#00000040" }} />
                      </Button>
                    </a>
                  );
                })}
            </Tooltip>
          </span>
          <span className="mr-3">
            {getIsoCertificateData()
              .filter((item) => elm.name == item.name)
              .map((item, key) => {
                return item?.pdf_link ? (
                  <Popover
                    title="Additional Emails"
                    content={
                      <Email passData={elm} visibleMailprop={closePopover} />
                    }
                    visible={elm.id === visibleMail}
                    trigger="click"
                  >
                    <Tooltip title="Mail" color="rgb(20, 72, 129)">
                      <Button onClick={() => setMail(elm)}>
                        <MailOutlined />
                      </Button>
                    </Tooltip>
                  </Popover>
                ) : (
                  <Tooltip title="Mail" color="rgb(20, 72, 129)">
                    <Button disabled>
                      <MailOutlined style={{ color: "#00000040" }} />
                    </Button>
                  </Tooltip>
                );
              })}
          </span>

          <span>
            <Tooltip title="Upload" color="rgb(20, 72, 129)">
              <Button onClick={() => setUploadModel(elm)}>
                <UploadOutlined />
              </Button>
            </Tooltip>
          </span>
        </div>
      ),
    },
  ];
  return (
    <>
      <Form layout="horizontal">
        <Table
          loading={!isValidArray(getIsoCertificateData())}
          columns={columns}
          dataSource={getIsoCertificateData()}
          rowKey="id"
        />
      </Form>
      <Modal
        title="Upload"
        centered
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        width={500}
      >
        <Form
          onFinish={postNewContractorFunction}
          layout="vertical"
          form={form}
        >
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item
                name="expiry_date"
                label="Expiry Date:"
                initialValue={moment(moment())}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Expiry Date",
                  },
                ]}
              >
                <DatePicker format={dateFormat} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please Upload File",
                  },
                ]}
                name="file"
                label="File:"
              >
                <Upload {...uploadSettings}>
                  {/* <Button onClick={() => policeCheckUpload(elm)}> */}
                  <Button onClick={() => setVisible(true)}>
                    <UploadOutlined />
                    Click to Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button htmlType="submit">Submit</Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(IsoCertificate);
