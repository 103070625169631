import { GET_MEETING_MINUTES_SUCCESS, GET_MEETING_MINUTES_FAILURE } from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const getMeetingMinutesSuccess = (data) => {
    return {
        type: GET_MEETING_MINUTES_SUCCESS,
        payload: data,
    };
};

const getMeetingMinutesFailure = (data) => {
    return {
        type: GET_MEETING_MINUTES_FAILURE,
        payload: data,
    };
};

export const getMeetingMinutesData = (data) => {
    return async (dispatch, getState) => {
        const { error, result } = await postApi(`/get_meeting_minutes`, {
            ...data,
        });
        if (!error) {
            return dispatch(getMeetingMinutesSuccess(result));
        }
        return dispatch(getMeetingMinutesFailure(result));
    };
};
