import { GET_USER_LIST_SUCCESS, GET_USER_LIST_FAILURE } from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const userListSuccess = (data) => {
  return {
    type: GET_USER_LIST_SUCCESS,
    payload: data,
  };
};

const userListFailure = (data) => {
  return {
    type: GET_USER_LIST_FAILURE,
    payload: data,
  };
};

export const getUserList = () => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/all_user_for_mymweb`);
    if (!error) {
      return dispatch(userListSuccess(result));
    }
    return dispatch(userListFailure(result));
  };
};
