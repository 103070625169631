import {
  UPDATE_HOLIDAY_SUCCESS,
  UPDATE_HOLIDAY_FAILURE,
} from "../constants/MyM";
import { putApi } from "../../utils/apiCall";

const updateHolidaySuccess = (data) => {
  return {
    type: UPDATE_HOLIDAY_SUCCESS,
    payload: data,
  };
};

const updateHolidayFailure = (data) => {
  return {
    type: UPDATE_HOLIDAY_FAILURE,
    payload: data,
  };
};

export const updateHoliday = (id, data) => {
  return async (dispatch, getState) => {
    const { error, result } = await putApi(`/edit_holiday_by_id/${id}`, data);
    if (!error) {
      return dispatch(updateHolidaySuccess(result));
    }
    return dispatch(updateHolidayFailure(result));
  };
};

