import { GET_GROUP_SUCCESS, GET_GROUP_FAILURE } from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const groupSuccess = (data) => {
  return {
    type: GET_GROUP_SUCCESS,
    payload: data,
  };
};

const groupFailure = (data) => {
  return {
    type: GET_GROUP_FAILURE,
    payload: data,
  };
};

export const getGroup = () => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/get_all_organization_list`);
    if (!error) {
      return dispatch(groupSuccess(result));
    }
    return dispatch(groupFailure(result));
  };
};
