import {
  GET_USER_WISE_FACILITY_SUCCESS,
  GET_USER_WISE_FACILITY_FAILURE,
} from "../constants/MyM";
import { getApi } from "utils/apiCall";

const getUserWiseFacilitySuccess = (data) => {
  return {
    type: GET_USER_WISE_FACILITY_SUCCESS,
    payload: data,
  };
};

const getUserWiseFacilityFailure = (data) => {
  return {
    type: GET_USER_WISE_FACILITY_FAILURE,
    payload: data,
  };
};

export const getUserWiseFacility = (id) => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(
      `/staff_user_wise_data_facility?user_id=${id}`
    );
    if (!error) {
      return dispatch(getUserWiseFacilitySuccess(result));
    }
    return dispatch(getUserWiseFacilityFailure(result));
  };
};
