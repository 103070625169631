import React from "react";
import { Button, Row, Col, Divider, Form, Input,Spin } from "antd";
import { LeftSquareOutlined, FileDoneOutlined } from "@ant-design/icons";

function Step4(props) {
  
  const prev = () => {
    props.prev();
  };

  const onFinish = (values) => {
    props.done(values);
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <>
    {props.loader ? (
    <>
    <div className="steps-content">
      <div className="d-flex justify-content-center loader-spin">
        <Spin size="large"/>
      </div>
      </div>
       </>
       ) : (
        <>
        <Form labelCol={{ span: 9 }} wrapperCol={{ span: 16 }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <div className="steps-content">
        <Divider style={{ fontSize: "22px" }}>Login</Divider>
        <p></p>
        <Row gutter={24} style={{ marginLeft: "0px", marginRight: "0px" }} className="d-flex justify-content-center">
          <div className="flex-column">
            <Col lg={24} md={24} sm={24} xs={24}>
              <Form.Item className="mt-3" label="Username" name="username" rules={[{ required: true, message: "Please input your username!" }]}>
                <Input defaultValue="" placeholder="Enter Username" />
              </Form.Item>
            </Col>

            <Col lg={24} md={24} sm={24} xs={24}>
              <Form.Item className="mt-3" label="Password" name="password" rules={[{ required: true, message: "Please input your password!" }]}>
                <Input defaultValue="" placeholder="Enter Password" />
              </Form.Item>
            </Col> 
          </div>
         
        </Row>
      </div>
      <Row gutter={24} className="mt-2">
        <Col lg={12} md={12} sm={24} xs={24}></Col>
        <Col lg={12} md={12} sm={24} xs={24} className="text-right">
          <Button shape="round" className="mr-3" icon={<LeftSquareOutlined />} onClick={prev}>
            Prev
          </Button>
          <Button
            shape="round"
            htmlType="submit"
            icon={<FileDoneOutlined />}
          >
            Done
          </Button>
        </Col>
      </Row>
    </Form>
        </>
      )}
     
    </>
  );
}

export default Step4;
