import {
  GET_STATE_LIST_SUCCESS,
  GET_STATE_LIST_FAILURE,
} from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const stateListSuccess = (data) => {
  return {
    type: GET_STATE_LIST_SUCCESS,
    payload: data,
  };
};

const stateListFailure = (data) => {
  return {
    type: GET_STATE_LIST_FAILURE,
    payload: data,
  };
};

export const getStateList = () => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/get_all_statelist`);
    if (!error) {
      return dispatch(stateListSuccess(result));
    }
    return dispatch(stateListFailure(result));
  };
};
