import { POST_NEW_STAFF_INFO_SUCCESS, POST_NEW_STAFF_INFO_FAILURE } from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const postNewStaffSuccess = (data) => {
  return {
    type: POST_NEW_STAFF_INFO_SUCCESS,
    payload: data,
  };
};

const postNewStaffFailure = (data) => {
  return {
    type: POST_NEW_STAFF_INFO_FAILURE,
    payload: data,
  };
};

export const postNewStaff = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await postApi(`/assign_facility_to_staff`, {
      ...data,
    });
    if (!error) {
      return dispatch(postNewStaffSuccess(result));
    }
    return dispatch(postNewStaffFailure(result));
  };
};
