import { DELETE_HOLIDAY_SUCCESS, DELETE_HOLIDAY_FAILURE } from "../constants/MyM";
import { deleteApi } from "../../utils/apiCall";

const deleteHolidaySuccess = (data) => {
  return {
    type: DELETE_HOLIDAY_SUCCESS,
    payload: data,
  };
};

const deleteHolidayFailure = (data) => {
  return {
    type: DELETE_HOLIDAY_FAILURE,
    payload: data,
  };
};

export const deleteHoliday = (id) => {
  return async (dispatch, getState) => {
    const { error, result } = await deleteApi(`/delete_holiday_by_id/${id}`);
    if (!error) {
      return dispatch(deleteHolidaySuccess(result));
    }
    return dispatch(deleteHolidayFailure(result));
  };
};
