import { POST_NEW_GROUP_SUCCESS, POST_NEW_GROUP_FAILURE } from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const postNewGroupSuccess = (data) => {
  return {
    type: POST_NEW_GROUP_SUCCESS,
    payload: data,
  };
};

const postNewGroupFailure = (data) => {
  return {
    type: POST_NEW_GROUP_FAILURE,
    payload: data,
  };
};

export const postNewGroup = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await postApi(`/add_organization_data`, {
      ...data,
    });
    if (!error) {
      return dispatch(postNewGroupSuccess(result));
    }
    return dispatch(postNewGroupFailure(result));
  };
};
