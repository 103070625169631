import jsPDF from "jspdf";
import "jspdf-autotable";
import { isValidArray } from "../../../utils/MyMUtils";
import { toast } from "react-toastify";

const generatePDF = (data, column) => {
  const doc = new jsPDF({
    orientation: "l",
    unit: "mm",
    format: "a2",
    putOnlyUsedFonts: true,
  });

  const tableArray = ["Group","Facility","State","Facility Address","Facility Manager Name","Facility Manager Email","Cleaning Service StartDate","Service"];

  const tableRows = [];

  if (isValidArray(data)) {
    data.forEach((d) => {
      const data = [d.org_name, d.facility_name,d.state_name,d.facility_address,d.manager_name,d.manager_email,d.cleaning_service_startdate,d.service.map((e)=>{ return e.service_name})];
      tableRows.push(data); 
    });

    doc.autoTable(tableArray, tableRows, { startY: 10, startX: 0 });
    const date = Date().split(" ");

    const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];

    doc.save(`Facility Details.pdf`);
  } else {
    toast.error("No Data in Table!");
  }
};

export default generatePDF;