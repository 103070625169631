import {
  GET_MANAGER_CONTRACTOR_SUCCESS,
  GET_MANAGER_CONTRACTOR_FAILURE,
} from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const contractorSuccess = (data) => {
  return {
    type: GET_MANAGER_CONTRACTOR_SUCCESS,
    payload: data,
  };
};

const contractorFailure = (data) => {
  return {
    type: GET_MANAGER_CONTRACTOR_FAILURE,
    payload: data,
  };
};

export const getContractorData = () => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/contractor_contact_details`);
    if (!error) {
      return dispatch(contractorSuccess(result));
    }
    return dispatch(contractorFailure(result));
  };
};
