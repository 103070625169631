import jsPDF from "jspdf";
import "jspdf-autotable";
import { isValidArray } from "../../../utils/MyMUtils";
import { toast } from "react-toastify";

const generatePDFManagement = (data, column) => {
  const doc = new jsPDF({
    orientation: "l",
    unit: "mm",
    format: "a2",
    putOnlyUsedFonts: true,
  });

  const tableArray = ["First Name","Last Name","Job Title","Phone Number","Email", "Address","State"];

  const tableRows = [];

  if (isValidArray(data)) {
    data.forEach((d) => {
      const data = [d.first_name,d.last_name,d.job_title,d.mobile,d.email,d.address,d.state];
      tableRows.push(data); 
    });

    doc.autoTable(tableArray, tableRows, { startY: 10, startX: 0 });
    const date = Date().split(" ");

    const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];

    doc.save(`Management Details.pdf`);
  } else {
    toast.error("No Data in Table!");
  }
};

export default generatePDFManagement;