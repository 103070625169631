import { GET_STAFF_LIST_ORG_SUCCESS, GET_STAFF_LIST_ORG_FAILURE } from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const getStaffListOrgSuccess = (data) => {
  return {
    type: GET_STAFF_LIST_ORG_SUCCESS,
    payload: data,
  };
};

const getStaffListOrgFailure = (data) => {
  return {
    type: GET_STAFF_LIST_ORG_FAILURE,
    payload: data,
  };
};

export const getStaffListOrganization = (data) => {
    return async (dispatch, getState) => {
      const { error, result } = await getApi(`/staff_details?org_id=${data}`);
      if (!error) {
        return dispatch(getStaffListOrgSuccess(result));
      }
      return dispatch(getStaffListOrgFailure(result));
    };
};
