import { GET_EXITS_EMAIL_SUCCESS, GET_EXITS_EMAIL_FAILURE } from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const getExistEmailSuccess = (data) => {
  return {
    type: GET_EXITS_EMAIL_SUCCESS,
    payload: data,
  };
};

const getExistEmailFailure = (data) => {
  return {
    type: GET_EXITS_EMAIL_FAILURE,
    payload: data,
  };
};

export const getExistEmailFacilityManagement = (email) => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/is_email_exist?email=${email}`);
    if (!error) {
      return dispatch(getExistEmailSuccess(result));
    }
    return dispatch(getExistEmailFailure(result));
  };
};
