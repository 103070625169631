import jsPDF from "jspdf";
import "jspdf-autotable";
import { isValidArray } from "../../../utils/MyMUtils";
import { toast } from "react-toastify";

const generatePDF = (data, column) => {
  const doc = new jsPDF({
    orientation: "l",
    unit: "mm",
    format: "a2",
    putOnlyUsedFonts: true,
  });

  const tableArray = ["Group","Contact Name","Email","Contact Number"];

  const tableRows = [];

  if (isValidArray(data)) {
    data.forEach((d) => {
      const data = [d.group_name, d.contact_name,d.email,d.contact_number];
      tableRows.push(data); 
    });

    doc.autoTable(tableArray, tableRows, { startY: 10, startX: 0 });
    const date = Date().split(" ");

    const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];

    doc.save(`Group Details.pdf`);
  } else {
    toast.error("No Data in Table!");
  }
};

export default generatePDF;
