import React, { memo } from "react";
import { Card, Tabs } from "antd";
import { StickyContainer, Sticky } from "react-sticky";
import PublicHoliday from "./PublicHoliday";
import ManageHoliday from "./ManageHoliday"

const { TabPane } = Tabs;

const Setting = () => {
  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar
          {...props}
          style={{ ...style, zIndex: 1, background: "#fff" }}
        />
      )}
    </Sticky>
  );
  const changeTab = (activeKey) => {};

  return (
    <>
      <Card>
        <StickyContainer>
          <Tabs
            defaultActiveKey="1"
            renderTabBar={renderTabBar}
            onChange={changeTab}
          >
            <TabPane tab="Public Holiday" key="1">
              <PublicHoliday />
            </TabPane>
            <TabPane tab="Manage Holiday" key="2">
              <ManageHoliday />
            </TabPane>
          </Tabs>
        </StickyContainer>
      </Card>
    </>
  );
};
export default memo(Setting);
