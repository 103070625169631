import { DELETE_ADHOC_FACILITY_SUCCESS, DELETE_ADHOC_FACILITY_FAILURE } from "redux/constants/MyM"
import { deleteApi } from "utils/apiCall";

const deleteAdhocFacilitySuccess = (data) => {
    return {
        type: DELETE_ADHOC_FACILITY_SUCCESS,
        payload: data
    }
}

const deleteAdhocFacilityFailure = (data) => {
    return {
        type: DELETE_ADHOC_FACILITY_FAILURE,
        payload: data
    }
}

export const deleteAdhocFacility = (id) => {
    return async (dispatch, getState) => {
        const { error, result } = await deleteApi(`/delete_adhoc_facility_by_id/${id}`);
        if (!error) {
            return dispatch(deleteAdhocFacilitySuccess(result))
        }
        return dispatch(deleteAdhocFacilityFailure(result))
    }
}
