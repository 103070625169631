import React from "react";
import { Card, Tabs } from "antd";
import { StickyContainer, Sticky } from "react-sticky";
import NewStaff from "./newStaff";
import ExistingStaff from "./existingStaff";
import AgencyStaff from "./agencyStaff"

const { TabPane } = Tabs;

const UserManagement = () => {
  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: "#fff" }} />
      )}
    </Sticky>
  );
  return (
    <>
      <Card>
        <StickyContainer>
          <Tabs renderTabBar={renderTabBar} defaultActiveKey="1">
            <TabPane tab="New Staff" key="1">
              <NewStaff />
            </TabPane>
            <TabPane tab="Existing Staff" key="2">
              <ExistingStaff />
            </TabPane>
            {/* <TabPane tab="Agency Staff" key="3">
              <AgencyStaff />
            </TabPane> */}
          </Tabs>
        </StickyContainer>
      </Card>
    </>
  );
};

export default UserManagement;
