import React, { useEffect, useState } from "react";
import { Button, Row, Col, Divider, Checkbox, Tooltip, message } from "antd";
import {
  RightSquareOutlined,
  LeftSquareOutlined,
  BankOutlined,
  ClearOutlined,
  SkinOutlined,
  ReadOutlined,
  FieldTimeOutlined
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getApiArrayDetails, isValidArray } from "../../../utils/MyMUtils";
import { getFacilityByModule } from "redux/actions/getFacilityByModule.action";
import { CLEANING_AUDIT, EDUCATION_TOOLBOX, FACILITY_MANAGEMENT, LAUNDRY_AUDIT, PERIODICAL } from "MyMConstants";

function Step3(props) {
  const dispatch = useDispatch();
  const [info, setInfo] = useState([]);

  const getFacilityList = useSelector((state) =>
    getApiArrayDetails(state.getFacilityByModule.data)
  );

  useEffect(() => {
    dispatch(getFacilityByModule(props.passData.role));
  }, []);

  useEffect(() => {
    props.selectFacility(info);
    return () => {};
  }, [info]);

  function onChangeFacility({ target: { value, checked } }) {
    props?.setAble((state) => {
      return checked ? [...state, value] : state.filter((id) => id !== value);
    });

    if (!checked) {
      setInfo((state) => {
        return state.filter(({ facility_id }) => facility_id !== value);
      });
    }
  }

  const onSelectModule = ({ target: { value, id, checked } }) => {
    const setCheckedId = ({ fac_id, mod_id, data, able }) => {
      if (isValidArray(data)) {
        let status = false;
        let len = data.length - 1;

        const newData = data.map((e, i) => {
          const { facility_id, modules } = e;
          if (facility_id == fac_id) {
            modules.push(mod_id);
            return { facility_id, modules };
          }

          if (props?.able.includes(fac_id) && len == i) {
            status = true;
          }

          return e;
        });

        return !status
          ? newData
          : [...newData, { facility_id: fac_id, modules: [mod_id] }];
      }
      data.push({ facility_id: fac_id, modules: [mod_id] });
      return data;
    };

    const unSetCheckedId = ({ fac_id, mod_id, data }) => {
      const newData = data.map((e) => {
        const { facility_id, modules } = e;
        if (facility_id == fac_id) {
          const newModule = modules.filter((id) => id != mod_id);
          return { facility_id, modules: newModule };
        }
        return e;
      });
      return newData;
    };

    setInfo((state) => {
      if (checked) {
        return setCheckedId({
          fac_id: id,
          mod_id: value,
          data: state,
          able: props?.able,
        });
      } else {
        return unSetCheckedId({ fac_id: id, mod_id: value, data: state });
      }
    });
  };

  const next = () => {
    if (props.passData.facility.length > 0) {
      props.next();
    } else {
      message.error("Please Select Facility and its Module!!!");
    }
  };

  const prev = () => {
    props.prev();
  };

  const getmodulesIds = (data) => {
    if (data) {
      return data?.modules?.map((module) => {
        return module;
      });
    }
  };

  return (
    <>
      <div className="steps-content">
        <p></p>
        <Divider orientation="left" style={{ fontSize: "22px" }}>
          Select Facility - Module
        </Divider>
        <p></p>
        <Row gutter={24} style={{ marginLeft: "0px", marginRight: "0px" }}>
          {getFacilityList &&
            getFacilityList.map((facility) => {
              return (
                <>
                  <Col
                    lg={10}
                    md={8}
                    sm={24}
                    xs={24}
                    key={facility.facility_id}
                  >
                    <Row gutter={24}>
                      <Col lg={24} md={24} sm={24} xs={24}>
                        <div className="outer-radio-facility">
                        {isValidArray(props?.passData?.facility) ? (
                            <Checkbox.Group
                              defaultValue={props.passData?.facility?.map((e) => {
                                return e.facility_id;
                              })}
                            >
                              <Checkbox value={facility.facility_id} onChange={onChangeFacility}>
                                {facility.facility_name}
                              </Checkbox>
                            </Checkbox.Group>
                          ) : (
                            <Checkbox.Group>
                              <Checkbox value={facility.facility_id} onChange={onChangeFacility}>
                                {facility.facility_name}
                              </Checkbox>
                            </Checkbox.Group>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={14} md={16} sm={24} xs={24}>
                    <Row gutter={24}>
                      {facility.modules &&
                        facility?.modules.map((module) => {
                          return (
                            <>
                              <Col lg={6} md={6} sm={6} xs={6}>
                                <Tooltip
                                  title={module?.name}
                                  color="rgb(20, 72, 129)"
                                >
                                  <div className="outer-radio-facility">
                                    <Checkbox.Group
                                      defaultValue={isValidArray(props?.passData?.facility) ? getmodulesIds(
                                        ...props.passData?.facility?.filter(
                                          (e) =>
                                            e.facility_id === module.facility_id
                                        )
                                      ) : null}
                                    >
                                      <Checkbox
                                        value={module.module_id}
                                        id={module?.facility_id}
                                        onChange={onSelectModule}
                                        disabled={
                                          !props?.able.includes(
                                            module?.facility_id
                                          )
                                        }
                                      >
                                        {module.name === CLEANING_AUDIT && (
                                          <ClearOutlined
                                            className="ml-3"
                                            style={{
                                              fontSize: "22px",
                                            }}
                                        />
                                        )}
                                        {module.name === EDUCATION_TOOLBOX && (
                                          <ReadOutlined
                                            className="ml-3"
                                            style={{
                                              fontSize: "22px",
                                            }}
                                        />
                                        )}
                                        {module.name === FACILITY_MANAGEMENT && (
                                          <BankOutlined
                                            className="ml-3"
                                            style={{
                                              fontSize: "22px",
                                            }}
                                        />
                                        )}
                                        {module.name === PERIODICAL && (
                                          <FieldTimeOutlined
                                            className="ml-3"
                                            style={{
                                              fontSize: "22px",
                                            }}
                                        />
                                        )}
                                        {module.name === LAUNDRY_AUDIT && (
                                          <SkinOutlined
                                            className="ml-3"
                                            style={{
                                              fontSize: "22px",
                                            }}
                                        />
                                        )}
                                      </Checkbox>
                                    </Checkbox.Group>
                                  </div>
                                </Tooltip>
                              </Col>
                            </>
                          );
                        })}
                    </Row>
                  </Col>
                </>
              );
            })}
        </Row>
      </div>
      <Row gutter={24} className="mt-2">
        <Col lg={12} md={12} sm={24} xs={24}></Col>
        <Col lg={12} md={12} sm={24} xs={24} className="text-right">
          <Button
            shape="round"
            className="mr-3"
            icon={<LeftSquareOutlined />}
            onClick={prev}
          >
            Prev
          </Button>
          <Button shape="round" icon={<RightSquareOutlined />} onClick={next}>
            Next
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default Step3;
