import {
  DELETE_CONTRACTD_HOURS_SUCCESS,
  DELETE_CONTRACTD_HOURS_FAILURE,
  } from "../constants/MyM";
  import { deleteApi } from "../../utils/apiCall";
  
  const deleteContractedHoursSuccess = (data) => {
    return {
      type: DELETE_CONTRACTD_HOURS_SUCCESS,
      payload: data,
    };
  };
  
  const deleteContractedHoursFailure = (data) => {
    return {
      type: DELETE_CONTRACTD_HOURS_FAILURE,
      payload: data,
    };
  };
  
  export const deleteContractedHours = (id) => {
    return async (dispatch, getState) => {
      const { error, result } = await deleteApi(`/delete_facility_contract_hours/${id}`);
      if (!error) {
        return dispatch(deleteContractedHoursSuccess(result));
      }
      return dispatch(deleteContractedHoursFailure(result));
    };
  };
  