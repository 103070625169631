import React, { memo } from "react";
import { Card, Tabs } from "antd";
import { StickyContainer, Sticky } from "react-sticky";
import VaccinationCertificate from "./VaccinationCertificate";
import CovidVaccineCertificate from "./CovidVaccineCertificate";
import StateDeclarationCertificate from "./StateDeclarationCertificate";
import PoliceCheckCertificate from "./PoliceCheckCertificate";
import StaffList from "../reports/StaffList";

const { TabPane } = Tabs;

const Staff = () => {
  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar
          {...props}
          style={{ ...style, zIndex: 1, background: "#fff" }}
        />
      )}
    </Sticky>
  );

  const changeTab = (activeKey) => {};
  return (
    <>
      <Card>
        <StickyContainer>
          <Tabs
            defaultActiveKey="1"
            renderTabBar={renderTabBar}
            onChange={changeTab}
          >
            <TabPane tab="Staff List" key="1">
              <StaffList />
            </TabPane>
            <TabPane tab="Flu Vaccination Certificate" key="2">
              <VaccinationCertificate />
            </TabPane>
            <TabPane tab="Covid Vaccine Certificate" key="3">
              <CovidVaccineCertificate />
            </TabPane>
            <TabPane tab="Stat Declaration Certificate" key="4">
              <StateDeclarationCertificate />
            </TabPane>
            <TabPane tab="Police Check Certificate" key="5">
              <PoliceCheckCertificate />
            </TabPane>
          </Tabs>
        </StickyContainer>
      </Card>
    </>
  );
};

export default memo(Staff);
