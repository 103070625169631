import {
  POST_UPLOAD_STATEMENT_SUCCESS,
  POST_UPLOAD_STATEMENT_FAILURE,
  POST_UPLOAD_STATEMENT_REQUEST,
} from "../constants/MyM";
import { postApi } from "../../utils/apiCall";
import { apiToastError,apiToastSuccess } from '../../utils/MyMUtils';

const uploadStatementSuccess = (data) => {
  return {
    type: POST_UPLOAD_STATEMENT_SUCCESS,
    payload: data,
  };
};

const uploadStatementFailure = (data) => {
  return {
    type: POST_UPLOAD_STATEMENT_FAILURE,
    payload: data,
  };
};
export const uploadStatementRequest = () => ({
	type: POST_UPLOAD_STATEMENT_REQUEST,
});
// export const uploadStatement = (uploadInfo) => {
//   return async (dispatch, getState) => {
//     const { error, result } = await postApi(`/upload_statement`, {
//      // facility_id: "180",
//       ...uploadInfo,
//     });
//     if (!error) {
//       return dispatch(uploadStatementSuccess(result));
//     }
//     return dispatch(uploadStatementFailure(result));
//   };
// };

export const uploadStatement = (uploadInfo) => (dispatch, getState) => {
	dispatch(uploadStatementRequest());
	return postApi(`/upload_statement`, {
    // facility_id: "180",
     ...uploadInfo,
   })
		.then((data) => {
      apiToastSuccess(data)
      return dispatch(uploadStatementSuccess(data));
		})
		.catch((error) => {
			apiToastError(error);
			return dispatch(uploadStatementFailure(error));
		});
};
