import { UPDATE_GROUP_SUCCESS, UPDATE_GROUP_FAILURE } from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const updateGroupSuccess = (data) => {
  return {
    type: UPDATE_GROUP_SUCCESS,
    payload: data,
  };
};

const updateGroupFailure = (data) => {
  return {
    type: UPDATE_GROUP_FAILURE,
    payload: data,
  };
};

export const updateGroup = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await postApi(`/add_organization_data`, {
      ...data,
    });
    if (!error) {
      return dispatch(updateGroupSuccess(result));
    }
    return dispatch(updateGroupFailure(result));
  };
};
