import React, { useEffect, useState, Fragment } from "react";
import {
  Table,
  Card,
  Menu,
  Form,
  Button,
  Select,
  Row,
  Col,
  Upload,
  Tabs,
  message,
  Tooltip,
  Modal,
  Input,
  InputNumber,
  DatePicker,
  Popover,
  Spin,
} from "antd";
import { EyeOutlined, MailOutlined, UploadOutlined } from "@ant-design/icons";
import { getApiArrayDetails, getPreSignedUrl, isValidArray } from "../../../utils/MyMUtils";
import { useDispatch, useSelector } from "react-redux";
import { getUserList } from "redux/actions/getUserList.action";
import { getCovidVaccine } from "redux/actions/covidVaccine.action";
import { uploadCertificate } from "redux/actions/uploadCertificate.action";
import { uploadCovidVaccineCertificate } from "redux/actions/covidVaccineCertificate.action";
import { toast } from "react-toastify";
import moment from "moment";
import "./index.css";
import Email from "./../mail";

const { Option } = Select;
const dateFormat = "DD/MM/YYYY";

function CovidVaccineCertificate() {
  const [userId, setUserId] = useState("");
  const [refNumber, setRefNumber] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [uploadInfo, setUploadInfo] = useState({});
  const [policeCheckData, setPoliceCheckData] = useState([]);
  const [policeInfo, setPoliceInfo] = useState([]);
  const [visible, setVisible] = useState(false);
  const [staffIdsList, setStaffIdsList] = useState("");
  const [staffIdForUpload, setStaffIdForUpload] = useState("");
  const [visibleMail, setVisibleMail] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const getCovidVaccineList = useSelector((state) =>
    getApiArrayDetails(state.getCovidVaccine.data)
  );
  const getUserListData = useSelector((state) =>
    getApiArrayDetails(state.getUserList.data)
  );

  const fileLoadingState = useSelector((state) => state.fileLoadingReducer.fileLoadingState);

  useEffect(() => {
    dispatch(getUserList());
    //  dispatch(getPoliceCheckData());
    return () => {};
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  const getStaffIds = () => {
    if (isValidArray(getUserListData)) {
      let addIds = "";
      getUserListData.map((staff) => {
        addIds += staff.user_id + ",";
      });
      addIds = addIds.substring(0, addIds.length - 1);
      return addIds;
    }
    return null;
  };
  let StaffIds = getStaffIds();
  useEffect(() => {
    setStaffIdsList(StaffIds);
    dispatch(getCovidVaccine(StaffIds));
  }, [StaffIds]);

  const getcovidVaccineList = () => {
    if (isValidArray(getUserListData)) {
      return getUserListData.map((staff) => {
        return (
          <Fragment key={staff?.user_id}>
            <Option value={staff?.user_id}>
              {staff?.first_name} {staff?.last_name}
            </Option>
          </Fragment>
        );
      });
    }
    return null;
  };
  const setUploadModel = (elm) => {
    setVisible(true);
    setUserId(elm);
  };

  const uploadSettings = {
    accept: ".pdf",
    beforeUpload: (file) => {
      if (file.type !== "application/pdf") {
        message.error(`${file.name} is not a pdf file`);
      }
      //   uploadStatement(file.type !== "application/pdf" ? false : true);
      return false;
    },
    onChange: (info) => {
      let selectedFile = info.fileList;
      let file = null;
      let fileName;
      if (selectedFile.length > 0) {
        let fileToLoad = selectedFile[selectedFile.length - 1].originFileObj;
        fileName = fileToLoad.name;
        let reader = new FileReader();
        reader.onload = function (fileEvent) {
          file = fileEvent.target.result;

          let name = fileName.split(".");
          const uploadInfo = {
            certificate_type: "covid",
            filename: name[0],
            file: file,
          };
          setUploadInfo(uploadInfo);
        };
        reader.readAsDataURL(fileToLoad);
      }
    },
  };
  const postNewUploadFunction = (data) => {
    dispatch(uploadCertificate(uploadInfo)).then((record) => {
      const uploadFinal = {
        user_id: userId.user_id,
        staff_name: userId.staff_name,
        vaccination_type: "covid",
        vaccination_id: "4",
        comment: data?.comment,
        vaccination_date: moment(data.vaccination_date).format("YYYY-MM-DD"),
        pdf: record.payload.result.result,
      };
      dispatch(uploadCovidVaccineCertificate(uploadFinal)).then((record) => {
        if (record.payload.result.success === true) {
          if (staffIdForUpload) {
            dispatch(getCovidVaccine(staffIdForUpload || userId));
          } else {
            dispatch(getCovidVaccine(StaffIds));
          }
          toast.success(record.payload.result.message);
        } else {
          toast.error(record.payload.result.message);
        }
      });
    });
    setVisible(false);
  };

  const handleOnChange = (event) => {
    if (event[event.length - 1] == "0") {
      setStaffIdForUpload(staffIdsList);
      dispatch(getCovidVaccine(staffIdsList));
    }
    if (!isValidArray(event)) {
      setStaffIdForUpload(staffIdsList);
      dispatch(getCovidVaccine(staffIdsList));
    } else {
      setStaffIdForUpload(event);
      dispatch(getCovidVaccine(event));
    }
  };

  const closePopover = () => {
    setVisibleMail(false);
  };

  const setMail = (elm) => {
    setVisibleMail(elm.id);
    if (visibleMail === elm.id) {
      setVisibleMail(false);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "staff_name",
      key: "staff_name",
      width: "50%",
      sorter: (a, b) =>
        a.staff_name.toUpperCase().charCodeAt(0) -
        b.staff_name.toUpperCase().charCodeAt(0),
      filters: getCovidVaccineList
        ?.map((f) => {
          return { text: f.staff_name, value: f.staff_name };
        })
        .sort(
          (a, b) =>
            a.text.toUpperCase().charCodeAt(0) -
            b.text.toUpperCase().charCodeAt(0)
        ),
      onFilter: (value, record) => record.staff_name.includes(value),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "50%",
      render: (_, elm) => (
        <div>
          <span className="mr-3">
            {getCovidVaccineList
              .filter((item) => elm.user_id == item.user_id)
              .map((item) => {
                return item?.evidence_photo ? (
                    <Tooltip title="View" color="rgb(20, 72, 129)">
                    <Button onClick={() => getPreSignedUrl(item.evidence_photo)}  >
                        { fileLoadingState !== item.evidence_photo ? <EyeOutlined /> : <Spin size="small" />}
                      </Button>
                    </Tooltip>
                ) : (
                  <a>
                    <Tooltip title="View" color="rgb(20, 72, 129)">
                      <Button disabled>
                        <EyeOutlined style={{ color: "#00000040" }} />
                      </Button>
                    </Tooltip>
                  </a>
                );
              })}
          </span>
          <span className="mr-3">
            {getCovidVaccineList
              .filter((item) => elm.user_id == item.user_id)
              .map((item) => {
                return item?.evidence_photo ? (
                  <Popover
                    title="Additional Emails"
                    content={
                      <Email passData={elm} visibleMailprop={closePopover} />
                    }
                    visible={elm.id === visibleMail}
                    trigger="click"
                  >
                    <Tooltip title="Mail" color="rgb(20, 72, 129)">
                      <Button onClick={() => setMail(elm)}>
                        <MailOutlined />
                      </Button>
                    </Tooltip>
                  </Popover>
                ) : (
                  <Tooltip title="Mail" color="rgb(20, 72, 129)">
                    <Button disabled>
                      <MailOutlined style={{ color: "#00000040" }} />
                    </Button>
                  </Tooltip>
                );
              })}
          </span>
          <span>
            <Tooltip title="Upload" color="rgb(20, 72, 129)">
              <Button onClick={() => setUploadModel(elm)}>
                <UploadOutlined />
              </Button>
            </Tooltip>
          </span>
        </div>
      ),
    },
  ];
  function onChange(value, dateString) {}

  function onOk(value) {}
  // const props = {
  //   name: 'file',
  //   action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  //   headers: {
  //     authorization: 'authorization-text',
  //   },
  //   onChange(info) {
  //     if (info.file.status !== 'uploading') {
  //     }
  //     if (info.file.status === 'done') {
  //       message.success(`${info.file.name} file uploaded successfully`);
  //     } else if (info.file.status === 'error') {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  // };

  return (
    <>
      <Form layout="horizontal">
        <Row>
          <Col span={24}>
            <Form.Item>
              <div style={{ float: "right", width: "30%" }}>
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select Staff"
                  onChange={handleOnChange}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="0">All</Option>
                  {getcovidVaccineList()}
                </Select>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Table
          loading={!isValidArray(getCovidVaccineList)}
          columns={columns}
          dataSource={getCovidVaccineList}
          rowKey="id"
        />
      </Form>
      <Modal
        title="Upload"
        centered
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        width={500}
      >
        <Form onFinish={postNewUploadFunction} layout="vertical" form={form}>
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item
                name="vaccination_date"
                label="Vaccination Date:"
                initialValue={moment(moment())}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Issue Date",
                  },
                ]}
              >
                <DatePicker
                  // defaultValue={moment(moment(), dateFormat)}
                  format={dateFormat}
                  onChange={onChange}
                  onOk={onOk}
                  // disabledDate={(current) => {
                  //   return moment().add(-1, "days") >= current;
                  // }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item
                name="file"
                label="File:"
                rules={[
                  {
                    required: true,
                    message: "Please upload File",
                  },
                ]}
              >
                <Upload {...uploadSettings} maxCount={1}>
                  {/* <Button onClick={() => policeCheckUpload(elm)}> */}

                  <Button onClick={() => setVisible(true)}>
                    <UploadOutlined />
                    Click to Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Form.Item name="comment" label="Comment:">
                <Input placeholder="Enter Comment" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button htmlType="submit">Submit</Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
export default CovidVaccineCertificate;
