import React from "react";
import { Card, Select, Tabs } from "antd";
import { StickyContainer, Sticky } from "react-sticky";
import MeetingList from "./meeting";

const { Option } = Select;
const { TabPane } = Tabs;

export const MeetingMinutes = () => {
  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar
          {...props}
          style={{ ...style, zIndex: 1, background: "#fff" }}
        />
      )}
    </Sticky>
  );
  return (
    <Card>
      <StickyContainer>
        <Tabs renderTabBar={renderTabBar}>
          <TabPane tab="Meetings" key="1">
            <MeetingList />
          </TabPane>
         
        </Tabs>
      </StickyContainer>
    </Card>
  );
};
export default MeetingMinutes;
