import {
  GET_USER_MANAGEMENT_MODULE_FAILURE,
  GET_USER_MANAGEMENT_MODULE_SUCCESS,
} from "../constants/MyM";

const initStat = { data: [], error: false, errorData: "", isLoading: true };

export default (state = initStat, action) => {
  switch (action.type) {
    case GET_USER_MANAGEMENT_MODULE_FAILURE:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case GET_USER_MANAGEMENT_MODULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorData: action.payload,
        error: true,
      };
    default:
      return { ...state };
  }
};
