import {
  GET_POLICE_CHECK_SUCCESS,
  GET_POLICE_CHECK_FAILURE,
} from "../constants/MyM";

const initStat = { data: [], error: false, errorData: "", isLoading: true };

export default (state = initStat, action) => {
  switch (action.type) {
    case GET_POLICE_CHECK_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case GET_POLICE_CHECK_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorData: action.payload,
        error: true,
      };
    default:
      return { ...state };
  }
};
