import React, { memo, useEffect, useCallback, useState, Fragment } from "react";
import { Card, Select, Tabs } from "antd";
import { StickyContainer, Sticky } from "react-sticky";
import FacilityLoginAccess from "./facilityLoginAccess"
import StaffLoginAccess from "./StaffLoginAccess"
const { TabPane } = Tabs;

export const CommunicationManagement = () => {
  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar
          {...props}
          style={{ ...style, zIndex: 1, background: "#fff" }}
        />
      )}
    </Sticky>
  );
  return (
    <Card>
      <StickyContainer>
        <Tabs renderTabBar={renderTabBar}>
          <TabPane tab="Facility Access" key="1">
            <FacilityLoginAccess />
          </TabPane>
          <TabPane tab="Staff Access" key="2">
            <StaffLoginAccess />
          </TabPane>
        </Tabs>
      </StickyContainer>
    </Card>
  );
};
export default CommunicationManagement;
