import {
    GET_WORK_COVER_STATE_LIST_SUCCESS,
    GET_WORK_COVER_STATE_LIST_FAILURE,
  } from "../constants/MyM";
  import { getApi } from "../../utils/apiCall";
  
  const workCoverStateListSuccess = (data) => {
    return {
      type: GET_WORK_COVER_STATE_LIST_SUCCESS,
      payload: data,
    };
  };
  
  const workCoverStateListFailure = (data) => {
    return {
      type: GET_WORK_COVER_STATE_LIST_FAILURE,
      payload: data,
    };
  };
  
  export const getWorkCoverStateList = () => {
    return async (dispatch, getState) => {
      const { error, result } = await getApi(`/state_list_for_certificate`);
      if (!error) {
        return dispatch(workCoverStateListSuccess(result));
      }
      return dispatch(workCoverStateListFailure(result));
    };
  };
  