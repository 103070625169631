import { POST_HOLIDAY_SUCCESS, POST_HOLIDAY_FAILURE } from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const postNewHolidaySuccess = (data) => {
  return {
    type: POST_HOLIDAY_SUCCESS,
    payload: data,
  };
};

const postNewHolidayFailure = (data) => {
  return {
    type: POST_HOLIDAY_FAILURE,
    payload: data,
  };
};

export const postNewHoliday = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await postApi(`/holiday_master`, {
      ...data,
    });
    if (!error) {
      return dispatch(postNewHolidaySuccess(result));
    }
    return dispatch(postNewHolidayFailure(result));
  };
};
