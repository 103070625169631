import { GET_STAFF_LIST_SUCCESS, GET_STAFF_LIST_FAILURE } from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const getStaffListSuccess = (data) => {
  return {
    type: GET_STAFF_LIST_SUCCESS,
    payload: data,
  };
};

const getStaffListFailure = (data) => {
  return {
    type: GET_STAFF_LIST_FAILURE,
    payload: data,
  };
};

export const getStaffListFacility = (data) => {
    return async (dispatch, getState) => {
      const { error, result } = await getApi(`/staff_details?facility_id=${data}`);
      if (!error) {
        return dispatch(getStaffListSuccess(result));
      }
      return dispatch(getStaffListFailure(result));
    };
};
