import { DELETE_COMMUNICATION_LOGIN_SUCCESS, DELETE_COMMUNICATION_LOGIN_FAILURE } from "redux/constants/MyM"
import { deleteApi } from "utils/apiCall";

const deleteCommunicationLoginSuccess = (data) => {
    return {
        type: DELETE_COMMUNICATION_LOGIN_SUCCESS,
        payload: data
    }
}

const deleteCommunicationLoginFailure = (data) => {
    return {
        type: DELETE_COMMUNICATION_LOGIN_FAILURE,
        payload: data
    }
}

export const deleteCommunicationLogin = (id) => {
    return async (dispatch, getState) => {
        const { error, result } = await deleteApi(`/communication_login_details/${id}`);
        if (!error) {
            return dispatch(deleteCommunicationLoginSuccess(result))
        }
        return dispatch(deleteCommunicationLoginFailure(result))
    }
}
