import { GET_ADHOC_FACILITY_SUCCESS, GET_ADHOC_FACILITY_FAILURE } from "redux/constants/MyM"

const initState = { data: [], error: false, errorData: "", isLoading: true }

export default (state = initState, action) => {
    switch (action.type) {
        case GET_ADHOC_FACILITY_SUCCESS:
            return {
                ...state, data: action.payload, isLoading: false
            }
        case GET_ADHOC_FACILITY_FAILURE:
            return {
                ...state, isLoading: false, error: true, errorData: action.payload
            }
        default:
            return {
                ...state
            }
    }
}