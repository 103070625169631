import {
  GET_POLICE_CHECK_SUCCESS,
  GET_POLICE_CHECK_FAILURE,
} from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const policeCheckSuccess = (data) => {
  return {
    type: GET_POLICE_CHECK_SUCCESS,
    payload: data,
  };
};

const policeCheckFailure = (data) => {
  return {
    type: GET_POLICE_CHECK_SUCCESS,
    payload: data,
  };
};

export const getPoliceCheckData = (ids) => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/all_policecheck_certificate?user_id=${ids}`);
    if (!error) {
      return dispatch(policeCheckSuccess(result));
    }
    return dispatch(policeCheckFailure(result));
  };
};
