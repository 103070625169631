import {
  GET_FACILITY_BY_MODULE_SUCCESS,
  GET_FACILITY_BY_MODULE_FAILURE,
} from "../constants/MyM";
import { getApi } from "utils/apiCall";

const getFacilityByModuleSuccess = (data) => {
  return {
    type: GET_FACILITY_BY_MODULE_SUCCESS,
    payload: data,
  };
};

const getFacilityByModuleFailure = (data) => {
  return {
    type: GET_FACILITY_BY_MODULE_FAILURE,
    payload: data,
  };
};

export const getFacilityByModule = (id) => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/get_facility_by_role/${id}`);
    if (!error) {
      return dispatch(getFacilityByModuleSuccess(result));
    }
    return dispatch(getFacilityByModuleFailure(result));
  };
};
