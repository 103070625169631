import {
  GET_MANAGER_MANAGEMENT_SUCCESS,
  GET_MANAGER_MANAGEMENT_FAILURE,
} from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const managementSuccess = (data) => {
  return {
    type: GET_MANAGER_MANAGEMENT_SUCCESS,
    payload: data,
  };
};

const managementFailure = (data) => {
  return {
    type: GET_MANAGER_MANAGEMENT_FAILURE,
    payload: data,
  };
};

export const getManagementData = () => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/manager_contact_details`);
    if (!error) {
      return dispatch(managementSuccess(result));
    }
    return dispatch(managementFailure(result));
  };
};
