import {
  GET_VACCINATION_CERTIFICATE_SUCCESS,
  GET_VACCINATION_CERTIFICATE_FAILURE,
} from "../constants/MyM";

import { getApi } from "../../utils/apiCall";

const vaccinationCertificateSuccess = (data) => {
  return {
    type: GET_VACCINATION_CERTIFICATE_SUCCESS,
    payload: data,
  };
};

const vaccinationCertificateFailure = (data) => {
  return {
    type: GET_VACCINATION_CERTIFICATE_FAILURE,
    payload: data,
  };
};

export const getVaccinationCertificate = (id) => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(
      `/immunisation_data?user_id=${id}&vaccination_type=1`
    );
    if (!error) {
      return dispatch(vaccinationCertificateSuccess(result));
    }
    return dispatch(vaccinationCertificateFailure(result));
  };
};
