import { AUTH_PREFIX_PATH } from "configs/AppConfig";

const errorInterceptor = (axiosInstance) => {
    axiosInstance.interceptors.response.use((response) => {
        return response
    }, (error) => {
        if(error.response.status >= 401){
            localStorage.clear();
            window.location.href = `${AUTH_PREFIX_PATH}/login`;
        }
    })
}

export default errorInterceptor