import { POST_ADHOC_FACILITY_SUCCESS, POST_ADHOC_FACILITY_FAILURE } from "redux/constants/MyM";

const initState = { data: [], error: false, errorData: "", isLoading: true }

export default (state = initState, action) => {
    switch (action.type) {
        case POST_ADHOC_FACILITY_SUCCESS:
            return {
                ...state, data: action.payload, isLoading: false
            }
        case POST_ADHOC_FACILITY_FAILURE:
            return {
                ...state, errorData: action.payload, error: true, isLoading: false
            }
        default:
            return {
                ...state
            }
    }
}