import React, { memo, useEffect, useState, Fragment } from "react";
import {
  Table,
  Form,
  Button,
  Select,
  Tooltip,
  Row,
  Col,
  Popover,
  Spin,
} from "antd";
import { getApiArrayDetails, getPreSignedUrl, isValidArray } from "../../../utils/MyMUtils";
import { useDispatch, useSelector } from "react-redux";
import { EyeOutlined, MailOutlined, DownloadOutlined } from "@ant-design/icons";
import { getGroup } from "../../../redux/actions/group.action";
import { getFacility } from "../../../redux/actions/facility.action";
import { toast } from "react-toastify";
import { getEquipmentReportData } from "../../../redux/actions/getEquipmentreport.action";
import Email from "./../mail";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import equipmentPDF from "./equipmentPdfReport";

const { Option } = Select;

const EquipmentReport = ({ csvData }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const [facilityId, setFacilityId] = useState("");
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [fileName, setFileName] = useState("Monthly Equipment Report");
  const [myLoader, setMyLoader] = useState(false);
  const [visibleMail, setVisibleMail] = useState(false);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const groupList = useSelector((state) =>
    getApiArrayDetails(state.GroupList.data)
  );
  const fileLoadingState = useSelector((state) => state.fileLoadingReducer.fileLoadingState);


  const facilitiesList = useSelector((state) =>
    getApiArrayDetails(state.FacilitiesList.data)
  );
  const equipmentReport = useSelector((state) =>
    getApiArrayDetails(state.getEquipmentReport.data)
  );

  useEffect(() => {
    dispatch(getGroup());
    dispatch(getEquipmentReportData()).then((record) => {
      if (record.payload.success === true) {
        toast.success(record.payload.message);
        setMyLoader(false);
      } else {
        toast.error(record.payload.message);
        setMyLoader(false);
      }
    });
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [facilitiesList]);

  useEffect(() => {
    setResult(equipmentReport);
  }, [equipmentReport]);

  useEffect(() => {
    const data = {
      facility_id: facilityId,
    };
    dispatch(getEquipmentReportData(data));
    setLoading(false);
  }, []);

  const exportToCSV = (csvData, fileName) => {
    if (csvData) {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      toast.error("No Data in Table!");
    }
  };

  const excelData = () => {
    if (isValidArray(result)) {
      return result?.map((item) => {
        return {
          "Facility Name":item.facility_name,
          "User Name": item.user_name,
          "Equipment": item.equipment,
          "Serial no.": item.serial_no,
          Date: item.test_tag_date,
          "Pdf Link": item.pdf_link,
        };
      });
    }
  };

  const getGroupList = () => {
    if (isValidArray(groupList)) {
      return groupList.map((group) => {
        return (
          <Fragment key={group?.org_id}>
            <Option value={group?.org_id}>{group?.org_name}</Option>
          </Fragment>
        );
      });
    }
  };

  const getFacilityList = () => {
    if (isValidArray(facilitiesList)) {
      return facilitiesList.map((facility) => {
        return (
          <Fragment key={facility?.facility_id}>
            <Option value={facility?.facility_id}>
              {facility?.facility_name}
            </Option>
          </Fragment>
        );
      });
    }
  };

  const handleOnChange = (event) => {
    setLoading(true);
    dispatch(getFacility(event));
  };

  const handleFacilityID = (event) => {
    setLoading(true);
    setFacilityId(event);
    const data = {
      facility_id: event,
    };
    setMyLoader(true);
    dispatch(getEquipmentReportData(data)).then((record) => {
      if (record.payload.success === true) {
        toast.success(record.payload.message);
        setMyLoader(false);
      } else {
        toast.error(record.payload.message);
        setMyLoader(false);
      }
    });
  };

  const closePopover = () => {
    setVisibleMail(false);
  };
  const setMail = (elm) => {
    setVisibleMail(elm.id);
    if (visibleMail === elm.id) {
      setVisibleMail(false);
    }
  };

  const columns = [
    {
      title: "Facility Name",
      dataIndex: "facility_name",
      key: "facility_name",
      width: "15%",
    },
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "user_name",
      width: "15%",
    },
    {
      title: "Equipment",
      dataIndex: "equipment",
      key: "equipment",
      width: "20%",
    },
    {
      title: "Serial no.",
      dataIndex: "serial_no",
      key: "serial_no",
      width: "15%",
    },
    {
      title: " Test & Tag Date",
      dataIndex: "test_tag_date",
      key: "test_tag_date",
      width: "20%",
      render: (elm) => {
        return moment(elm, "DD-MM-YYYY").format("DD MMM YY");
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "15%",
      render: (_, elm, row) => (
        <div>
          <span className="mr-3">
            {result
              .filter((data) => elm.id == data.id)
              .map((item) => {
                return item?.pdf_link ? (
                  // <a target="_blank" href={item?.pdf_link}>
                    <Tooltip title="View" color="rgb(20, 72, 129)">
                       <Button onClick={() => getPreSignedUrl(item.pdf_link)}  >
                    <EyeOutlined />
                  </Button>
                    </Tooltip>
                  // </a>
                ) : (
                  <a target="_blank" href="">
                    <Tooltip title="View" color="rgb(20, 72, 129)">
                      <Button disabled>
                        <EyeOutlined style={{ color: "#00000040" }} />
                      </Button>
                    </Tooltip>
                  </a>
                );
              })}
          </span>
          <span className="mr-3">
            {result
              .filter((data) => elm.id == data.id)
              .map((item) => {
                return item?.pdf_link ? (
                  <Popover
                    title="Additional Emails"
                    content={
                      <Email passData={elm} visibleMailprop={closePopover} />
                    }
                    visible={elm.id === visibleMail}
                    trigger="click"
                  >
                    <Tooltip title="Mail" color="rgb(20, 72, 129)">
                      <Button onClick={() => setMail(elm)}>
                        <MailOutlined />
                      </Button>
                    </Tooltip>
                  </Popover>
                ) : (
                  <Tooltip title="Mail" color="rgb(20, 72, 129)">
                    <Button disabled>
                      <MailOutlined style={{ color: "#00000040" }} />
                    </Button>
                  </Tooltip>
                );
              })}
          </span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setMyLoader(fileLoadingState === '' ? false :true )
  }, [fileLoadingState])
  

  return (
    <>
      <Form layout="horizontal" form={form}>
        {/* <Row gutter={24}>
          <Col span={4}></Col>
          <Col span={5}></Col>
          <Col span={5}>
            <Form.Item>
              <div>
                <Select
                  onChange={handleOnChange}
                  placeholder="Select Group"
                  allowClear
                >
                  {getGroupList()}
                </Select>
              </div>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="demo2">
              <div>
                <Select
                  mode="multiple"
                  onChange={handleFacilityID}
                  placeholder="Select Facility"
                >
                  {getFacilityList()}
                </Select>
              </div>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Button
              className="ml-2"
              style={{ float: "right" }}
              onClick={() => equipmentPDF(result, columns)}
              icon={<DownloadOutlined />}
            >
              PDF
            </Button>
            <Button
              style={{ float: "right" }}
              onClick={(e) => exportToCSV(excelData(), fileName)}
              icon={<DownloadOutlined />}
            >
              Excel
            </Button>
          </Col>
        </Row> */}
      </Form>
      <Form>
        <Table
          loading={myLoader}
          className="mt-1"
          dataSource={result}
          columns={columns}
          rowKey="id"
        />
      </Form>
    </>
  );
};

export default memo(EquipmentReport);
