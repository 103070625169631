import { GET_CONTRACTED_HOURS_SUCCESS, GET_CONTRACTED_HOURS_FAILURE } from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const getContractedHoursSuccess = (data) => {
  return {
    type: GET_CONTRACTED_HOURS_SUCCESS,
    payload: data,
  };
};

const getContractedHoursFailure = (data) => {
  return {
    type: GET_CONTRACTED_HOURS_FAILURE,
    payload: data,
  };
};

export const getContractedHoursInfo = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await postApi(`/contract_hours_by_facility_id`, {
      ...data,
    });
    if (!error) {
      return dispatch(getContractedHoursSuccess(result));
    }
    return dispatch(getContractedHoursFailure(result));
  };
};
