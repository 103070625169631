import { POST_COMMUNICATION_LOGIN_SUCCESS, POST_COMMUNICATION_LOGIN_FAILURE } from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const postCommunicationLoginSuccess = (data) => {
  return {
    type: POST_COMMUNICATION_LOGIN_SUCCESS,
    payload: data,
  };
};

const postCommunicationLoginFailure = (data) => {
  return {
    type: POST_COMMUNICATION_LOGIN_FAILURE,
    payload: data,
  };
};

export const AddCommunicationLogin = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await postApi(`/setup_communication_login`, {
      ...data,
    });
    if (!error) {
      return dispatch(postCommunicationLoginSuccess(result));
    }
    return dispatch(postCommunicationLoginFailure(result));
  };
};
