import React from "react";
import { Card, Select, Tabs } from "antd";
import { StickyContainer, Sticky } from "react-sticky";
import PoliceStatement from "./police";
import StatmentVaccination from "./vaccination";
import Covid from "./covid";

const { Option } = Select;
const { TabPane } = Tabs;

const Statment = () => {
  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar
          {...props}
          style={{ ...style, zIndex: 1, background: "#fff" }}
        />
      )}
    </Sticky>
  );

  const changeTab = (activeKey) => {};

  return (
    <Card>
      <StickyContainer>
        <Tabs
          renderTabBar={renderTabBar}
          defaultActiveKey="1"
          onChange={changeTab}
        >
          <TabPane tab="Flu Vaccination Statement" key="1">
            <StatmentVaccination />
          </TabPane>
          <TabPane tab="Covid Vaccination Statement" key="2">
            <Covid />
          </TabPane>
          <TabPane tab="Police Check Obligation Statement" key="3">
            <PoliceStatement />
          </TabPane>
        </Tabs>
      </StickyContainer>
    </Card>
  );
};

export default Statment;
