import { GET_COVID_VACCINE_SUCCESS, GET_COVID_VACCINE_FAILURE } from "../constants/MyM";

import { getApi } from "../../utils/apiCall";

const covidVaccineSuccess = (data) => {
  return {
    type: GET_COVID_VACCINE_SUCCESS,
    payload: data,
  };
};

const covidVaccineFailure = (data) => {
  return {
    type: GET_COVID_VACCINE_FAILURE,
    payload: data,
  };
};

export const getCovidVaccine = (id) => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/immunisation_data?user_id=${id}&vaccination_type=4`);
    if (!error) {
      return dispatch(covidVaccineSuccess(result));
    }
    return dispatch(covidVaccineFailure(result));
  };
};
