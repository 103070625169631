import React, { memo, useEffect, useState, useRef } from "react";
import {
  Table,
  Form,
  Button,
  Select,
  Row,
  Col,
  Divider,
  Tooltip,
  Modal,
  Input,
  Popconfirm,
  Checkbox,
  Space,
  Tag,
  DatePicker,
  List,
} from "antd";
import { getApiArrayDetails, isValidArray } from "../../../utils/MyMUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  PlusCircleOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { getContactFacility } from "redux/actions/contactFacility.action";
import moment from "moment";
import { getStateList } from "redux/actions/stateList.action";
import { getGroup } from "redux/actions/group.action";
import { getManagerList } from "redux/actions/getManager.action";
import { postNewFacility } from "redux/actions/postNewFacility.action";
import { updateFacility } from "redux/actions/updateFacility.action";
import { deleteFacility } from "redux/actions/deleteFacility.action";
import { deleteUser } from "redux/actions/deleteUser.action";
import { getExistEmailFacilityManagement } from "redux/actions/getEmailExist.action";
import { getExistEmailAtEditFacilityManagement } from "redux/actions/getEmailExistAtEdit.action";
import { toast } from "react-toastify";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import generatePdfFacility from "./generatePdfFacility";
import { getFacilityUserData } from "redux/actions/getFacilityUserById.action";

const { Option } = Select;
const formatDate = ["DD/MM/YYYY"];

const FacilityContact = ({ csvData }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  let searchInput = useRef(null);

  const [visible, setVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleUserList, setVisibleUserList] = useState(false);
  const [deletePopupVisible, setDeletePopupVisible] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState([]);
  const [showEditDatePicker, setShowEditDatePicker] = useState([]);
  const [defaultValue, setDefaultValue] = useState("");
  const [serviceID, setServiceID] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [facilityID, setFacilityID] = useState("");

  const [fileName, setFileName] = useState("Facility Details");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const dispatch = useDispatch();

  const ContactFacilityList = useSelector((state) =>
    getApiArrayDetails(state.contactFacility.data)
  );

  const stateList = useSelector((state) =>
    getApiArrayDetails(state.stateList.data)
  );

  const organizationList = useSelector((state) =>
    getApiArrayDetails(state.GroupList.data)
  );

  const managerList = useSelector((state) =>
    getApiArrayDetails(state.getManagerList.data)
  );

  const facilityUserList = useSelector((state) =>
    getApiArrayDetails(state.getFacilityUserList.data)
  );

  useEffect(() => {
    dispatch(getContactFacility());
  }, []);

  useEffect(() => {
    dispatch(getStateList());
    dispatch(getGroup());
    dispatch(getManagerList());
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [isModalVisible]);

  useEffect(() => {
    form2.resetFields();
  }, [visibleModal]);

  useEffect(() => {
    setServiceId("");
    form3.resetFields();
  }, [visible]);

  const plainOptions = [
    { label: "Cleaning", value: "Cleaning" },
    { label: "Laundry", value: "Laundry" },
  ];

  const defaultOptions = [
    { label: "Cleaning", value: "Cleaning" },
    { label: "Laundry", value: "Laundry" },
  ];

  const exportToCSV = (csvData, fileName) => {
    if (csvData) {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      toast.error("No Data in Table!");
    }
  };

  const excelData = () => {
    if (isValidArray(ContactFacilityList)) {
      return ContactFacilityList?.map((item) => {
        return {
          Group: item.org_name,
          Facility: item.facility_name,
          State: item.state_name,
          "Facility Address": item.facility_address,
          "Facility Manager Name": item.manager_name,
          "Facility Manager Email": item.manager_email,
          "Cleaning Service StartDate": item.cleaning_service_startdate,
          Service:
            item.service.length === 1
              ? item.service[0].service_name
              : `${item.service[0].service_name} , ${item.service[1].service_name}`,
        };
      });
    }
  };

  const getStateListDetails = () => {
    if (isValidArray(stateList)) {
      return stateList.map((state) => (
        <Option name="state_id" key={state.state_id} value={state.state_id}>
          {state.state_name}
        </Option>
      ));
    }
  };

  const getOrganisationListDetails = () => {
    if (isValidArray(organizationList)) {
      return organizationList.map((organization) => (
        <Option
          name="state_id"
          key={organization.org_id}
          value={organization.org_id}
        >
          {organization.org_name}
        </Option>
      ));
    }
  };

  const getManagerListDetails = () => {
    if (isValidArray(managerList)) {
      return managerList.map((manager) => (
        <Option name="manager_id" key={manager.user_id} value={manager.user_id}>
          {manager.manager_name}
        </Option>
      ));
    }
  };

  const ViewFacilityFunction = (elm) => {
    const arr = [];
    elm.service.map((d) => {
      arr.push(d.service_name);
    });
    setShowEditDatePicker(arr);
    setDefaultValue(() => {
      if (elm.cleaning_service_startdate || elm.laundry_service_startdate) {
        return {
          ...elm,
          cleaning_service_startdate: moment(
            elm.cleaning_service_startdate,
            formatDate
          ),
          laundry_service_startdate: moment(
            elm.laundry_service_startdate,
            formatDate
          ),
        };
      }
      return elm;
    });
    let arry = [];
    elm.service.map((e) => {
      arry.push(e?.service_name);
    });
    const newArry = arry.map(function (e) {
      return e.toString();
    });

    setServiceID(newArry);
    setIsModalVisible(true);
  };

  const EditFacilityFunction = (elm) => {
    const arr = [];
    elm.service.map((d) => {
      arr.push(d.service_name);
    });
    setShowEditDatePicker(arr);
    setDefaultValue(() => {
      if (elm.cleaning_service_startdate || elm.laundry_service_startdate) {
        return {
          ...elm,
          cleaning_service_startdate: moment(
            elm.cleaning_service_startdate || new Date(),
            formatDate
          ),
          laundry_service_startdate: moment(
            elm.laundry_service_startdate || new Date(),
            formatDate
          ),
        };
      }
      return elm;
    });
    let editArry = [];
    elm.service.map((e) => {
      editArry.push(e?.service_name);
    });
    const newEditArry = editArry.map(function (e) {
      return e.toString();
    });
    setServiceId(newEditArry);
    setVisibleModal(true);
  };

  const postNewFacilityFunction = (data) => {
    const newData = {
      ...data,
      cleaning_service_startdate: data?.cleaning_service_startdate
        ? moment(data?.cleaning_service_startdate).format("YYYY/MM/DD")
        : "",
      laundry_service_startdate: data?.laundry_service_startdate
        ? moment(data?.laundry_service_startdate).format("YYYY/MM/DD")
        : "",
    };
    dispatch(postNewFacility(newData)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        dispatch(getContactFacility());
      } else {
        toast.error(record.payload.message);
        dispatch(getContactFacility());
      }
    });
    setVisible(false);
  };

  const updateFacilityFunction = (info) => {
    let arry = [];
    info.user_details.map((item) => {
      arry.push(item);
    });

    const putInfo = {
      ...info,
      fac_login: info?.fac_login ? [...arry, ...info?.fac_login] : [...arry],
      facility_id: defaultValue.facility_id,
      service: isValidArray(showEditDatePicker)
        ? showEditDatePicker
        : serviceId,
      cleaning_service_startdate: info?.cleaning_service_startdate
        ? moment(info?.cleaning_service_startdate).format("YYYY/MM/DD")
        : "",
      laundry_service_startdate: info?.laundry_service_startdate
        ? moment(info?.laundry_service_startdate).format("YYYY/MM/DD")
        : "",
    };
    dispatch(updateFacility(putInfo)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        dispatch(getContactFacility());
      } else {
        toast.error(record.payload.message);
        dispatch(getContactFacility());
      }
    });
    setVisibleModal(false);
  };

  const deleteFacilityFunction = (elm) => {
    setFacilityID(elm.facility_id);
    dispatch(getFacilityUserData(elm.facility_id)).then((record) => {
      if (record.payload.success == true) {
        setVisibleUserList(true);
      } else {
        setDeletePopupVisible(true);
      }
    });
  };

  const deleteFacilityUserFunction = (elm) => {
    dispatch(deleteFacility(facilityID)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        dispatch(getContactFacility());
        setDeletePopupVisible(false);
      } else {
        toast.error(record.payload.message);
        dispatch(getContactFacility());
        setDeletePopupVisible(false);
      }
    });
  };

  const selectService = (e) => {
    setShowDatePicker(e);
  };

  const onSelectChange = (e) => {
    setShowEditDatePicker(e);
  };
  function cancel(e) {}

  const checkEmail = (e) => {
    let email = e?.target?.value;
    dispatch(getExistEmailFacilityManagement(email)).then((record) => {
      if (record.payload.success == true) {
        toast.error(record.payload.message);
      }
    });
  };

  const checkEmailEdit = (e) => {
    let email = e?.target?.value;
    dispatch(getExistEmailAtEditFacilityManagement(email)).then((record) => {
      if (record.payload.success == true) {
        toast.error(record.payload.message);
      }
    });
  };

  function onChange(value, dateString) {}

  function onOk(value) {}

  const deleteUserHandle = (e) => {
    let user_id = defaultValue?.user_details[e]?.user_id;
    dispatch(deleteUser(user_id)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
      }
    });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: "#1a3353" ? "#1a3353" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Group",
      dataIndex: "org_name",
      key: "org_name",
      width: "17%",
      ...getColumnSearchProps("org_name"),
    },
    {
      title: "Facility",
      dataIndex: "facility_name",
      key: "facility_name",
      width: "20%",
      ...getColumnSearchProps("facility_name"),
    },
    {
      title: "Facility Manager Name",
      dataIndex: "manager_name",
      key: "manager_name",
      width: "15%",
      ...getColumnSearchProps("manager_name"),
    },
    {
      title: "State",
      dataIndex: "state_name",
      key: "state_name",
      width: "15%",
      ...getColumnSearchProps("state_name"),
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      width: "8%",
      render: (text) => {
        return (
          <>
            {text &&
              text.map((e) => {
                return (
                  <>
                    <Tag color="rgb(227 221 236)" className="mt-2 d-block">
                      {e?.service_name}
                    </Tag>
                  </>
                );
              })}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "25%",
      render: (_, elm) => (
        <div>
          <span className="mr-3">
            <Tooltip title="View" color="rgb(20, 72, 129)">
              <Button onClick={() => ViewFacilityFunction(elm)}>
                <EyeOutlined />
              </Button>
            </Tooltip>
          </span>
          <span className="mr-3">
            <Tooltip title="Edit" color="rgb(20, 72, 129)">
              <Button onClick={() => EditFacilityFunction(elm)}>
                <EditOutlined />
              </Button>
            </Tooltip>
          </span>
          <span className="mr-3">
            <Tooltip title="Delete" color="rgb(20, 72, 129)">
              <Button onClick={() => deleteFacilityFunction(elm)}>
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </span>
        </div>
      ),
    },
  ];
  return (
    <>
      <Form>
        <Button
          className="style-btn ml-2"
          onClick={() => setVisible(true)}
          style={{ float: "right", marginBottom: "20px" }}
          icon={<PlusCircleOutlined />}
          shape="round"
          type="primary"
        >
          New Facility
        </Button>
        <Button
          shape="round"
          className="ml-2"
          style={{ float: "right" }}
          onClick={() => generatePdfFacility(ContactFacilityList, columns)}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
        <Button
          shape="round"
          style={{ float: "right" }}
          onClick={(e) => exportToCSV(excelData(), fileName)}
          icon={<DownloadOutlined />}
        >
          Excel
        </Button>
        <Table
          columns={columns}
          loading={!isValidArray(ContactFacilityList)}
          dataSource={ContactFacilityList}
          rowKey="id"
        />
      </Form>

      <Modal
        title="New Facility"
        centered
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        width={900}
      >
        <Form
          onFinish={postNewFacilityFunction}
          layout="vertical"
          form={form3}
          className="facility-steps-content"
        >
          <Divider>Assign Facility</Divider>
          <Row gutter={[16, 16]} className="justify-content-center m-0">
            <Col md={12} xs={16}>
              <Form.Item
                name="org_id"
                label="Group:"
                rules={[
                  {
                    required: true,
                    message: "Please Select Organization",
                  },
                ]}
              >
                <Select placeholder="Select Organization">
                  {getOrganisationListDetails()}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={16}>
              <Form.Item
                name="facility_name"
                label="Facility Name:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Facility Name",
                  },
                ]}
              >
                <Input placeholder="Enter Facility Name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="justify-content-center m-0">
            <Col md={12} xs={16}>
              <Form.Item
                name="state_id"
                label="State:"
                rules={[
                  {
                    required: true,
                    message: "Please Select State",
                  },
                ]}
              >
                <Select placeholder="Select State">
                  {getStateListDetails()}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={16}>
              <Form.Item
                name="facility_address"
                label="Facility Address:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Facility Address",
                  },
                ]}
              >
                <Input placeholder="Enter Facility Address" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="justify-content-center m-0">
            <Col md={12} xs={16}>
              <Form.Item
                name="facility_email"
                label="Facility Email:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Facility  Manager Email",
                  },
                  {
                    type: "email",
                    message: "Please Enter valid Email address",
                  },
                ]}
              >
                <Input placeholder="Enter Manager Email" />
              </Form.Item>
            </Col>
            <Col md={12} xs={16}>
              <Form.Item
                name="job_code"
                label="Job Code:"
                rules={[
                  {
                    required: true,
                    message: "Please EnterJob Code",
                  },
                ]}
              >
                <Input placeholder="Enter Job Code" />
              </Form.Item>
            </Col>
          </Row>

          <Divider>Assign Service</Divider>
          <Row gutter={[16, 16]} className="justify-content-center m-0">
            <Col md={12} xs={16}>
              <Form.Item
                name="service"
                label="Service Facility:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Manager",
                  },
                ]}
              >
                <Checkbox.Group
                  options={plainOptions}
                  onChange={selectService}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={16}>
              <Form.Item
                name="manager_id"
                label="Manager:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Manager",
                  },
                ]}
              >
                <Select placeholder="Select Manager" mode="multiple">
                  {getManagerListDetails()}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="m-0">
            {showDatePicker.includes("Cleaning") === true ? (
              <Col md={12} xs={16}>
                <Form.Item
                  name="cleaning_service_startdate"
                  label="Cleaning Service Start Date:"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Date",
                    },
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" />
                </Form.Item>
                <Form.Item
                  name="cleaning_survery_monkey_hash_code"
                  label="Cleaning Survey Monkey Link:"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter survey monkey link",
                    },
                  ]}
                >
                  <Input style={{ width: "100%" }} placeholder="Survey Monkey Link" />
                </Form.Item>
              </Col>
            ) : (
              " "
            )}

            {showDatePicker.includes("Laundry") === true ? (
              <Col md={12} xs={16}>
                <Form.Item
                  name="laundry_service_startdate"
                  label="Laundry Service Start Date:"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Date",
                    },
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" />
                </Form.Item>
                <Form.Item
                  name="laundry_survery_monkey_hash_code"
                  label="Laundry Survey Monkey Link:"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter survey monkey link",
                    },
                  ]}
                >
                  <Input style={{ width: "100%" }} placeholder="Survey Monkey Link" />
                </Form.Item>
              </Col>
            ) : (
              " "
            )}
          </Row>

          <Divider>Access Facility Portal</Divider>

          <Row gutter={[16, 16]} className="d-flex justify-content-center m-0">
            <Col span={24}>
              <Form.List name="fac_login">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Space key={key} align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, "first_name"]}
                          fieldKey={[fieldKey, "first_name"]}
                          rules={[
                            { required: true, message: "Missing First Name" },
                          ]}
                        >
                          <Input placeholder="Enter First Name" />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "last_name"]}
                          fieldKey={[fieldKey, "last_name"]}
                          rules={[
                            { required: true, message: "Missing Last Name" },
                          ]}
                        >
                          <Input placeholder="Enter Last Name" />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          onBlur={checkEmail}
                          name={[name, "email"]}
                          fieldKey={[fieldKey, "email"]}
                          rules={[
                            { required: true, message: "Missing Email" },
                            {
                              type: "email",
                              message: "Please Enter valid Email address",
                            },
                          ]}
                        >
                          <Input placeholder="Enter Email" />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "password"]}
                          fieldKey={[fieldKey, "password"]}
                          rules={[
                            { required: true, message: "Missing Password" },
                          ]}
                        >
                          <Input placeholder="Enter password" />
                        </Form.Item>

                        <Button
                          onClick={() => remove(name)}
                          icon={<DeleteOutlined />}
                        ></Button>
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusCircleOutlined />}
                      >
                        Add User
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>

          <Divider className="m-0" />
          <Row gutter={24} className="justify-content-center mt-3 m-0">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button className="style-btn" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Edit Facility"
        centered
        visible={visibleModal}
        footer={null}
        onCancel={() => setVisibleModal(false)}
        width={900}
      >
        <Form
          onFinish={updateFacilityFunction}
          layout="vertical"
          initialValues={defaultValue}
          form={form2}
          className="facility-steps-content"
        >
          <Divider>Assign Facility</Divider>
          <Row gutter={[16, 16]} className="justify-content-center m-0">
            <Col md={12} xs={16}>
              <Form.Item
                name="org_id"
                label="Group:"
                rules={[
                  {
                    required: true,
                    message: "Please Select Organization",
                  },
                ]}
              >
                <Select placeholder="Select Organization">
                  {getOrganisationListDetails()}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={16}>
              <Form.Item
                name="facility_name"
                label="Facility Name:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Facility Name",
                  },
                ]}
              >
                <Input placeholder="Enter Facility Name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="justify-content-center m-0">
            <Col md={12} xs={16}>
              <Form.Item
                name="state_id"
                label="State:"
                rules={[
                  {
                    required: true,
                    message: "Please Select State",
                  },
                ]}
              >
                <Select placeholder="Select State">
                  {getStateListDetails()}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={16}>
              <Form.Item
                name="facility_address"
                label="Facility Address:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Facility Address",
                  },
                ]}
              >
                <Input placeholder="Enter Facility Address" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="justify-content-center m-0">
            <Col md={12} xs={16}>
              <Form.Item
                name="facility_email"
                label="Facility Email:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Facility  Manager Email",
                  },
                  {
                    type: "email",
                    message: "Please Enter valid Email address",
                  },
                ]}
              >
                <Input placeholder="Enter Manager Email" />
              </Form.Item>
            </Col>
            <Col md={12} xs={16}>
            <Form.Item
                name="job_code"
                label="Job Code:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Job Code",
                  },
                ]}
              >
                <Input placeholder="Enter Job Code" />
              </Form.Item>
            </Col>
          </Row>

          <Divider>Assign Service</Divider>
          <Row gutter={[16, 16]} className="justify-content-center m-0">
            <Col md={12} xs={16}>
              <Form.Item
                // name="services"
                label="Service Facility:"
                // initialValue={serviceId}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Manager",
                  },
                ]}
              >
                <Checkbox.Group
                  options={defaultOptions}
                  defaultValue={serviceId}
                  onChange={onSelectChange}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={16}>
              <Form.Item
                name="manager_id"
                label="Manager:"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Manager",
                //   },
                // ]}
              >
                <Select placeholder="Select Manager" mode="multiple">
                  {getManagerListDetails()}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="m-0">
            {showEditDatePicker?.includes("Cleaning") === true ? (
              <Col md={12} xs={16}>
                <Form.Item
                  name="cleaning_service_startdate"
                  label="Cleaning Service Start Date:"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Date",
                    },
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} format={formatDate} />
                </Form.Item>
                <Form.Item
                  name="cleaning_survery_monkey_hash_code"
                  label="Cleaning Survey Monkey Link:"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter survey monkey link",
                    },
                  ]}
                >
                  <Input style={{ width: "100%" }} placeholder="Survey Monkey Link" />
                </Form.Item>
              </Col>
            ) : (
              ""
            )}

            {showEditDatePicker?.includes("Laundry") === true ? (
              <Col md={12} xs={16}>
                <Form.Item
                  name="laundry_service_startdate"
                  label="Laundry Service Start Date:"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Date",
                    },
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} format={formatDate} />
                </Form.Item>
                <Form.Item
                  name="laundry_survery_monkey_hash_code"
                  label="Laundry Survey Monkey Link:"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter survey monkey link",
                    },
                  ]}
                >
                  <Input style={{ width: "100%" }} placeholder="Survey Monkey Link" />
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
          </Row>

          <Divider>Access Facility Portal</Divider>
          <Row
            gutter={[16, 16]}
            className="d-flex justify-content-center mt-1 m-0"
            // className="mt-1 m-0"
          >
            <Col span={24}>
              <Form.List name="user_details">
                {(fields, { add, remove }) => (
                  <>
                    <Row className="justify-content-center">
                      <Col md={20}>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                          <Space key={key} align="baseline">
                            <Form.Item
                              {...restField}
                              name={[name, "first_name"]}
                              fieldKey={[fieldKey, "first_name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter First Name",
                                },
                              ]}
                            >
                              <Input placeholder="First Name" />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "last_name"]}
                              fieldKey={[fieldKey, "last_name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Last Name",
                                },
                              ]}
                            >
                              <Input placeholder="Last Name" />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "email"]}
                              fieldKey={[fieldKey, "email"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Email",
                                },
                              ]}
                              onBlur={checkEmailEdit}
                            >
                              <Input placeholder="Email" />
                            </Form.Item>
                            <Button
                              onClick={() => remove(name)}
                              icon={
                                <DeleteOutlined
                                  onClick={() => deleteUserHandle(name)}
                                />
                              }
                            ></Button>
                          </Space>
                        ))}
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="d-flex justify-content-center m-0">
            <Col span={24}>
              <Form.List name="fac_login">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Space key={key} align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, "first_name"]}
                          fieldKey={[fieldKey, "first_name"]}
                          rules={[
                            { required: true, message: "Missing First Name" },
                          ]}
                        >
                          <Input placeholder="Enter First Name" />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "last_name"]}
                          fieldKey={[fieldKey, "last_name"]}
                          rules={[
                            { required: true, message: "Missing Last Name" },
                          ]}
                        >
                          <Input placeholder="Enter Last Name" />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "email"]}
                          fieldKey={[fieldKey, "email"]}
                          onBlur={checkEmail}
                          rules={[
                            { required: true, message: "Missing Email" },
                            {
                              type: "email",
                              message: "Please Enter valid Email address",
                            },
                          ]}
                        >
                          <Input placeholder="Enter Email" />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "password"]}
                          fieldKey={[fieldKey, "password"]}
                          rules={[
                            { required: true, message: "Missing Password" },
                          ]}
                        >
                          <Input placeholder="Enter password" />
                        </Form.Item>

                        <Button
                          onClick={() => remove(name)}
                          icon={<DeleteOutlined />}
                        ></Button>
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusCircleOutlined />}
                      >
                        Add User
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>

          <Divider className="m-0" />
          <Row gutter={24} className="justify-content-center mt-3 m-0">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button className="style-btn" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="View Facility"
        centered
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        width={900}
      >
        <Form
          initialValues={defaultValue}
          layout="vertical"
          form={form}
          className="facility-steps-content"
        >
          <Row gutter={[16, 16]} className="justify-content-center m-0">
            <Col md={12} xs={16}>
              <Form.Item name="org_id" label="Group:">
                <Select disabled>{getOrganisationListDetails()}</Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={16}>
              <Form.Item name="facility_name" label="Facility Name:">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="justify-content-center m-0">
            <Col md={12} xs={16}>
              <Form.Item name="facility_address" label="Facility Address:">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={12} xs={16}>
              <Form.Item name="state_id" label="State:">
                <Select disabled>{getStateListDetails()}</Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="justify-content-center m-0">
            <Col md={12} xs={16}>
              <Form.Item name="manager_name" label="Facility Manager Name:">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={12} xs={16}>
              <Form.Item name="manager_email" label="Facility Manager Email:">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="justify-content-center m-0">
            <Col md={12} xs={16}>
              <Form.Item label="Service Facility:">
                <Checkbox.Group
                  options={defaultOptions}
                  value={serviceID}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={16}>
              <Form.Item name="manager_name" label="Manager:">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="m-0">
            {showEditDatePicker?.includes("Cleaning") === true ? (
              <Col md={12} xs={16}>
                <Form.Item
                  label="Cleaning Service StartDate:"
                  name="cleaning_service_startdate"
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    format={formatDate}
                    disabled
                  />
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
            {showEditDatePicker?.includes("Laundry") === true ? (
              <Col md={12} xs={16}>
                <Form.Item
                  label="Laundry Service StartDate:"
                  name="laundry_service_startdate"
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    format={formatDate}
                    disabled
                  />
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
             <Col md={12} xs={16}>
              <Form.Item name="job_code" label="Job code:">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Divider>Access to Facility Portal</Divider>
          <Row
            gutter={[16, 16]}
            className="d-flex justify-content-center mt-1 m-0"
            // className="mt-1 m-0"
          >
            <Col span={24}>
              <Form.List name="user_details">
                {(fields, { add, remove }) => (
                  <>
                    <Row className="justify-content-center">
                      <Col md={20}>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                          <Space
                            key={key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "first_name"]}
                              label={"First Name"}
                              fieldKey={[fieldKey, "first_name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter First Name",
                                },
                              ]}
                            >
                              <Input placeholder="First Name" disabled />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "last_name"]}
                              label={"Last Name"}
                              fieldKey={[fieldKey, "last_name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Last Name",
                                },
                              ]}
                            >
                              <Input placeholder="Last Name" disabled />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "email"]}
                              label={"Email"}
                              fieldKey={[fieldKey, "email"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Email",
                                },
                              ]}
                            >
                              <Input placeholder="Email" disabled />
                            </Form.Item>
                          </Space>
                        ))}
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="User List"
        centered
        visible={visibleUserList}
        onCancel={() => setVisibleUserList(false)}
        onOk={() => {
          setVisibleUserList(false);
        }}
        width={500}
      >
        <h4>
          <b>
            Below users have been assigned to the facility. Please allocate
            these users to other facility before deleting.{" "}
          </b>
        </h4>
        <br />
        <List
          itemLayout="vertical"
          size="large"
          grid={{ gutter: 16, column: 2 }}
          pagination={{
            pageSize: 10,
          }}
          dataSource={facilityUserList}
          renderItem={(user) => (
            <>
              <List.Item key={user.user_id}>
                <div className="outer-radio-user">{user.name}</div>
              </List.Item>
            </>
          )}
        />
      </Modal>

      <Modal
        centered
        visible={deletePopupVisible}
        onCancel={() => setDeletePopupVisible(false)}
        onOk={() => {
          deleteFacilityUserFunction();
        }}
        width={500}
      >
        <h4>
          <b>
            <ExclamationCircleOutlined style={{ color: "#ffc542" }} /> &nbsp;
            Are you sure you want to delete this facility?{" "}
          </b>
        </h4>
        <br />
      </Modal>
    </>
  );
};

export default memo(FacilityContact);
