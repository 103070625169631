import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider, Spin } from "antd";
import {
  APP_PREFIX_PATH,
  AUTH_PREFIX_PATH,
  APP_MENU_PATH,
} from "configs/AppConfig";
import { isValidToken } from "../utils/MyMUtils";
import MainMenu from "../views/mainMenu";
import { TOKEN } from "constants/ApiEndPointsConstants";

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function PrivateRoute({ component: Component, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
}

export const Views = (props) => {
  const { locale, location, isAutoLogin } = props;
  const token = localStorage.getItem(TOKEN);
  const currentAppLocale = AppLocale[locale];
  return (
    <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <div className="global_loader" >

          
      <Spin spinning={isAutoLogin} >
          <ConfigProvider locale={currentAppLocale.antd}>
            <Switch>
              <Route exact path="/">
                <Redirect exact to={APP_PREFIX_PATH} />
              </Route>
              <Route path={AUTH_PREFIX_PATH}>
                <AuthLayout isAuthenticated={false} />
              </Route>

              <PrivateRoute
                isAuthenticated={token}
                path="/menu"
                component={MainMenu}
              />

              <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
                <AppLayout location={location} />
              </RouteInterceptor>
            </Switch>
          </ConfigProvider>
      </Spin>
      </div>
        </IntlProvider>
  );
};

const mapStateToProps = ({ theme, autoLoginReducer }) => {
  const { locale } = theme;
  const { isAutoLogin } = autoLoginReducer;
  return { locale, isAutoLogin };
};

export default withRouter(connect(mapStateToProps)(Views));
