import React, { memo, useState, useEffect } from "react";
import { Button, Row, Col } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Form from "antd/lib/form/Form";
import { useDispatch } from "react-redux";
import { sendMail } from "redux/actions/sendmail.action";
import { toast } from "react-toastify";

const Email = (props) => {
  console.log(props,"pppppppppppppppppp")
  const [data, setData] = useState("");
  const dispatch = useDispatch();

  const handleClose = () => {
    props.visibleMailprop();
    setData("");
  };

  const validateEmail = (email) => {
    var validEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let result = email.map((item) => {
      if (item.match(validEmail)) {
        return true;
      } else {
        return false;
      }
    });
    return result.includes(false);
  };

  const handleSubmit = () => {
    var emailData = data.split(",");
    var str = props.passData.pdf_link;
    var res = str.split("/");
    res = res[res.length - 1];
    if (res.substr(-4) !== ".pdf") {
      res = res + ".pdf";
    }
    const apiData = {
      subject: props.passData.email_subject,
      email_list: data,
      html_body: "",
      attachments: [
        {
          filename: res,
          path: props.passData.pdf_link,
        },
      ],
    };

    if (data === "") {
      toast.error("Please input your email id!");
    } else {
      if (!validateEmail(emailData)) {
        dispatch(sendMail(apiData)).then((record) => {
          if (record.payload.result === true) {
            toast.success("Thanks! Email has been sent successfully.");
            props.visibleMailprop();
            setData("");
          } else {
            toast.error(record.payload.result.message);
            props.visibleMailprop();
            setData("");
          }
        });
      } else {
        toast.error("Please enter valid email address!");
        setData("");
      }
      setData("");
    }
  };

  const onChange = (e, record) => {
    const { value } = e.target;
    setData(value);
  };

  return (
    <>
      <Form
        onFinish={handleSubmit}
        layout="vertical"
        // onMouseLeave={() => props.visibleMailprop()}
      >
        <p>Separate emails with commas</p>
        <TextArea
          onChange={onChange}
          name="subject"
          style={{ height: "150px" }}
          value={data}
        />

        <br />
        <br />
        <Row gutter={24} className="justify-content-center">
          <Col md={12}>
            <Button
              onClick={() => handleClose()}
              className="mr-2 ml-3"
              htmlType="button"
            >
              Close
            </Button>
          </Col>
          <Col md={12}>
            <Button className="mr-2" htmlType="submit">
              Send
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default memo(Email);
