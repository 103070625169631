import {
  UPDATE_MANAGER_SUCCESS,
  UPDATE_MANAGER_FAILURE,
} from "../constants/MyM";
import { putApi } from "../../utils/apiCall";

const updateManagerSuccess = (data) => {
  return {
    type: UPDATE_MANAGER_SUCCESS,
    payload: data,
  };
};

const updateManagerFailure = (data) => {
  return {
    type: UPDATE_MANAGER_FAILURE,
    payload: data,
  };
};

export const updateManager = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await putApi(`/manager_contact_details`, {
      ...data,
    });
    if (!error) {
      return dispatch(updateManagerSuccess(result));
    }
    return dispatch(updateManagerFailure(result));
  };
};
