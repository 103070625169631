import { EDIT_USER_FAILURE, EDIT_USER_SUCCESS } from "../constants/MyM";
import { onSuccess, onFailure } from "./common";

const initStat = { data: [], error: false, errorData: "", isLoading: true };

export default (state = initStat, action) => {
  switch (action.type) {
    case EDIT_USER_SUCCESS:
      return onSuccess(state, action);
    case EDIT_USER_FAILURE:
      return onFailure(state, action);
    default:
      return { ...state };
  }
};
