import {
  POST_UPLOAD_TIMESHEET_SUCCESS,
  POST_UPLOAD_TIMESHEET_FAILURE,
} from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const postUploadTimesheetSuccess = (data) => {
  return {
    type: POST_UPLOAD_TIMESHEET_SUCCESS,
    payload: data,
  };
};

const postUploadTimesheetFailure = (data) => {
  return {
    type: POST_UPLOAD_TIMESHEET_FAILURE,
    payload: data,
  };
};

export const uploadTimesheet = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await postApi(`/upload_emp_timesheet_data`, {
      ...data,
    });
    if (!error) {
      return dispatch(postUploadTimesheetSuccess(result));

    }
    return dispatch(postUploadTimesheetFailure(result));
  };
};
