import { DELETE_EXTRA_HOURS_SUCCESS, DELETE_EXTRA_HOURS_FAILURE } from "redux/constants/MyM";
import { putApi } from "utils/apiCall";

const deleteExtraHoursSuccess = (data) => {
    return {
        type: DELETE_EXTRA_HOURS_SUCCESS,
        payload: data
    }
}

const deleteExtraHoursFailure = (data) => {
    return {
        type: DELETE_EXTRA_HOURS_FAILURE,
        payload: data
    }
}

export const deleteExtraHours = ( data) => {
    return async (dispatch, getState) => {
      const { error, result } = await putApi(`/delete_facility_extra_hours_by_id`, data);
      if (!error) {
        return dispatch(deleteExtraHoursSuccess(result));
      }
      return dispatch(deleteExtraHoursFailure(result));
    };
  };
  