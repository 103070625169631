import React, { memo, useEffect, useState } from "react";
import {
  Table,
  Form,
  Button,
  Select,
  Row,
  Col,
  Tabs,
  Tooltip,
  Modal,
  Input,
  Popconfirm,
} from "antd";
import { getApiArrayDetails, isValidArray } from "../../../utils/MyMUtils";
import { useDispatch, useSelector } from "react-redux";
import { EyeOutlined, EditOutlined, DeleteOutlined,DownloadOutlined } from "@ant-design/icons";
import { getManagementData } from "redux/actions/management.action";
import { postNewManager } from "redux/actions/postNewManager.action";
import { updateManager } from "redux/actions/updateManager.action";
import { getStateList } from "redux/actions/stateList.action";
import { deleteManager } from "redux/actions/deleteManager.action";
import { toast } from "react-toastify";
import { PlusCircleOutlined } from "@ant-design/icons";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import generatePDFManagement from "./generatePdfManagement";

const { Option } = Select;
const { TabPane } = Tabs;

const ManagementContact = () => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [visibleModal, setVisibleModal] = useState(false);
  const [defaultValue, setDefaultValue] = useState("");
  const [getState, setGetState] = useState("");
  const [userID, setUserID] = useState("");
  const [displayModal, setDisplayModal] = useState(false);
  const [fileName, setFileName] = useState("Management Details");

  const dispatch = useDispatch();

  useEffect(() => {
    form.resetFields();
  }, [visibleModal]);

  useEffect(() => {
    form2.resetFields();
  }, [visible]);

  useEffect(() => {
    form3.resetFields();
  }, [displayModal]);

  const contractorList = useSelector((state) => getApiArrayDetails(state.management.data));

  const stateList = useSelector((state) => getApiArrayDetails(state.stateList.data));

  useEffect(() => {
    dispatch(getManagementData());
  }, []);

  useEffect(() => {
    dispatch(getStateList());
  }, []);

  const postNewManagerRecord = useSelector((state) => {
    return state.postNewManagerInfo.data;
  });

  const updateManagerRecord = useSelector((state) => {
    return state.updateManagerInfo.data;
  });

  const deleteManagerInfo = useSelector((state) => {
    return state.deleteManager.data;
  });

  const exportToCSV = (csvData, fileName) => {
    if (csvData) {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      toast.error("No Data in Table!");
    }
  };

  const excelData = () => {
    if (isValidArray(contractorList)) {
      return contractorList?.map((item) => {
        return {
          "First Name": item.first_name,
          "Last Name":item.last_name,
          "Job Title":item.job_title,
          "Phone Number":item.mobile,
          "Email":item.email,
          "Address":item.address,
          "State":item.state,
        };
      });
    }
  };

  const updateModalFunction = (elm) => {
    setDefaultValue(elm);
    setVisibleModal(true);
    setUserID(elm.user_id);
    setGetState(elm.state_id)
  };

  const postNewManagerFunction = (data) => {
    dispatch(postNewManager(data)).then((record) => {
      if (record.payload.success === true) {
        toast.success(record.payload.message);
        dispatch(getManagementData());
      } else {
        toast.error(record.payload.message);
        dispatch(getManagementData());
      }
    });
    setVisible(false);
  };

  const updateManagerFunction = (info) => {
    const updateInfo = {
      ...info,
      state_id: getState,
      user_id: userID,
    };
    dispatch(updateManager(updateInfo)).then((record) => {
      if (record.payload.success === true) {
        toast.success(record.payload.message);
        dispatch(getManagementData());
      } else {
        toast.error(record.payload.message);
        dispatch(getManagementData());
      }
    });
    setVisibleModal(false);
  };

  const deleteFunction = (elm) => {
    dispatch(deleteManager(elm.user_id)).then((record) => {
      if (record.payload.success === true) {
        toast.success(record.payload.message);
        dispatch(getManagementData());
      } else {
        toast.error(record.payload.message);
        dispatch(getManagementData());
      }
    });
  };

  const ViewFunction = (elm) => {
    setDisplayModal(true);
    setDefaultValue(elm);
  };

  const getStateListDetails = () => {
    if (isValidArray(stateList)) {
      return stateList.map((state) => (
        <Option name="state_id" key={state.state_id} value={state.state_id}>
          {state.state_name}
        </Option>
      ));
    }
  };

  const selectState = (e) => {
    setGetState(e);
  };

  const getDefaultStateListDetails = () => {
    if (isValidArray(stateList)) {
      return stateList.map((state) => (
        <Option name="state_id" key={state.state_id} value={state.state_id}>
          {state.state_name}
        </Option>
      ));
    }
  };
  const openNewManagerModal = () => {
    setVisible(true);
  };

  function cancel(e) {}

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "25%",
      filters: contractorList
        ?.map((f) => {
          return { text: f.first_name, value: f.first_name };
        })
        .sort((a, b) => a.text.toUpperCase().charCodeAt(0) - b.text.toUpperCase().charCodeAt(0)),
      onFilter: (value, record) => record.first_name.includes(value),
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
      width: "25%",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: "25%",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "25%",
      render: (_, elm, row) => (
        <div>
          <span className="mr-3">
            <Tooltip title="View" color="rgb(20, 72, 129)">
              <Button onClick={() => ViewFunction(elm)}>
                <EyeOutlined />
              </Button>
            </Tooltip>
          </span>
          <span className="mr-3">
            <Tooltip title="Edit" color="rgb(20, 72, 129)">
              <Button onClick={() => updateModalFunction(elm)}>
                <EditOutlined />
              </Button>
            </Tooltip>
          </span>
          <span className="mr-3">
            <Popconfirm
              title="Are you sure to delete this data?"
              onConfirm={() => deleteFunction(elm)}
              onCancel={() => cancel}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete" color="rgb(20, 72, 129)">
                <Button>
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <Button
        className="style-btn ml-2"
        onClick={openNewManagerModal}
        style={{ float: "right", marginBottom: "20px" }}
        icon={<PlusCircleOutlined />}
        shape="round"
        size="default"
      >
        New Manager
      </Button>
      <Button
          shape="round"
          className="ml-2"
          style={{ float: "right" }}
          onClick={() => generatePDFManagement(contractorList, columns)}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
        <Button
          shape="round"
          style={{ float: "right" }}
          onClick={(e) => exportToCSV(excelData(), fileName)}
          icon={<DownloadOutlined />}
        >
          Excel
        </Button>
      <Form layout="horizontal">
        <Table
          loading={!isValidArray(contractorList)}
          columns={columns}
          dataSource={contractorList}
          rowKey="id"
        />
      </Form>
      <Modal
        title="New Manager"
        centered
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        width={900}
      >
        <Form onFinish={postNewManagerFunction} layout="vertical" form={form2}>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item
                name="first_name"
                label="First Name:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter First Name",
                  },
                ]}
              >
                <Input placeholder="Enter First Name" />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="last_name"
                label="Last Name:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Last Name",
                  },
                ]}
              >
                <Input placeholder="Enter Last Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item
                name="job_title"
                label="Job Title:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Job Title",
                  },
                ]}
              >
                <Input placeholder="Enter Job Title" />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="mobile"
                label="Phone Number:"
                rules={[
                  {
                    // type: "/^[0-9]$/",
                    message: "The input Only Number!",
                  },
                ]}
              >
                <Input placeholder="Enter Phone number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item
                name="email"
                label="Email:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Email",
                  },
                  { type: "email", message: "Please Enter Correct Email" },
                ]}
              >
                <Input placeholder="Enter Email" />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="password"
                label="Password:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Password",
                  },
                ]}
              >
                <Input.Password placeholder="Enter Password" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item
                name="address"
                label="Address:"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Address",
                //   },
                // ]}
              >
                <Input placeholder="Enter Address" />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="state_id"
                label="State:"
                rules={[
                  {
                    required: true,
                    message: "Please Select State",
                  },
                ]}
              >
                <Select>{getStateListDetails()}</Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button className="style-btn" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Edit Manager"
        centered
        visible={visibleModal}
        footer={null}
        onCancel={() => setVisibleModal(false)}
        width={900}
      >
        <Form
          onFinish={updateManagerFunction}
          layout="vertical"
          initialValues={defaultValue}
          form={form}
        >
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item
                name="first_name"
                label="First Name:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter First Name",
                  },
                ]}
              >
                <Input placeholder="Enter First Name" />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="last_name"
                label="Last Name:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Last Name",
                  },
                ]}
              >
                <Input placeholder="Enter Last Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item
                name="job_title"
                label="Job Title:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Job Title",
                  },
                ]}
              >
                <Input placeholder="Enter Job Title" />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="mobile"
                label="Phone Number:"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Phone number",
                //   },
                // ]}
              >
                <Input placeholder="Enter Phone number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item
                name="email"
                label="Email:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Email",
                  },
                  { type: "email", message: "Please Enter Correct Email" },
                ]}
              >
                <Input placeholder="Enter Email" />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="address"
                label="Address:"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Address",
                //   },
                // ]}
              >
                <Input placeholder="Enter Address" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item
                name="state"
                label="State:"
                rules={[
                  {
                    required: true,
                    message: "Please Select State",
                  },
                ]}
              >
                <Select onChange={selectState}>{getDefaultStateListDetails()}</Select>
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              {/* <Form.Item
                name="user_id"
                label="User ID:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter User ID",
                  },
                ]}
              >
                <Input placeholder="Enter User ID" />
              </Form.Item> */}
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button className="style-btn" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="View Manager"
        centered
        visible={displayModal}
        footer={null}
        onCancel={() => setDisplayModal(false)}
        width={900}
      >
        <Form layout="vertical" initialValues={defaultValue} form={form3}>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item name="first_name" label="First Name:">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item name="last_name" label="Last Name:">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item name="job_title" label="Job Title:">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item name="mobile" label="Phone Number:">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item name="email" label="Email:">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item name="address" label="Address:">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item name="state" label="State:">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}></Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(ManagementContact);
