import {
  POST_UPLOAD_LABOUR_HIRE_SUCCESS,
  POST_UPLOAD_LABOUR_HIRE_FAILURE,
  POST_UPLOAD_LABOUR_HIRE_REQUEST,
} from "../constants/MyM";
import { postApi } from "../../utils/apiCall";
import { apiToastError, apiToastSuccess } from "../../utils/MyMUtils";

const uploadLabourHireSuccess = (data) => {
  return {
    type: POST_UPLOAD_LABOUR_HIRE_SUCCESS,
    payload: data,
  };
};

const uploadLabourHireFailure = (data) => {
  return {
    type: POST_UPLOAD_LABOUR_HIRE_FAILURE,
    payload: data,
  };
};

export const uploadLabourHireRequest = () => ({
  type: POST_UPLOAD_LABOUR_HIRE_REQUEST,
});

export const uploadLabourHire = (uploadInfo) => (dispatch, getState) => {
  dispatch(uploadLabourHireRequest());
  return postApi(`/upload_staff_certificate`, {
    ...uploadInfo,
  })
    .then((data) => {
      apiToastSuccess(data);
      return dispatch(uploadLabourHireSuccess(data));
    })
    .catch((error) => {
      apiToastError(error);
      return dispatch(uploadLabourHireFailure(error));
    });
};
