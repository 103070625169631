import { POST_MANAGE_HOLIDAY_SUCCESS, POST_MANAGE_HOLIDAY_FAILURE } from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const postManageHolidaySuccess = (data) => {
  return {
    type: POST_MANAGE_HOLIDAY_SUCCESS,
    payload: data,
  };
};

const postManageHolidayFailure = (data) => {
  return {
    type: POST_MANAGE_HOLIDAY_FAILURE,
    payload: data,
  };
};

export const postManageHoliday = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await postApi(`/manage_holiday`, {
      ...data,
    });
    if (!error) {
      return dispatch(postManageHolidaySuccess(result));
    }
    return dispatch(postManageHolidayFailure(result));
  };
};
