import {  DELETE_EXTRA_HOURS_SUCCESS, DELETE_EXTRA_HOURS_FAILURE } from "redux/constants/MyM";

const initState = { data: [], error: false, errorData: "", isLoading: true }

export default (state = initState, action) => {
    switch (action.type) {
        case DELETE_EXTRA_HOURS_SUCCESS:
            return {
                ...state, data: action.payload, isLoading: false
            }
        case DELETE_EXTRA_HOURS_FAILURE:
            return {
                ...state, errorData: action.payload, error: true, isLoading: false
            }
        default:
            return {
                ...state
            }
    }
}