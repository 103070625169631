import { GET_FACILITY_USER_LIST_SUCCESS, GET_FACILITY_USER_LIST_FAILURE } from "../constants/MyM";
import { getApi } from "utils/apiCall";

const getFacilityUserSuccess = (data) => {
  return {
    type: GET_FACILITY_USER_LIST_SUCCESS,
    payload: data,
  };
};

const getFacilityUserFailure = (data) => {
  return {
    type: GET_FACILITY_USER_LIST_FAILURE,
    payload: data,
  };
};

export const getFacilityUserData = (id) => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/facility_user_list/${id}`);
    if (!error) {
      return dispatch(getFacilityUserSuccess(result));
    }
    return dispatch(getFacilityUserFailure(result));
  };
};
