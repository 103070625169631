import {
  POST_UPLOAD_CERTIFICATE_SUCCESS,
  POST_UPLOAD_CERTIFICATE_FAILURE,
  POST_UPLOAD_CERTIFICATE_REQUEST,
} from "../constants/MyM";
import { postApi } from "../../utils/apiCall";
import { apiToastError, apiToastSuccess } from "../../utils/MyMUtils";

const uploadCertificateSuccess = (data) => {
  return {
    type: POST_UPLOAD_CERTIFICATE_SUCCESS,
    payload: data,
  };
};

const uploadCertificateFailure = (data) => {
  return {
    type: POST_UPLOAD_CERTIFICATE_FAILURE,
    payload: data,
  };
};

export const uploadCertificateRequest = () => ({
  type: POST_UPLOAD_CERTIFICATE_REQUEST,
});

export const uploadCertificate = (uploadInfo) => (dispatch, getState) => {
  dispatch(uploadCertificateRequest());
  return postApi(`/upload_certificate`, {
    ...uploadInfo,
  })
    .then((data) => {
      apiToastSuccess(data);
      return dispatch(uploadCertificateSuccess(data));
    })
    .catch((error) => {
      apiToastError(error);
      return dispatch(uploadCertificateFailure(error));
    });
};
