import {
  POST_UPLOAD_COVID_VACCINE_CERTIFICATE_SUCCESS,
  POST_UPLOAD_COVID_VACCINE_CERTIFICATE_FAILURE,
  POST_UPLOAD_COVID_VACCINE_CERTIFICATE_REQUEST,
} from "../constants/MyM";
import { postApi } from "../../utils/apiCall";
import { apiToastError, apiToastSuccess } from "../../utils/MyMUtils";

const uploadCovidVaccineCertificateSuccess = (data) => {
  return {
    type: POST_UPLOAD_COVID_VACCINE_CERTIFICATE_SUCCESS,
    payload: data,
  };
};

const uploadCovidVaccineCertificateFailure = (data) => {
  return {
    type: POST_UPLOAD_COVID_VACCINE_CERTIFICATE_FAILURE,
    payload: data,
  };
};

export const uploadCovidVaccineCertificateRequest = () => ({
  type: POST_UPLOAD_COVID_VACCINE_CERTIFICATE_REQUEST,
});

export const uploadCovidVaccineCertificate =
  (uploadInfo) => (dispatch, getState) => {
    dispatch(uploadCovidVaccineCertificateRequest());
    return postApi(`/covid_vaccination_certificate`, {
      ...uploadInfo,
    })
      .then((data) => {
        apiToastSuccess(data);
        return dispatch(uploadCovidVaccineCertificateSuccess(data));
      })
      .catch((error) => {
        apiToastError(error);
        return dispatch(uploadCovidVaccineCertificateFailure(error));
      });
  };
