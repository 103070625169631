import { GET_HOLIDAY_LIST_FOR_MANAGE_HOLIDAY_SUCCESS, GET_HOLIDAY_LIST_FOR_MANAGE_HOLIDAY_FAILURE } from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const getHolidayListForManageHolidaySuccess = (data) => {
  return {
    type: GET_HOLIDAY_LIST_FOR_MANAGE_HOLIDAY_SUCCESS,
    payload: data,
  };
};

const getHolidayListForManageHolidayFailure = (data) => {
  return {
    type: GET_HOLIDAY_LIST_FOR_MANAGE_HOLIDAY_FAILURE,
    payload: data,
  };
};

export const getHolidayListForManageHoliday = (year) => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/get_holiday_list_for_manage_holiday?year=${year}`);
    if (!error) {
      return dispatch(getHolidayListForManageHolidaySuccess(result));
    }
    return dispatch(getHolidayListForManageHolidayFailure(result));
  };
};
