import jsPDF from "jspdf";
import "jspdf-autotable";
import { isValidArray } from "../../../utils/MyMUtils";
import { toast } from "react-toastify";
import moment from "moment";

const generatePDFPoliceCheck = (data, column) => {
  const doc = new jsPDF({
    orientation: "l",
    unit: "mm",
    format: "a2",
    putOnlyUsedFonts: true,
  });

  const tableArray = ["First Name", "Last Name", "Police Check Reference", "Certificate Sited","Are there Offences","Offence Type","Certification Date", "Certificate Expiry","Days until Expiry"];

  const tableRows = [];

  if (isValidArray(data)) {
    data.forEach((d) => {
      const data = [d.first_name, d.last_name, d.ref_number, d.certificate_sited == 0 ? "No" : d.certificate_sited == 1 ? "Yes" : "", d.are_there_offences == 0 ? "No" : d.are_there_offences == 1 ? "Yes" : "",d.offence_type,moment(d.issue_date).format("DD/MM/YYYY"),moment(d.expiry_date).format("DD/MM/YYYY") ,d.days_until_expiry == 1 ? "" : d.days_until_expiry];
      tableRows.push(data);
    });

    doc.autoTable(tableArray, tableRows, { startY: 10, startX: 0 });
    const date = Date().split(" ");

    const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];

    doc.save(`Police Check Certificate.pdf`);
  } else {
    toast.error("No Data in Table!");
  }
};

export default generatePDFPoliceCheck;