import {
  GET_STATEMENT_POLICE_SUCCESS,
  GET_STATEMENT_POLICE_FAILURE,
} from "../constants/MyM";
import { getApi } from "utils/apiCall";

const statementPoliceSuccess = (data) => {
  return {
    type: GET_STATEMENT_POLICE_SUCCESS,
    payload: data,
  };
};

const statementPoliceFailure = (data) => {
  return {
    type: GET_STATEMENT_POLICE_FAILURE,
    payload: data,
  };
};

export const statementPolice = (ids) => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(
      `/get_police_statements?facility_id=${ids}`
    );
    if (!error) {
      return dispatch(statementPoliceSuccess(result));
    }
    return dispatch(statementPoliceFailure(result));
  };
};
