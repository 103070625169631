import React, { useState, useEffect } from "react";
import { Button, Row, Col, Divider, Form, Input, Switch, Spin } from "antd";
import {  FileDoneOutlined,LeftSquareOutlined } from "@ant-design/icons";
import { isValidObject } from "../../../utils/MyMUtils";

function ExistingStep4(props) {
  const [checked, setChecked] = useState(false);
  const [userName, setUserName] = useState(props?.userData?.user_name);

  const prev = () => {
    props.prev();
  };

  useEffect(() => {
    if (isValidObject(props.userData)) {
      setUserName(props.userData.user_name);
    }
  }, [props]);

  const onFinish = (values) => {
    props.done(values);
  };

  const onFinishFailed = (errorInfo) => {};

  function onChange(checked) {
    setChecked(checked);
  }

  return (
    <>
      {props.loader ? (
        <>
          <div className="steps-content">
            <div className="d-flex justify-content-center loader-spin">
              <Spin size="large" />
            </div>
          </div>
        </>
      ) : (
        <>
          <Form
            labelCol={{ span: 11 }}
            wrapperCol={{ span: 13 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div className="steps-content">
              <Divider style={{ fontSize: "22px" }}>Login</Divider>
              <p></p>
              <Row
                gutter={24}
                style={{ marginLeft: "0px", marginRight: "0px" }}
                className="d-flex justify-content-center"
              >
                <div className="flex-column">
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                      className="mt-3"
                      label="Username"
                      name="username"
                      initialValue={userName && userName}
                      rules={[{ required: true, message: "Please input your username!" }]}
                    >
                      <Input placeholder="Enter Username" />
                    </Form.Item>
                  </Col>
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <Form.Item label="Reset Password">
                      <Switch onChange={onChange} />
                    </Form.Item>
                  </Col>

                  {checked === true ? (
                    <>
                      <Col lg={24} md={24} sm={24} xs={24}>
                        <Form.Item className="mt-3" label="Password" name="password">
                          <Input defaultValue="" placeholder="Enter Password" />
                        </Form.Item>
                      </Col>
                    </>
                  ) : null}
                </div>
              </Row>
            </div>
            <Row gutter={24} className="mt-2">
              <Col lg={12} md={12} sm={24} xs={24}></Col>
              <Col lg={12} md={12} sm={24} xs={24} className="text-right">
                <Button
              shape="round"
              className="mr-3"
              icon={<LeftSquareOutlined />}
              onClick={prev}
            >
              Prev
            </Button>
                <Button shape="round" htmlType="submit" icon={<FileDoneOutlined />}>
                  Done
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
}

export default ExistingStep4;
