import {
  DELETE_FACILITY_SUCCESS,
  DELETE_FACILITY_FAILURE,
} from "../constants/MyM";
import { deleteApi } from "../../utils/apiCall";

const deleteFacilitySuccess = (data) => {
  return {
    type: DELETE_FACILITY_SUCCESS,
    payload: data,
  };
};

const deleteFacilityFailure = (data) => {
  return {
    type: DELETE_FACILITY_FAILURE,
    payload: data,
  };
};

export const deleteFacility = (id) => {
  return async (dispatch, getState) => {
    const { error, result } = await deleteApi(`/delete_fac_for_mym_web/${id}`);
    if (!error) {
      return dispatch(deleteFacilitySuccess(result));
    }
    return dispatch(deleteFacilityFailure(result));
  };
};
