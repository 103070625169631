import {
  POST_UPLOAD_ISO_SUCCESS,
  POST_UPLOAD_ISO_FAILURE,
  POST_UPLOAD_ISO_REQUEST,
} from "../constants/MyM";
import { postApi } from "../../utils/apiCall";
import { apiToastError, apiToastSuccess } from "../../utils/MyMUtils";

const uploadIsoCertificateSuccess = (data) => {
  return {
    type: POST_UPLOAD_ISO_SUCCESS,
    payload: data,
  };
};

const uploadIsoCertificateFailure = (data) => {
  return {
    type: POST_UPLOAD_ISO_FAILURE,
    payload: data,
  };
};

export const uploadIsoCertificateRequest = () => ({
  type: POST_UPLOAD_ISO_REQUEST,
});

export const uploadIsoCertificate = (uploadInfo) => (dispatch, getState) => {
  dispatch(uploadIsoCertificateRequest());
  return postApi(`/upload_iso_certificate`, {
    ...uploadInfo,
  })
    .then((data) => {
      apiToastSuccess(data);
      return dispatch(uploadIsoCertificateSuccess(data));
    })
    .catch((error) => {
      apiToastError(error);
      return dispatch(uploadIsoCertificateFailure(error));
    });
};
