import {
  UPDATE_CONTRACTOR_SUCCESS,
  UPDATE_CONTRACTOR_FAILURE,
} from "../constants/MyM";
import { putApi } from "../../utils/apiCall";

const updateContractorSuccess = (data) => {
  return {
    type: UPDATE_CONTRACTOR_SUCCESS,
    payload: data,
  };
};

const updateContractorFailure = (data) => {
  return {
    type: UPDATE_CONTRACTOR_FAILURE,
    payload: data,
  };
};

export const updateContractor = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await putApi(`/contractor_contact_details`, {
      ...data,
    });
    if (!error) {
      return dispatch(updateContractorSuccess(result));
    }
    return dispatch(updateContractorFailure(result));
  };
};
