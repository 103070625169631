import {
  GET_STATE_DECLARATION_SUCCESS,
  GET_STATE_DECLARATION_FAILURE,
} from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const stateSuccess = (data) => {
  return {
    type: GET_STATE_DECLARATION_SUCCESS,
    payload: data,
  };
};

const stateFailure = (data) => {
  return {
    type: GET_STATE_DECLARATION_FAILURE,
    payload: data,
  };
};

export const getStateDeclarationData = (ids) => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/user_state_declare?user_id=${ids}`);
    if (!error) {
      return dispatch(stateSuccess(result));
    }
    return dispatch(stateFailure(result));
  };
};
