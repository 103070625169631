import React, { memo, useEffect, useState } from "react";
import { Table, Row, Button, Input, Form, Col, Modal,Tooltip,Popconfirm } from "antd";
import { PlusCircleOutlined,EditOutlined,DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getApiArrayDetails } from "../../../utils/MyMUtils";
import { getHolidayList } from "redux/actions/getHolidayList.action";
import {postNewHoliday} from "redux/actions/postHoliday.action"
import {deleteHoliday} from "redux/actions/deleteHoliday.action"
import {updateHoliday} from "redux/actions/updateHoliday.action"
import { toast } from "react-toastify";

const PublicHoliday = () => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [defaultValue, setDefaultValue] = useState([]);
  const [id,setId]=useState("")
  

  const holidayData = useSelector((state) =>
    getApiArrayDetails(state.getHolidayListData.data)
  );

  useEffect(() => {
    setLoading(true);
    dispatch(getHolidayList()).then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    form.resetFields();
    form1.resetFields()
  }, [visible,editVisible]);

  const deleteFunction = (elm) => {
    dispatch(deleteHoliday(elm.id)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        dispatch(getHolidayList());
      } else {
        toast.error(record.payload.message);
        dispatch(getHolidayList());
      }
    });
  };

  const EditFunction = (e) => {
    setId(e.id)
    setEditVisible(true);
    setDefaultValue({
      ...e,
    });
  };

  const column = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "70%",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "30%",
      render: (_, elm) => <div>
      <span className="mr-3">
        <Tooltip title="Edit" color="rgb(20, 72, 129)">
          <Button onClick={() => EditFunction(elm)}>
            <EditOutlined />
          </Button>
        </Tooltip>
      </span>
      <span className="mr-3">
        <Popconfirm
          title="Are you sure to delete this data?"
          onConfirm={() => deleteFunction(elm)}
          onCancel={() => cancel}
          okText="Yes"
          cancelText="No"
        >
          <Tooltip title="Delete" color="rgb(20, 72, 129)">
            <Button>
              <DeleteOutlined />
            </Button>
          </Tooltip>
        </Popconfirm>
      </span>
    </div>
    },
  ];

  const cancel = () => {};


  const PostData = (data) => {
    setLoader(true);
    dispatch(postNewHoliday(data)).then((record) => {
      if (record.payload.success) {
        toast.success(record.payload.message);
        setLoader(false);
        setVisible(false);
        dispatch(getHolidayList());
      } else {
        toast.error(record.payload.message);
        setLoader(false);
        setVisible(false);
        dispatch(getHolidayList());
      }
    });
  };

  const EditData = (data) => {
    setEditLoader(true)
    dispatch(updateHoliday(id,data)).then((record) => {
      if (record.payload.success) {
        toast.success(record.payload.message);
        setEditLoader(false)
        setEditVisible(false);
        dispatch(getHolidayList()).then(() => {
        });
      } else {
        toast.error(record.payload.message);
        setEditLoader(false)
        setEditVisible(false);
        dispatch(getHolidayList()).then(() => {
          setLoading(false);
        });
      }
    });
  };


  

  return (
    <>
        <div >
          <Button
            className="style-btn"
            onClick={() => setVisible(true)}
            style={{ float: "right", marginBottom: "20px" }}
            icon={<PlusCircleOutlined />}
            shape="round"
            size="default"
          >
            Add Holiday
          </Button>
        </div>
      <Table loading={loading} columns={column} dataSource={holidayData} />

      <Modal
        title="Add Holiday"
        centered
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        width={400}
      >
        <Form onFinish={PostData}  layout="vertical" form={form}>
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item
                name="name"
                className="btn-weekpicker"
                label="Holiday"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Holiday",
                  },
                ]}
              >
                <Input placeholder="Holiday" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button htmlType="submit" loading={loader}>Submit</Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Edit Holiday"
        centered
        visible={editVisible}
        footer={null}
        onCancel={() => setEditVisible(false)}
        width={400}
      >
        <Form onFinish={EditData} initialValues={defaultValue} layout="vertical" form={form1}>
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item
                name="name"
                className="btn-weekpicker"
                label="Holiday"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Holiday",
                  },
                ]}
              >
                <Input placeholder="Holiday" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button htmlType="submit" loading={editLoader}>Submit</Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default memo(PublicHoliday);
