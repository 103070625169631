import { GET_EXTRA_HOURS_SUCCESS, GET_EXTRA_HOURS_FAILURE } from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const getExtraHoursSuccess = (data) => {
  return {
    type: GET_EXTRA_HOURS_SUCCESS,
    payload: data,
  };
};

const getExtraHoursFailure = (data) => {
  return {
    type: GET_EXTRA_HOURS_FAILURE,
    payload: data,
  };
};

export const getExtraHoursList = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await postApi(`/get_extra_hours_by_facility`, {
      ...data,
    });
    if (!error) {
      return dispatch(getExtraHoursSuccess(result));
    }
    return dispatch(getExtraHoursFailure(result));
  };
};
