import {
  GET_EXIST_STAFF_USER_MANAGEMENT_SUCCESS,
  GET_EXIST_STAFF_USER_MANAGEMENT_FAILURE,
} from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const getExistStaffUserManagementSuccess = (data) => {
  return {
    type: GET_EXIST_STAFF_USER_MANAGEMENT_SUCCESS,
    payload: data,
  };
};

const getExistStaffUserManagementFailure = (data) => {
  return {
    type: GET_EXIST_STAFF_USER_MANAGEMENT_FAILURE,
    payload: data,
  };
};

export const getExistStaffUserManagement = () => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(
      `/get_all_staff_user_management?type=exist`
    );
    if (!error) {
      return dispatch(getExistStaffUserManagementSuccess(result));
    }
    return dispatch(getExistStaffUserManagementFailure(result));
  };
};
