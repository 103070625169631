import React, { memo, useEffect, useState, useRef } from "react";
import {
  Form,
  Button,
  Table,
  Modal,
  Card,
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Divider,
  Tooltip,
  Popconfirm,
  Space,
} from "antd";
import {
  PlusCircleOutlined,
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import { getApiArrayDetails, isValidArray } from "../../../utils/MyMUtils";
import { useDispatch, useSelector } from "react-redux";
import { getGroup } from "redux/actions/group.action";
import { getFacility } from "redux/actions/facility.action";
import { getServiceType } from "redux/actions/getServiceType.action";
import { postContractedHours } from "redux/actions/postcontractedHours.action";
import { getContractedHoursInfo } from "redux/actions/getContractedHours.action";
import { deleteContractedHours } from "redux/actions/deleteContractedHours.action";
import { updateContractedHours } from "redux/actions/updateContractedHours.action";

const ContractedHours = () => {
  const { Option } = Select;
  const dispatch = useDispatch();
  let searchInput = useRef(null);

  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [facilityId, setFacilityId] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [facilityIdData, setFacilityIdData] = useState([]);
  const [serviceTypeId, setServiceTypeId] = useState([]);
  const [visible, setVisible] = useState(false);
  const [validDate, setValidDate] = useState("");
  const [defaultValues, setDefaultValue] = useState("");
  const [editVisible, setEditVisible] = useState(false);
  const [rowId, setRowId] = useState("");

  const organizationList = useSelector((state) =>
    getApiArrayDetails(state.GroupList.data)
  );

  const facilityList = useSelector((state) =>
    getApiArrayDetails(state.FacilitiesList.data)
  );

  const serviceTypeList = useSelector((state) =>
    getApiArrayDetails(state.ServiceType.data)
  );

  const contractedHours = useSelector((state) =>
    getApiArrayDetails(state.getContractedHours.data)
  );

  useEffect(() => {
    dispatch(getGroup());
  }, []);

  useEffect(() => {
    form.resetFields();
    form2.resetFields();
  }, [visible, editVisible]);

  const handleOnChangeGroupView = (event) => {
    dispatch(getFacility(event));
    setFacilityIdData([]);
    setServiceTypeId([]);
  };

  const handleOnChangeGroup = (event) => {
    dispatch(getFacility(event));
  };

  const handleOnChangeFacility = (event) => {
    dispatch(getServiceType(event));
    setFacilityId(event);
    setFacilityIdData(event);
    setServiceTypeId([]);
  };
  const handleOnChangeAddFacility = (event) => {
    dispatch(getServiceType(event));
  };

  const handleServiceType = (event) => {
    setLoading(true);
    setServiceTypeId(event);
    setServiceId(event);
    const valueData = {
      facility_id: facilityId,
      service_type: event,
    };
    dispatch(getContractedHoursInfo(valueData)).then((record) => {
      if (record.payload.success) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const getOrganisationListDetails = () => {
    if (isValidArray(organizationList)) {
      return organizationList.map((organization) => (
        <Option
          name="state_id"
          key={organization.org_id}
          value={organization.org_id}
        >
          {organization.org_name}
        </Option>
      ));
    }
  };
  const getFacilityListDetails = () => {
    if (isValidArray(facilityList)) {
      return facilityList.map((facility) => (
        <Option
          name="facility_id"
          key={facility.facility_id}
          value={facility.facility_id}
        >
          {facility.facility_name}
        </Option>
      ));
    }
  };

  const getServiceTypeListDetails = () => {
    if (isValidArray(serviceTypeList)) {
      return serviceTypeList.map((type) => (
        <Option name="state_id" key={type.id} value={type.id}>
          {type.name}
        </Option>
      ));
    }
  };

  const PostContractedHours = (data) => {
    const valueData = {
      facility_id: data.facility_id,
      total_beds: data.total_beds,
      current_beds: data.current_beds,
      service_type: data.service_type,
      mon: data.mon || 0,
      tues: data.tues || 0,
      wed: data.wed || 0,
      thur: data.thur || 0,
      fri: data.fri || 0,
      sat: data.sat || 0,
      sun: data.sun || 0,
      floater_mon_fri: data.floater_mon_fri || 0,
      periodical_mon_fri: data.periodical_mon_fri || 0,
      public_holiday: data.public_holiday || 0,
      comments: data.comments,
      valid_from: validDate,
    };
    dispatch(postContractedHours(valueData)).then((record) => {
      if (record.payload.success) {
        toast.success(record.payload.message);
        const valueData = {
          facility_id: facilityId,
          service_type: serviceId,
        };
        dispatch(getContractedHoursInfo(valueData));
        setVisible(false);
      } else {
        toast.success(record.payload.message);
        const valueData = {
          facility_id: facilityId,
          service_type: serviceId,
        };
        dispatch(getContractedHoursInfo(valueData));
        setVisible(false);
      }
    });
  };

  const EditContractedHours = (data) => {
    const valueData = {
      facility_id: defaultValues.facility_id,
      total_beds: data.total_beds,
      current_beds: data.current_beds,
      service_type: defaultValues.service_type,
      mon: data.mon || 0,
      tues: data.tues || 0,
      wed: data.wed || 0,
      thur: data.thur || 0,
      fri: data.fri || 0,
      sat: data.sat || 0,
      sun: data.sun || 0,
      floater_mon_fri: data.floater_mon_fri || 0,
      periodical_mon_fri: data.periodical_mon_fri || 0,
      public_holiday: data.public_holiday || 0,
      comments: data.comments,
      valid_from: moment(data.valid_from).format("DD-MM-YYYY"),
    };
    dispatch(updateContractedHours(rowId, valueData)).then((record) => {
      if (record.payload.success) {
        toast.success(record.payload.message);
        const valueData = {
          facility_id: facilityId,
          service_type: serviceId,
        };
        dispatch(getContractedHoursInfo(valueData));
        setEditVisible(false);
      } else {
        toast.success(record.payload.message);
        const valueData = {
          facility_id: facilityId,
          service_type: serviceId,
        };
        dispatch(getContractedHoursInfo(valueData));
        setEditVisible(false);
      }
    });
  };

  const handelChangeDate = (date, dateString) => {
    setValidDate(dateString);
  };

  const EditFunction = (e) => {
    setRowId(e.id);
    setEditVisible(true);
    setDefaultValue({
      ...e,
      valid_from: moment(e.valid_from),
    });
  };

  const deleteFunction = (elm) => {
    dispatch(deleteContractedHours(elm.id)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        dispatch(getContractedHoursInfo());
      } else {
        toast.error(record.payload.message);
        dispatch(getContractedHoursInfo());
      }
    });
  };

  const cancel = () => {};

  const columnsData = [
    {
      title: "Day",
      children: [
        {
          title: "M",
          dataIndex: "mon",
          key: "mon",
        },
        {
          title: "T",
          dataIndex: "tues",
          key: "tues",
        },
        {
          title: "W",
          dataIndex: "wed",
          key: "wed",
        },
        {
          title: "Th",
          dataIndex: "thur",
          key: "thur",
        },
        {
          title: "F",
          dataIndex: "fri",
          key: "fri",
        },
      ],
    },
    {
      title: "Holiday",
      children: [
        {
          title: "Public Holiday",
          dataIndex: "public_holiday",
          key: "public_holiday",
        },
      ],
    },
    {
      title: "Weekly",
      children: [
        {
          title: "Sa",
          dataIndex: "sat",
          key: "sat",
        },
        {
          title: "Su",
          dataIndex: "sun",
          key: "sun",
        },
        {
          title: "Floter Hours",
          dataIndex: "floater_mon_fri",
          key: "floater_mon_fri",
        },
        {
          title: "Periodical Hours",
          dataIndex: "periodical_mon_fri",
          key: "periodical_mon_fri",
        },
      ],
    },
    {
      title: "Total Bed",
      dataIndex: "total_beds",
      key: "total_beds",
    },
    {
      title: "Current Bed",
      dataIndex: "current_beds",
      key: "current_beds",
    },
    {
      title: "Valid From",
      dataIndex: "valid_from",
      key: "valid_from",
      render: (_, elm) => {
        return moment(elm?.valid_from).format("DD-MM-YYYY");
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (_, elm) => (
        <div>
          <span className="mr-3">
            <Tooltip title="Edit" color="rgb(20, 72, 129)">
              <Button onClick={() => EditFunction(elm)}>
                <EditOutlined />
              </Button>
            </Tooltip>
          </span>
          <span className="mr-3">
            <Popconfirm
              title="Are you sure to delete this data?"
              onConfirm={() => deleteFunction(elm)}
              onCancel={() => cancel}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete" color="rgb(20, 72, 129)">
                <Button>
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          </span>
        </div>
      ),
    },
  ];

 
  return (
    <>
      <Form>
        <Row gutter={24}>
          <Col span={5}></Col>
          <Col span={5}>
            <Form.Item>
              <div>
                <Select
                  onChange={handleOnChangeGroupView}
                  placeholder="Select Group"
                >
                  {getOrganisationListDetails()}
                </Select>
              </div>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="demo2">
              <div>
                <Select
                  value={facilityIdData}
                  onChange={handleOnChangeFacility}
                  placeholder="Select Facility"
                >
                  {getFacilityListDetails()}
                </Select>
              </div>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="demo2">
              <div>
                <Select
                  value={serviceTypeId}
                  onChange={handleServiceType}
                  placeholder="Select Service Type"
                >
                  {getServiceTypeListDetails()}
                </Select>
              </div>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Button
              className="style-btn ml-2"
              onClick={() => setVisible(true)}
              style={{ float: "right", marginBottom: "20px" }}
              icon={<PlusCircleOutlined />}
              shape="round"
              type="primary"
            >
              Add Contracted Hours
            </Button>
          </Col>
        </Row>

        <Table
          columns={columnsData}
          loading={loading}
          dataSource={contractedHours}
          rowKey="id"
        />
        <Modal
          title="Add Contracted Hours"
          visible={visible}
          footer={null}
          width={1300}
          centered
          onCancel={() => setVisible(false)}
        >
          <Form
            layout="vertical"
            onFinish={PostContractedHours}
            className=""
            form={form}
          >
            <Row gutter={[16, 16]} className="justify-content-center m-0">
              <Col md={8} xs={16}>
                <Form.Item
                  name="org_id"
                  label="Group"
                  rules={[{ required: true, message: "Please Select Group." }]}
                >
                  <Select onChange={handleOnChangeGroup} placeholder="Group">
                    {getOrganisationListDetails()}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={8} xs={16}>
                <Form.Item
                  name="facility_id"
                  label="Facility"
                  rules={[
                    { required: true, message: "Please Select Facility." },
                  ]}
                >
                  <Select
                    onChange={handleOnChangeAddFacility}
                    placeholder="Facility"
                  >
                    {getFacilityListDetails()}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={8} xs={16}>
                <Form.Item
                  rules={[
                    { required: true, message: "Please Select Service type." },
                  ]}
                  name="service_type"
                  label="Service Type"
                >
                  <Select placeholder="Service Type">
                    {getServiceTypeListDetails()}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left" className="mb-0">
              Hours
            </Divider>
            <Card>
              <Row gutter={[16, 16]}>
                <Col md={11} xs={16}>
                  <Divider>Daily</Divider>
                  <Card>
                    <Row className="justify-content-center">
                      <Col>
                        <Form.Item className="mr-1" name="mon" label="Monday">
                          <InputNumber
                            defaultValue={0}
                            name="mon"
                            placeholder="Monday"
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item className="mr-1" name="tues" label="Tuesday">
                          <InputNumber
                            defaultValue={0}
                            name="tues"
                            placeholder="Tuesday"
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          className="mr-1"
                          name="wed"
                          label="Wednesday"
                        >
                          <InputNumber
                            defaultValue={0}
                            name="wed"
                            placeholder="Wednesday"
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          className="mr-1"
                          name="thur"
                          label="Thursday"
                        >
                          <InputNumber
                            defaultValue={0}
                            name="thur"
                            placeholder="Thursday"
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item className="mr-1" name="fri" label="Friday">
                          <InputNumber
                            defaultValue={0}
                            name="fri"
                            placeholder="Friday"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col md={4} xs={16}>
                  <Divider>Holiday</Divider>
                  <Card>
                    <Row className="justify-content-center">
                      <Col>
                        <Form.Item name="public_holiday" label="Public Holiday">
                          <InputNumber
                            name="public_holiday"
                            defaultValue={0}
                            placeholder="Public Holiday"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>

                <Col md={9} xs={16}>
                  <Divider>Weekly</Divider>
                  <Card>
                    <Row className="justify-content-center">
                      <Col>
                        <Form.Item className="mr-1" name="sat" label="Saturday">
                          <InputNumber
                            defaultValue={0}
                            name="sat"
                            placeholder="Saturday"
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item className="mr-1" name="sun" label="Sunday">
                          <InputNumber
                            defaultValue={0}
                            name="sun"
                            placeholder="Sunday"
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          className="mr-1"
                          name="floater_mon_fri"
                          label="Floter Hours"
                        >
                          <InputNumber
                            name="floater_mon_fri"
                            defaultValue={0}
                            placeholder="Floter Hours"
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          className="mr-1"
                          name="periodical_mon_fri"
                          label="Periodical Hours"
                        >
                          <InputNumber
                            defaultValue={0}
                            name="periodical_mon_fri"
                            placeholder="Periodical Hours"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Card>

            <Row gutter={[16, 16]}>
              <Col md={8} xs={16}>
                <Form.Item
                  name="valid_from"
                  label="Valid From"
                  rules={[{ required: true, message: "Please Select Date." }]}
                >
                  <DatePicker
                    format={"DD-MM-YYYY"}
                    onChange={handelChangeDate}
                    style={{ width: "405px" }}
                  />
                </Form.Item>
              </Col>
              <Col md={8} xs={16}>
                <Form.Item
                  name="total_beds"
                  label="Total Bed"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Number of Total Bed.",
                    },
                  ]}
                  
                >
                  <InputNumber
                    style={{ width: "405px" }}
                    placeholder="Number of Total Bed"
                  />
                </Form.Item>
              </Col>
              <Col md={8} xs={16}>
                <Form.Item
                  name="current_beds"
                  label="Current Bed"
                  dependencies={['total_beds']}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Number of Current Bed.",
                    },
                
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('total_beds') >= value) {
                          return Promise.resolve();
                        }
          
                        return Promise.reject(new Error("Number of Current Bed should be Less then of Total Bed."));
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    style={{ width: "405px" }}
                    placeholder="Number of Current Bed"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col md={24} xs={16}>
                <Form.Item name="comments" label="Comment">
                  <TextArea placeholder="Comment" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} className="justify-content-center mt-3 m-0">
              <Col md={24} style={{ textAlign: "center" }}>
                <Button className="style-btn" htmlType="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          title="Edit Contracted Hours"
          visible={editVisible}
          footer={null}
          width={1300}
          centered
          onCancel={() => setEditVisible(false)}
        >
          <Form
            initialValues={defaultValues}
            layout="vertical"
            onFinish={EditContractedHours}
            className=""
            form={form2}
          >
            <Row gutter={[16, 16]} className="justify-content-center m-0">
              <Col md={8} xs={16}>
                <Form.Item
                  name="org_id"
                  label="Group"
                  rules={[{ required: true, message: "Please Select Group." }]}
                >
                  <Select
                    disabled
                    onChange={handleOnChangeGroup}
                    placeholder="Group"
                  >
                    {getOrganisationListDetails()}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={8} xs={16}>
                <Form.Item
                  name="facility_name"
                  label="Facility"
                  rules={[
                    { required: true, message: "Please Select Facility." },
                  ]}
                >
                  <Select
                    disabled
                    onChange={handleOnChangeFacility}
                    placeholder="Facility"
                  >
                    {getFacilityListDetails()}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={8} xs={16}>
                <Form.Item
                  rules={[
                    { required: true, message: "Please Select Service type." },
                  ]}
                  name="service_type_name"
                  label="Service Type"
                >
                  <Select disabled placeholder="Service Type">
                    {getServiceTypeListDetails()}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left" className="mb-0">
              Hours
            </Divider>
            <Card>
              <Row gutter={[16, 16]}>
                <Col md={11} xs={16}>
                  <Divider>Daily</Divider>
                  <Card>
                    <Row className="justify-content-center">
                      <Col>
                        <Form.Item className="mr-1" name="mon" label="Monday">
                          <InputNumber name="mon" placeholder="Monday" />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item className="mr-1" name="tues" label="Tuesday">
                          <InputNumber name="tues" placeholder="Tuesday" />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          className="mr-1"
                          name="wed"
                          label="Wednesday"
                        >
                          <InputNumber name="wed" placeholder="Wednesday" />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          className="mr-1"
                          name="thur"
                          label="Thursday"
                        >
                          <InputNumber name="thur" placeholder="Thursday" />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item className="mr-1" name="fri" label="Friday">
                          <InputNumber name="fri" placeholder="Friday" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col md={4} xs={16}>
                  <Divider>Holiday</Divider>
                  <Card>
                    <Row className="justify-content-center">
                      <Col>
                        <Form.Item name="public_holiday" label="Public Holiday">
                          <InputNumber
                            name="public_holiday"
                            placeholder="Public Holiday"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>

                <Col md={9} xs={16}>
                  <Divider>Weekly</Divider>
                  <Card>
                    <Row className="justify-content-center">
                      <Col>
                        <Form.Item className="mr-1" name="sat" label="Saturday">
                          <InputNumber name="sat" placeholder="Saturday" />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item className="mr-1" name="sun" label="Sunday">
                          <InputNumber name="sun" placeholder="Sunday" />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          className="mr-1"
                          name="floater_mon_fri"
                          label="Floter Hours"
                        >
                          <InputNumber
                            name="floater_mon_fri"
                            placeholder="Floter Hours"
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          className="mr-1"
                          name="periodical_mon_fri"
                          label="Periodical Hours"
                        >
                          <InputNumber
                            name="periodical_mon_fri"
                            placeholder="Periodical Hours"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Card>

            <Row gutter={[16, 16]}>
              <Col md={8} xs={16}>
                <Form.Item
                  name="valid_from"
                  label="Valid From"
                  rules={[{ required: true, message: "Please Select Date." }]}
                >
                  <DatePicker
                    disabled
                    format={"DD-MM-YYYY"}
                    onChange={handelChangeDate}
                    style={{ width: "405px" }}
                  />
                </Form.Item>
              </Col>
              <Col md={8} xs={16}>
                <Form.Item
                name="total_beds"
                label="Total Bed"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Number of Total Bed.",
                  },
                ]}
                >
                  <InputNumber
                    style={{ width: "405px" }}
                    placeholder="Number of Total Bed"
                  />
                </Form.Item>
              </Col>
              <Col md={8} xs={16}>
                <Form.Item
                  name="current_beds"
                  label="Current Bed"
                  name="current_beds"
                  label="Current Bed"
                  dependencies={['total_beds']}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Number of Current Bed.",
                    },
                
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('total_beds') >= value) {
                          return Promise.resolve();
                        }
          
                        return Promise.reject(new Error("Number of Current Bed should be Less then of Total Bed."));
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    style={{ width: "405px" }}
                    placeholder="Number of Current Bed"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col md={24} xs={16}>
                <Form.Item name="comments" label="Comment">
                  <TextArea placeholder="Comment" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} className="justify-content-center mt-3 m-0">
              <Col md={24} style={{ textAlign: "center" }}>
                <Button className="style-btn" htmlType="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Form>
    </>
  );
};

export default memo(ContractedHours);
