import {
    DELETE_CONTRACTOR_SUCCESS,
    DELETE_CONTRACTOR_FAILURE,
  } from "../constants/MyM";
  import { deleteApi } from "../../utils/apiCall";
  
  const deleteContractorSuccess = (data) => {
    return {
      type: DELETE_CONTRACTOR_SUCCESS,
      payload: data,
    };
  };
  
  const deleteContractorFailure = (data) => {
    return {
      type: DELETE_CONTRACTOR_FAILURE,
      payload: data,
    };
  };
  
  export const deleteContractor = (id) => {
    return async (dispatch, getState) => {
      const { error, result } = await deleteApi(`/contractor_contact_details/${id}`);
      if (!error) {
        return dispatch(deleteContractorSuccess(result));
      }
      return dispatch(deleteContractorFailure(result));
    };
  };
  