import { DELETE_GROUP_SUCCESS, DELETE_GROUP_FAILURE } from "../constants/MyM";
import { deleteApi } from "../../utils/apiCall";

const deleteGroupSuccess = (data) => {
  return {
    type: DELETE_GROUP_SUCCESS,
    payload: data,
  };
};

const deleteGroupFailure = (data) => {
  return {
    type: DELETE_GROUP_FAILURE,
    payload: data,
  };
};

export const deleteGroup = (id) => {
  return async (dispatch, getState) => {
    const { error, result } = await deleteApi(`/delete_org_by_id/${id}`);
    if (!error) {
      return dispatch(deleteGroupSuccess(result));
    }
    return dispatch(deleteGroupFailure(result));
  };
};
