import { POST_MEETING_MINUTES_SUCCESS, POST_MEETING_MINUTES_FAILURE } from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const postMeetingMinutesSuccess = (data) => {
  return {
    type: POST_MEETING_MINUTES_SUCCESS,
    payload: data,
  };
};

const postMeetingMinutesFailure = (data) => {
  return {
    type: POST_MEETING_MINUTES_FAILURE,
    payload: data,
  };
};

export const postMeetingMinutes = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await postApi(`/add_meeting_minutes`, {
      ...data,
    });
    if (!error) {
      return dispatch(postMeetingMinutesSuccess(result));
    }
    return dispatch(postMeetingMinutesFailure(result));
  };
};
