import { GET_HOLIDAY_LIST_SUCCESS, GET_HOLIDAY_LIST_FAILURE } from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const getHolidayListSuccess = (data) => {
  return {
    type: GET_HOLIDAY_LIST_SUCCESS,
    payload: data,
  };
};

const getHolidayListFailure = (data) => {
  return {
    type: GET_HOLIDAY_LIST_FAILURE,
    payload: data,
  };
};

export const getHolidayList = () => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/get_holiday_list`);
    if (!error) {
      return dispatch(getHolidayListSuccess(result));
    }
    return dispatch(getHolidayListFailure(result));
  };
};
