import { POST_ADHOC_FACILITY_SUCCESS, POST_ADHOC_FACILITY_FAILURE } from "redux/constants/MyM";
import { postApi } from "utils/apiCall";

const postAdhocFacilitySuccess = (data) => {
    return {
        type: POST_ADHOC_FACILITY_SUCCESS,
        payload: data
    }
}

const postAdhocFacilityFailure = (data) => {
    return {
        type: POST_ADHOC_FACILITY_FAILURE,
        payload: data
    }
}

export const postAdhocFacility = (data) => {
    return async (dispatch, getState) => {
      const { error, result } = await postApi(`/add_adhoc_facility`, {
        ...data,
      });
      if (!error) {
        return dispatch(postAdhocFacilitySuccess(result));
      }
      return dispatch(postAdhocFacilityFailure(result));
    };
  };
  