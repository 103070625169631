import { env } from "./EnvironmentConfig";

export const APP_NAME = "Majestic";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const APP_MENU_PATH = "/menu";
export const AUTH_PREFIX_PATH = "/auth";

export const THEME_CONFIG = {
  navType: "TOP",
  sideNavTheme: "SIDE_NAV_LIGHT",
  navCollapsed: false,
  topNavColor: "#144881",
  headerNavColor: "",
  locale: "en",
  currentTheme: "light",
};
