import React, { memo } from "react";
import { Card, Tabs } from "antd";
import { StickyContainer, Sticky } from "react-sticky";
import TimesheetList from "./timesheetList";
import ExtraHours from "./ExtraHours"

const { TabPane } = Tabs;

const Timesheet = () => {
  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar
          {...props}
          style={{ ...style, zIndex: 1, background: "#fff" }}
        />
      )}
    </Sticky>
  );

  return (
    <Card>
      <StickyContainer>
        <Tabs
          defaultActiveKey="1"
          renderTabBar={renderTabBar}
        >
          <TabPane tab="Upload Timesheet" key="1">
            <TimesheetList />
          </TabPane>
          <TabPane tab="Extra Hours" key="2">
            <ExtraHours />
          </TabPane>
          
        </Tabs>
      </StickyContainer>
    </Card>
  );
};

export default memo(Timesheet);
