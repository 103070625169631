import {
  POST_NEW_MANAGER_SUCCESS,
  POST_NEW_MANAGER_FAILURE,
} from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const postNewManagerSuccess = (data) => {
  return {
    type: POST_NEW_MANAGER_SUCCESS,
    payload: data,
  };
};

const postNewManagerFailure = (data) => {
  return {
    type: POST_NEW_MANAGER_FAILURE,
    payload: data,
  };
};

export const postNewManager = (data) => {
  return async (dispatch, getState) => {
    const { error, result } = await postApi(`/manager_contact_details`, {
      ...data,
    });
    if (!error) {
      return dispatch(postNewManagerSuccess(result));
    }
    return dispatch(postNewManagerFailure(result));
  };
};
