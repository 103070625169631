import { POST_SURVEY_UPLOAD_SUCCESS, POST_SURVEY_UPLOAD_FAILURE } from "../constants/MyM";

const initStat = { data: [], error: false, errorData: "", isLoading: true };

export default (state = initStat, action) => {
    switch (action.type) {
        case POST_SURVEY_UPLOAD_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            };
        case POST_SURVEY_UPLOAD_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorData: action.payload,
                error: true,
            };
        default:
            return { ...state };
    }
};