import {
  DashboardOutlined,
  BookOutlined,
  FormOutlined,
  FilePdfOutlined,
  ContactsOutlined,
  ScheduleOutlined,
  FileDoneOutlined,
  SolutionOutlined,
  FileTextOutlined,
  TeamOutlined,
  UserOutlined,
  SettingOutlined,
  IdcardOutlined 
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "sidenav.dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
    type: ["A"],
  },
];
const ContactDetails = [
  {
    key: "contactdetails",
    path: `${APP_PREFIX_PATH}/contact_detail`,
    // title: "sidenav.manageLicence",
    title: "Contact Details",
    icon: ContactsOutlined,
    breadcrumb: false,
    type: ["A"],
    submenu: [],
  },
];
const BusinessInsurance = [
  {
    key: "businessinsurance",
    path: `${APP_PREFIX_PATH}/business_insurance`,
    title: "Business Insurance",
    icon: FileDoneOutlined,
    breadcrumb: false,
    type: ["A"],
    submenu: [],
  },
];
const Certificates = [
  {
    key: "certificates",
    path: `${APP_PREFIX_PATH}/certificates`,
    title: "Certificates",
    icon: FilePdfOutlined,
    breadcrumb: false,
    type: ["A"],
    submenu: [],
  },
];
const Statements = [
  {
    key: "statements",
    path: `${APP_PREFIX_PATH}/statements`,
    title: "Statements",
    icon: SolutionOutlined,
    breadcrumb: false,
    submenu: [],
    type: "A",
  },
];

const survey = [
  {
    key: "survey",
    path: `${APP_PREFIX_PATH}/survey`,
    title: "Survey",
    icon: ScheduleOutlined,
    breadcrumb: false,
    submenu: [],
    type: "A",
  },
];
const meetingMinutes = [
  {
    key: "meetingMinutes",
    path: `${APP_PREFIX_PATH}/meeting-minutes`,
    title: "Meeting Minutes",
    icon: TeamOutlined,
    breadcrumb: false,
    submenu: [],
    type: "A",
  },
];

const setting = [
  {
    key: "setting",
    path: `${APP_PREFIX_PATH}/setting`,
    title: "Setting",
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: [],
    type: "A",
  },
];


const vehicle = [
  {
    key: "vehicle",
    path: `${APP_PREFIX_PATH}/vehicle`,
    title: "Vehicle",
    icon: FormOutlined,
    breadcrumb: false,
    submenu: [],
    type: "A",
  },
];

const staff = [
  {
    key: "staff",
    path: `${APP_PREFIX_PATH}/staff`,
    title: "Staff",
    icon: FormOutlined,
    breadcrumb: false,
    submenu: [],
    type: "H",
  },
];

const userManagement = [
  {
    key: "usermanagement",
    path: `${APP_PREFIX_PATH}/user-management`,
    title: "User Management",
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [],
    type: "U",
  },
];
const Kpireport = [
  {
    key: "kpireport",
    path: `${APP_PREFIX_PATH}/kpi_report`,
    title: "KPI",
    icon: FileTextOutlined,
    breadcrumb: false,
    submenu: [],
    type: "A",
  },
];

const facilityPortal = [
  {
    key: "facilityportal",
    path: `${APP_PREFIX_PATH}/facility-management`,
    title: "Facility Management",
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [],
    type: "F",
  },
];
const Educationreport = [
  {
    key: "educationreport",
    path: `${APP_PREFIX_PATH}/education-report`,
    title: "Report",
    icon: FileTextOutlined,
    breadcrumb: false,
    submenu: [],
    type: "A",
  },
];
const timesheetNavTree = [
  {
    key: "timesheet",
    path: `${APP_PREFIX_PATH}/timesheet`,
    title: "Timesheet",
    icon: BookOutlined,
    breadcrumb: false,
    submenu: [],
    type: ["T"],
  },
];
const communicationNavTree = [
  {
    key: "communication-management",
    path: `${APP_PREFIX_PATH}/communication-management`,
    title: "Communication Management",
    icon: IdcardOutlined,
    breadcrumb: false,
    submenu: [],
    type: ["C"],
  },
];

const navigationConfig = [
  ...dashBoardNavTree,
  ...ContactDetails,
  ...BusinessInsurance,
  ...Certificates,
  ...Statements,
  // ...survey,
  ...vehicle,
  ...staff,
  ...userManagement,
  // ...Kpireport,
  ...facilityPortal,
  // ...Educationreport,
  ...meetingMinutes,
  ...timesheetNavTree,
  ...setting,
  ...communicationNavTree,
  // ...licenceNavTree,
  // ...reportsNavTree,
];

export default navigationConfig;
