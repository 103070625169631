import React, { useEffect, memo, useCallback } from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  MehOutlined,
  FormOutlined,
  LogoutOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import Icon from "components/util-components/Icon";

import * as moment from "moment";
import { Link } from "react-router-dom";

const menuItem = [

  {
    title: "Sign Out",
    icon: LogoutOutlined,
    path: "/auth/login",
  },
];

export const NavProfile = (props) => {
  const getCurrentTime = () => {
    return moment().format("dddd, DD MMMM YYYY");
  };

  const profileImg = "/img/avatars/thumb-1.jpg";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <Link to={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </Link>
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src="/img/avatars/icon.jpg" />
        </Menu.Item>
        <Menu.Item className="pl-0">
          <div className="mt-3" style={{ lineHeight: 1.3 }}>
            <small className="mb-0" style={{ fontSize: "inherit",color:"white" }}>
              {getCurrentTime()}
            </small>
            <h4 className="mb-0">
              <span className="font-weight-normal" style={{ color:"white" }}>Welcome,</span>{" "}
              <b style={{ color:"white" }}>{localStorage.getItem("first_name")}</b>
            </h4>
          </div>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default memo(NavProfile);
