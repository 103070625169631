import React, { useEffect, useState, useRef, Fragment } from "react";
import { Table, Form, Button, Input, Space, Tooltip, Modal, Steps, Row, Col, Radio, Checkbox, message as AntMessage } from "antd";
import { getApiArrayDetails, isValidArray, sendNotification } from "../../../utils/MyMUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  SearchOutlined,
  UserAddOutlined,
  UserOutlined,
  LoginOutlined,
  ShopOutlined,
  PlusCircleOutlined
} from "@ant-design/icons";
import { getStaffUserManagement } from "redux/actions/getStaffUserManagement.action";
import Step1 from "./Step1";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { toast } from "react-toastify";
import { postNewStaff } from "redux/actions/postNewStaff.action";
import { addNewStaff } from "redux/actions/addNewStaff.action";

const { Step } = Steps;

const NewStaff = () => {
  let searchInput = useRef(null);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [title, setTitle] = useState();
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const [able, setAble] = useState([]);
  const [info, setInfo] = useState([]);
  const [newStaffModal, setNewStaffModal] = useState(false);
  const [loadingOnModalOk, setLoadingOnModalOk] = useState(false)
  const [postData, setpostData] = useState({
    role: "",
    facility: {
      facility_id: "",
      modules: [],
    },
  });

  const [newStaffForm] = Form.useForm()

  const checkBoxRef = useRef()

  const getStaffUserManagementList = useSelector((state) =>
    getApiArrayDetails(state.getStaffUserManagement.data)
  );

  const postNewStaffDetails = useSelector((state) =>
    getApiArrayDetails(state.postNewStaffReducer.data)
  );

  useEffect(() => {
    setTimeout(() => {
      dispatch(getStaffUserManagement());
    }, 1000);
  }, []);

  useEffect(() => {
    newStaffForm.resetFields()
  }, [newStaffModal])

  useEffect(() => {
    setCurrentStep(currentStep);
  }, [currentStep]);

  const showNewModal = (elm) => {
    setCurrentStep(0);
    setpostData({
      role: "",
      facility: {
        facility_id: "",
        modules: [],
      },
    });
    setIsModalVisible(true);
    setTitle(elm.user_name);
  };

  const SelectRole = (id) => {
    setpostData({ ...postData, role: id });
  };

  const SelectFacility = (FacilityAndModuleArray) => {
    setpostData({ ...postData, facility: FacilityAndModuleArray });
  };

  const getCurrentComponent = (current) => {
    switch (current) {
      case 1:
        return (
          <Step3
            prev={prev}
            next={next}
            selectFacility={SelectFacility}
            passData={postData}
            setAble={setAble}
            able={able}
            setInfo={setInfo}
            infor={info}
          />
        );
      case 2:
        return (
          <Step4 prev={prev} passData={postData} done={done} loader={loader} />
        );
      case 0:
      default:
        return (
          <Step1 next={next} selectRole={SelectRole} passData={postData} />
        );
    }
  };

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: "#1a3353" ? "#1a3353" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleFormSubmitForNewStaff = () => {

    newStaffForm.validateFields().then((values) => {

      setLoadingOnModalOk(true)

      let body = {
        ...values,
        is_agency_staff: checkBoxRef.current.input.checked ? 1 : 0
      }

      dispatch(addNewStaff(body)).then(({payload}) => {
        const { success, message } = payload;
        if(success){
          dispatch(getStaffUserManagement());
          setNewStaffModal(false)
          sendNotification({notificationType: 'success', message})
        } else {
          sendNotification({notificationType: 'error', message})
        }
      }).finally(() => {
        setLoadingOnModalOk(false)
      })
      
    })
  }

  const done = (values) => {
    setLoader(true);
    const details = {
      first_name: title?.split(" ").slice(0, -1).join(" "),
      last_name: title?.split(" ").slice(-1).join(" "),
      user_name: values?.username,
      password: values?.password,
      role_id: postData?.role,
      facility: postData?.facility,
    };
    dispatch(postNewStaff(details)).then((record) => {
      if (record?.payload?.success === true) {
        toast.success(record?.payload?.message);
        dispatch(getStaffUserManagement());
        setIsModalVisible(false);
        setLoader(false);
        window.location.reload();
      } else {
        toast.error(record?.payload?.message);
        dispatch(getStaffUserManagement());
        setLoader(false);
        setIsModalVisible(false);
        window.location.reload();
      }
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "user_name",
      key: "user_name",
      width: "50%",
      ...getColumnSearchProps("user_name"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "50%",
      render: (_, elm, row) => (
        <div>
          <span className="mr-3">
            <Tooltip title="Allocate" color="rgb(20, 72, 129)">
              <Button
                shape="round"
                icon={<UserAddOutlined />}
                onClick={() => showNewModal(elm)}
              ></Button>
            </Tooltip>
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <Button
            className="style-btn"
            onClick={() => setNewStaffModal(true)}
            style={{ float: "right", marginBottom: "20px" }}
            icon={<PlusCircleOutlined />}
            shape="round"
            size="default"
        >
            Add New Staff
        </Button>
    </div>
      <Form layout="horizontal">
        <Table
          // loading={!isValidArray(getStaffUserManagementList)}
          columns={columns}
          dataSource={getStaffUserManagementList}
          rowKey="id"
        />
      </Form>
      <Modal
        title={title}
        centered
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        width={1000}
      >
        <React.Fragment>
          <Steps current={currentStep} className="steps" responsive="532px">
            <Step title="Role" icon={<UserOutlined />} />
            {/* <Step title="Module" icon={<InsertRowBelowOutlined />} /> */}
            <Step title="Facility" icon={<ShopOutlined />} />
            <Step title="Login" icon={<LoginOutlined />} />
          </Steps>
          {getCurrentComponent(currentStep)}
        </React.Fragment>
      </Modal>

      {newStaffModal && (
          <Fragment>
              <Modal confirmLoading={loadingOnModalOk} title='Add New Staff' visible={true} onCancel={() => setNewStaffModal(false)} onOk={handleFormSubmitForNewStaff} >
                  <Form form={newStaffForm} layout="vertical">
                    <Row gutter={16}>
                      <Col lg={12} md={12} sm={24} xs={24}>
                        <Form.Item name='first_name' label="First Name" rules={[{required: true, message: 'First name is required'}]}>
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col lg={12} md={12} sm={24} xs={24}>
                        <Form.Item name='last_name' label="Last Name" rules={[{required: true, message: 'Last name is required'}]}>
                          <Input />
                        </Form.Item>
                      </Col>
                      
                      <Col lg={12} md={12} sm={24} xs={24}>
                        <Form.Item name='mobile' label="Mobile No." rules={[{required: true, message: 'Mobile number is required'}]}>
                          <Input type="number" />
                        </Form.Item>
                      </Col>
                      <Col lg={12} md={12} sm={24} xs={24}>
                        <Form.Item name='is_agency_staff' label="Is Agency Staff">
                          <Checkbox ref={checkBoxRef} value={1} >Is Agency Staff</Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
              </Modal>
          </Fragment>
      )}
    </>
  );
};

export default NewStaff;
