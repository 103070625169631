import React, {
  memo,
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import {
  Table,
  Form,
  Button,
  Space,
  Row,
  Col,
  Select,
  Input,
  Modal,
  InputNumber,
  Popconfirm,
  DatePicker
} from "antd";
import {
  PlusCircleOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import "react-week-picker/src/lib/calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { getApiArrayDetails, isValidArray } from "../../../utils/MyMUtils";
import { getExtraHoursCategoryInfo } from "redux/actions/getExtraHoursCategory.action";
import { getFacilityInfo } from "redux/actions/getAllFacilityList.action";
import { getServiceType } from "redux/actions/getServiceType.action";
import moment from "moment";

const { Option } = Select;
const AnotherFormat = "DD-MM-YYYY";

moment.locale("en", {
  week: {
    dow: 1, /// Date offset
  },
});

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const ExtraHoursTable = () => {
  const dispatch = useDispatch();
  let searchInput = useRef(null);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [productLst, setProductLst] = useState([]);
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [product, setProduct] = useState([
    {
      key: Math.random(4),
      category_id: "",
      service_type: "",
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
      sun: 0,
    },
  ]);
  const tempProd = {
    key: Math.random(4),
    category_id: "",
    service_type: "",
    mon: 0,
    tue: 0,
    wed: 0,
    thu: 0,
    fri: 0,
    sat: 0,
    sun: 0,
  };

  const ExtraHoursCategory = useSelector((state) =>
    getApiArrayDetails(state.getExtraHoursCategoryList.data)
  );

  const facilityData = useSelector((state) =>
    getApiArrayDetails(state.getFacilityList.data)
  );

  const serviceTypeList = useSelector((state) =>
    getApiArrayDetails(state.ServiceType.data)
  );

  const getCategoryList = () => {
    if (isValidArray(ExtraHoursCategory)) {
      return ExtraHoursCategory.map((category) => (
        <Option name="state_id" key={category.id} value={category.id}>
          {category.name}
        </Option>
      ));
    }
  };

  const getFacilityListDetails = () => {
    if (isValidArray(facilityData)) {
      return facilityData.map((facilityData) => (
        <Option
          name="facility_id"
          key={facilityData.facility_id}
          value={facilityData.facility_id}
        >
          {facilityData.facility_name}
        </Option>
      ));
    }
  };

  const getServiceTypeListDetails = () => {
    if (isValidArray(serviceTypeList)) {
      return serviceTypeList.map((type) => (
        <Option name="state_id" key={type.id} value={type.id}>
          {type.name}
        </Option>
      ));
    }
  };

  const onChangeFacility = (value) => {
    dispatch(getServiceType(value));
  };

  console.log(product, "product");

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  useEffect(() => {
    //get extra hours category
    dispatch(getExtraHoursCategoryInfo());

    //get all facility
    dispatch(getFacilityInfo());
  }, []);

  //-----Data table search functionality start-------
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: "#1a3353" ? "#1a3353" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => text,
  });
  //-----Data table search functionality end-------

  //----view data in data table start----//
  const column = [
    {
      title: "Facility Name",
      dataIndex: "facility_name",
      key: "FacilityName",
      width: "20%",
      ...getColumnSearchProps("facility_name"),
    },
    {
      title: "Hours",
      dataIndex: "hours",
      key: "hours",
      width: "10%",
      ...getColumnSearchProps("hours"),
    },
    {
      title: "date",
      dataIndex: "date",
      key: "date",
      width: "10%",
    },
    {
      title: "Comments",
      dataIndex: "notes",
      key: "notes",
      width: "30%",
    },
  ];
  //----view data in data table end----//

  const PostData = useCallback((data) => {
    console.log(StartDate,EndDate, "..........");
  });

  //-----week picker start------//
  const weekPicker = (value) => {
    setLoading(true);
    const start_date = moment(value).startOf("week").format(AnotherFormat);
    const end_date = moment(value).endOf("week").format(AnotherFormat);
    setStartDate(start_date);
    setEndDate(end_date);
  };

  const customWeekStartEndFormat = (value) => {
    return `${moment(value).startOf("week").format("DD-MM-YYYY")} ~ ${moment(
      value
    )
      .endOf("week")
      .format("DD-MM-YYYY")}`;
  };
  //-----week picker end------//


  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const defaultColumns = [
    {
      title: "Category",
      dataIndex: "category_id",
      width: "10%",
      render: (_, index, record) => {
        return (
          <Select
            optionFilterProp="children"
            defaultActiveFirstOption={true}
            placeholder={"Select Category"}
            style={{ minWidth: "90%", height: "32px" }}
            // onChange={(e) => handleProduct(e, index, record)}
          >
            {getCategoryList()}
          </Select>
        );
      },
    },
    {
      title: "Service Type",
      dataIndex: "service_type",
      width: "10%",

      render: (_, index, record) => {
        return (
          <Select
            optionFilterProp="children"
            defaultActiveFirstOption={true}
            placeholder={"Select Service Type"}
            style={{ minWidth: "90%", height: "32px" }}
            // onChange={(e) => handleProduct(e, index, record)}
          >
            {getServiceTypeListDetails()}
          </Select>
        );
      },
    },
    {
      title: "Note",
      dataIndex: "note",
      width: "10%",
      editable: true,
    },

    {
      title: "M",
      dataIndex: "mon",
      width: "3%",
      editable: true,
    },

    {
      title: "T",
      dataIndex: "tue",
      width: "3%",
      editable: true,
    },

    {
      title: "W",
      dataIndex: "wed",
      width: "3%",
      editable: true,
    },

    {
      title: "T",
      dataIndex: "thu",
      width: "3%",
      editable: true,
    },

    {
      title: "F",
      dataIndex: "fri",
      width: "3%",
      editable: true,
    },

    {
      title: "S",
      dataIndex: "sat",
      width: "3%",
      editable: true,
    },

    {
      title: "S",
      dataIndex: "sun",
      width: "3%",
      editable: true,
    },

    {
      title: "Action",
      dataIndex: "action",
      width: "20%",
      render: (_, record) => (
        <div>
          <span className="mr-3">
            <Button
              onClick={handleAdd}
              type="primary"
              icon={<PlusCircleOutlined />}
              size="small"
            />
          </span>
          <span className="mr-3">
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <Button type="danger" icon={<DeleteOutlined />} size="small" />
            </Popconfirm>
          </span>
        </div>
      ),
    },
  ];

  const handleDelete = (record) => {
    const newData = product.filter((item) => item.key !== record.key);
    setProduct(newData);
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const handleSave = (row) => {
    const newData = [...product];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setProduct(newData);
  };

  const handleAdd = () => {
    if (!product.length) {
      setProduct([tempProd]);
    } else {
      setProduct([...product, tempProd]);
    }
  };

  return (
    <Form layout="horizontal">
      <Row style={{ float: "right", marginBottom: "15px" }}>
        <div style={{ marginLeft: "10px" }}>
          <Button
            className="style-btn"
            onClick={() => setVisible(true)}
            style={{ float: "right", marginBottom: "20px" }}
            icon={<PlusCircleOutlined />}
            shape="round"
            size="default"
          >
            Add Extra Hours
          </Button>
        </div>
      </Row>
      <Table loading={loading}  columns={column} rowKey="id" />

      <Modal
        title="Add Extra Hours"
        centered
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        width={1250}
      >
        <Form onFinish={PostData} layout="vertical" form={form}>
          <Row gutter={24} className="justify-content-center">
            <Col md={12} >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please Select Facility.",
                  },
                ]}
                name="facility_id"
                label="Select Facility"
              >
                <Select onChange={onChangeFacility}>
                  {getFacilityListDetails()}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} >
            <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please Select Week.",
                  },
                ]}
                name="first_date"
                label="Select Week"
              >
                 <DatePicker
                onChange={weekPicker}
                format={customWeekStartEndFormat}
                picker="week"
              />
              </Form.Item>
            
            </Col>
          </Row>

          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Table
                scroll={{ x: true }}
                components={components}
                rowClassName={() => "editable-row"}
                dataSource={product}
                columns={columns}
              />
            </Col>
          </Row>

          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button htmlType="submit" loading={loader}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Form>
  );
};

export default memo(ExtraHoursTable);
