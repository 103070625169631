import { DELETE_USER_SUCCESS, DELETE_USER_FAILURE } from "../constants/MyM";
import { deleteApi } from "../../utils/apiCall";

const deleteUserSuccess = (data) => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: data,
  };
};

const deleteUserFailure = (data) => {
  return {
    type: DELETE_USER_FAILURE,
    payload: data,
  };
};

export const deleteUser = (id) => {
  return async (dispatch, getState) => {
    const { error, result } = await deleteApi(`/delete_org_user_details/${id}`);
    if (!error) {
      return dispatch(deleteUserSuccess(result));
    }
    return dispatch(deleteUserFailure(result));
  };
};
