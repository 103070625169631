import { GET_MANAGER_SUCCESS, GET_MANAGER_FAILURE } from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const getManagerListSuccess = (data) => {
  return {
    type: GET_MANAGER_SUCCESS,
    payload: data,
  };
};

const getManagerListFailure = (data) => {
  return {
    type: GET_MANAGER_FAILURE,
    payload: data,
  };
};

export const getManagerList = () => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`/manager_details`);
    if (!error) {
      return dispatch(getManagerListSuccess(result));
    }
    return dispatch(getManagerListFailure(result));
  };
};
