import {
  GET_FACILITY_COVID_SUCCESS,
  GET_FACILITY_COVID_FAILURE,
} from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const covidSuccess = (data) => {
  return {
    type: GET_FACILITY_COVID_SUCCESS,
    payload: data,
  };
};

const covidFailure = (data) => {
  return {
    type: GET_FACILITY_COVID_FAILURE,
    payload: data,
  };
};

export const getCovidData = (ids) => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(
      `/vaccination_stmt?facility_id=${ids}&vaccination_type=4`
    );
    if (!error) {
      return dispatch(covidSuccess(result));
    }
    return dispatch(covidFailure(result));
  };
};
