import {
  UPDATE_CONTRACTED_HOURS_SUCCESS,
  UPDATE_CONTRACTED_HOURS_FAILURE,
} from "../constants/MyM";
import { putApi } from "../../utils/apiCall";

const updateContractedHoursSuccess = (data) => {
  return {
    type: UPDATE_CONTRACTED_HOURS_SUCCESS,
    payload: data,
  };
};

const updateContractedHoursFailure = (data) => {
  return {
    type: UPDATE_CONTRACTED_HOURS_FAILURE,
    payload: data,
  };
};

export const updateContractedHours = (id, data) => {
  return async (dispatch, getState) => {
    const { error, result } = await putApi(`/edit_facility_contract_hours/${id}`, data);
    if (!error) {
      return dispatch(updateContractedHoursSuccess(result));
    }
    return dispatch(updateContractedHoursFailure(result));
  };
};

