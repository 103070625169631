import { UPDATE_COMMUNICATION_LOGIN_SUCCESS, UPDATE_COMMUNICATION_LOGIN_FAILURE } from "redux/constants/MyM";
import { putApi } from "utils/apiCall";

const putCommunicationLoginSuccess = (data) => {
    return {
        type: UPDATE_COMMUNICATION_LOGIN_SUCCESS,
        payload: data
    }
}

const putCommunicationLoginFailure = (data) => {
    return {
        type: UPDATE_COMMUNICATION_LOGIN_FAILURE,
        payload: data
    }
}

export const UpdateCommunicationLogin = (id, data) => {
    return async (dispatch, getState) => {
      const { error, result } = await putApi(`/communication_login_details/${id}`, data);
      if (!error) {
        return dispatch(putCommunicationLoginSuccess(result));
      }
      return dispatch(putCommunicationLoginFailure(result));
    };
  };
  