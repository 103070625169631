import React, { useEffect, useState } from "react";
import { Row, Col, Input, Card, Button, Dropdown, Menu, Tooltip } from "antd";
import {
  UserOutlined,
  AppstoreOutlined,
  CalendarOutlined,
  ExportOutlined,
  LogoutOutlined,
  BookOutlined,
  ClearOutlined,
  SkinOutlined,
  EditOutlined,
  ScheduleOutlined,
  CommentOutlined,
  FieldTimeOutlined,
  TeamOutlined,
  HomeOutlined
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "index.css";
import { useHistory } from "react-router-dom";
import NavPanel from "../../components/layout-components/NavPanel";
import { autoLogin } from "redux/actions/postAutoLogin.action";
import { cloneWith } from "lodash";
import NavProfileMenu from "../../../src/components/layout-components/NavProfileMenu";
import { AutoLoginLoaderAction } from "redux/actions/autoLoginLoader.action";
import { AutoLoginRedirect } from "utils/MyMUtils";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};
const backgroundStyle = {
  // backgroundImage: `url(${backgroundURL})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundColor: "gray",
};
const logout = () => {
  localStorage.removeItem("user_id");
  localStorage.removeItem("first_name");
  localStorage.removeItem("token");
  localStorage.removeItem("user_type");
  localStorage.removeItem("module_id");
  localStorage.removeItem("ck_name");
};
const menu = (
  <Menu>
    <Menu.Item onClick={logout}>
      <a href="/auth/login">
        <LogoutOutlined /> Logout
      </a>
    </Menu.Item>
  </Menu>
);
const { Search } = Input;
const MainMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const openPopup = () => {
    document.getElementById("myNav").style.width = "100%";
    document.getElementById("myNav").style.transform = "scale(100%)";
  };

  const closeNav = () => {
    document.getElementById("myNav").style.transform = "scale(0%)";
    localStorage.setItem("user_type", "A");
    history.push("/app/dashboards");
  };

  useEffect(() => {
    openPopup();
  }, []);

  const redirectDashboard = (value) => {
    localStorage.setItem("user_type", value);
    history.push("/app/dashboards");
  };

  const redirectStaffMaster = (value) => {
    localStorage.setItem("user_type", value);
    history.push("/app/staff");
    window.location.reload();
  };

  const redirectUserMaster = (value) => {
    localStorage.setItem("user_type", value);
    history.push("/app/user-management");
    window.location.reload();
  };

  const redirectFacilityPortalMaster = (value) => {
    localStorage.setItem("user_type", value);
    history.push("/app/facility-portal");
    window.location.reload();
  };

  const redirectTimesheet = (value) => {
    localStorage.setItem("user_type", value);
    history.push("/app/timesheet");
    window.location.reload();
  };

  const redirectCommunication = (value) => {
    localStorage.setItem("user_type", value);
    history.push("/app/communication-management");
    window.location.reload();
  };

  const T = new Date();
  const d = T.getDate();
  const m = T.getMonth();
  const y = T.getFullYear().toString();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const todayDate = `${d} ${
    monthNames[m].length > 4 ? monthNames[m].substring(0, 3) : monthNames[m]
  } ${y.substring(2)}`;

  const cleaningAudit = () => {
    var user_id = localStorage.getItem("user_id");

    const post = {
      module_id: "18",
      user_id: user_id,
    };
    dispatch(AutoLoginLoaderAction({start: true})).then(() => {
      dispatch(autoLogin(post)).then((record) => {
        if (record.payload.success == true) {
          const caUrl = process.env.REACT_APP_URL_CA;
          var token = record.payload.result.token_id;
          AutoLoginRedirect(`${caUrl}/autologin?token=${token}`)
        } else {
          toast.error(record.payload.message);
        }
      }).finally(() => {
        dispatch(AutoLoginLoaderAction({stop: true}))
      });
    })
  };

  const laundryAudit = () => {
    var user_id = localStorage.getItem("user_id");

    const post = {
      module_id: "19",
      user_id: user_id,
    };
    dispatch(AutoLoginLoaderAction({start: true})).then(() => {
      dispatch(autoLogin(post)).then(({payload}) => {

        if (payload.success == true) {

          const laUrl = process.env.REACT_APP_URL_LA;

          const { luser_id, token_id, user_id } = payload.result;

          AutoLoginRedirect(`${laUrl}/autologin?userid=${user_id}&luserid=${luser_id}&token=${token_id}`)

        } else {
          toast.error(payload.message);
        }
      }).finally(() => {
        dispatch(AutoLoginLoaderAction({stop: true}))
      });
    })
  };

  const education = () => {
    var user_id = localStorage.getItem("user_id");

    const post = {
      module_id: "20",
      user_id: user_id,
    };
    dispatch(AutoLoginLoaderAction({start: true})).then(() => {
      dispatch(autoLogin(post)).then((record) => {
        if (record.payload.success == true) {
          const educationUrl = process.env.REACT_APP_URL_EDU;
          var token = record.payload.result[0].token_id;
          var userid = record.payload.result[0].user_id;
          AutoLoginRedirect(
              `${educationUrl}/autologin?token=${token}&userId=${userid}`
          );
        } else {
          toast.error(record.payload.message);
        }
      }).finally(() => {
        dispatch(AutoLoginLoaderAction({stop: true}))
      });

    })
  };

  const periodical = () => {
    var user_id = localStorage.getItem("user_id");

    const post = {
      module_id: "2",
      user_id: user_id,
    };
    dispatch(AutoLoginLoaderAction({start: true})).then(() => {
      dispatch(autoLogin(post)).then((record) => {
        if (record.payload.success == true) {
          const periodicalUrl = process.env.REACT_APP_URL_PERIODICAL;
          var token = record.payload.result.token_id;
          var userid = record.payload.result.user_id;
          AutoLoginRedirect(`${periodicalUrl}/autologin?token=${token}&userid=${userid}`)
        } else {
          toast.error(record.payload.message);
        }
      }).finally(() => {
        dispatch(AutoLoginLoaderAction({stop: true}))
      });
    })
  };

  return (
    <div>
      <div id="myNav" className="overlay-main-menu">
        <div className="modal_container">
          <Row style={{ backgroundColor: "#144881", alignItems: "center" }}>
            <Col xs={12} md={19}>
              <img
                className="img-fluid"
                src="/img/logo1.png"
                alt=""
                style={{
                  height: "60px",
                  marginTop: "20px",
                  paddingLeft: "10px",
                }}
              />
            </Col>
            <Col
              xs={10}
              md={5}
              style={{
                height: "38px",
                width: "auto",
                // marginTop: "65px",
                // paddingRight: "10px",
                fontSize: "25px",
                cursor: "pointer",
                textAlign: "right",
              }}
            >
             
              <div className="nav-right" >
                <NavProfileMenu />
              </div>
            </Col>
          </Row>
        </div>
       

        <div className="overlay-content-main-menu">
          <div className="modal_container">
            <Row
              gutter={16}
              className="justify-content-center justify-content-md-start ml-0 mr-0"
            >
              <Col xs={20} md={8} lg={6}>
                <a onClick={() => redirectDashboard("A")}>
                  <Card className="card_style" hoverable={true}>
                    <Row>
                      <Col span={6}>
                        <UserOutlined
                          style={{
                            fontSize: "16px",
                            color: "rgb(20, 72, 129)",
                          }}
                        />
                      </Col>
                      <Col>
                        <h4
                          style={{
                            textAlign: "left",
                          }}
                        >
                          Admin
                        </h4>
                        <p>Admin Module</p>
                      </Col>
                    </Row>
                  </Card>
                </a>
              </Col>
              <Col xs={20} md={8} lg={6}>
                <a onClick={() => redirectStaffMaster("H")}>
                  <Card className="card_style" hoverable={true}>
                    <Row>
                      <Col span={6}>
                        <UserOutlined
                          style={{
                            fontSize: "16px",
                            color: "rgb(20, 72, 129)",
                          }}
                        />
                      </Col>
                      <Col>
                        <h4
                          style={{
                            textAlign: "left",
                          }}
                        >
                          HR
                        </h4>
                        <p>HR Module</p>
                      </Col>
                    </Row>
                  </Card>
                </a>
              </Col>
              <Col xs={20} md={8} lg={6}>
                <a onClick={() => redirectUserMaster("U")}>
                  <Card className="card_style" hoverable={true}>
                    <Row>
                      <Col span={6}>
                        <TeamOutlined
                          style={{
                            fontSize: "16px",
                            color: "rgb(20, 72, 129)",
                          }}
                        />
                      </Col>
                      <Col>
                        <h4
                          style={{
                            textAlign: "left",
                          }}
                        >
                          Users
                        </h4>
                        <p>User Management</p>
                      </Col>
                    </Row>
                  </Card>
                </a>
              </Col>
              <Col xs={20} md={8} lg={6}>
                <a onClick={() => redirectFacilityPortalMaster("F")}>
                  <Card className="card_style" hoverable={true}>
                    <Row>
                      <Col span={6}>
                        <HomeOutlined
                          style={{
                            fontSize: "16px",
                            color: "rgb(20, 72, 129)",
                          }}
                        />
                      </Col>
                      <Col>
                        <h4
                          style={{
                            textAlign: "left",
                          }}
                        >
                          Facility
                        </h4>
                        <p>Facility Management</p>
                      </Col>
                    </Row>
                  </Card>
                </a>
              </Col>

              <Col xs={20} md={8} lg={6}>
                <a onClick={() => redirectTimesheet("T")}>
                  <Card className="card_style" hoverable={true}>
                    <Row>
                      <Col span={6}>
                        <FieldTimeOutlined
                          style={{
                            fontSize: "16px",
                            color: "rgb(20, 72, 129)",
                          }}
                        />
                      </Col>
                      <Col>
                        <h4
                          style={{
                            textAlign: "left",
                          }}
                        >
                          Timesheet
                        </h4>
                        <p>Timesheet Module</p>
                      </Col>
                    </Row>
                  </Card>
                </a>
              </Col>
              <Col xs={20} md={8} lg={6}>
                <a onClick={() => redirectCommunication("C")}>
                  <Card className="card_style" hoverable={true}>
                    <Row>
                      <Col span={6}>
                        <CommentOutlined
                          style={{
                            fontSize: "16px",
                            color: "rgb(20, 72, 129)",
                          }}
                        />
                      </Col>
                      <Col>
                        <h4
                          style={{
                            textAlign: "left",
                          }}
                        >
                          Communication
                        </h4>
                        <p>Communication Management</p>
                      </Col>
                    </Row>
                  </Card>
                </a>
              </Col>

              {localStorage.getItem("module_id").includes(18) ? (
                <Col xs={20} md={8} lg={6}>
                  <Card
                    onClick={() => cleaningAudit()}
                    className="card_style"
                    hoverable={true}
                  >
                    <Row>
                      <Col span={6}>
                        <ClearOutlined
                          style={{
                            fontSize: "16px",
                            color: "rgb(20, 72, 129)",
                          }}
                        />
                      </Col>
                      <Col>
                        <h4
                          style={{
                            textAlign: "left",
                          }}
                        >
                          Cleaning Audit
                        </h4>
                        <p>Cleaning Management</p>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ) : (
                ""
              )}
              {localStorage.getItem("module_id").includes(19) ? (
                <Col xs={20} md={8} lg={6}>
                  <Card
                    className="card_style"
                    onClick={() => laundryAudit()}
                    hoverable={true}
                  >
                    <Row>
                      <Col span={6}>
                        <SkinOutlined
                          style={{
                            fontSize: "16px",
                            color: "rgb(20, 72, 129)",
                          }}
                        />
                      </Col>
                      <Col>
                        <h4
                          style={{
                            textAlign: "left",
                          }}
                        >
                          Laundry Audit
                        </h4>
                        <p>Laundry Management</p>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ) : (
                ""
              )}
              {localStorage.getItem("module_id").includes(20) ? (
                <Col xs={20} md={8} lg={6}>
                  <Card
                    className="card_style"
                    onClick={() => education()}
                    hoverable={true}
                  >
                    <Row>
                      <Col span={6}>
                        <EditOutlined
                          style={{
                            fontSize: "16px",
                            color: "rgb(20, 72, 129)",
                          }}
                        />
                      </Col>
                      <Col>
                        <h4
                          style={{
                            textAlign: "left",
                          }}
                        >
                          Education | Toolbox
                        </h4>
                        <p>Education Management</p>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ) : (
                ""
              )}
              {localStorage.getItem("module_id").includes(2) ? (
                <Col xs={20} md={8} lg={6}>
                  <Card
                    className="card_style"
                    onClick={() => periodical()}
                    hoverable={true}
                  >
                    <Row>
                      <Col span={6}>
                        <ScheduleOutlined
                          style={{
                            fontSize: "16px",
                            color: "rgb(20, 72, 129)",
                          }}
                        />
                      </Col>
                      <Col>
                        <h4
                          style={{
                            textAlign: "left",
                          }}
                        >
                          Periodical
                        </h4>
                        <p>Periodical Management</p>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainMenu;
