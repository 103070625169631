import React, { useEffect, useState, Fragment } from "react";
import {
  Table,
  Card,
  Menu,
  Form,
  Button,
  Select,
  Row,
  Col,
  Upload,
  Popover,
  message,
  Tooltip,
  Spin,
} from "antd";
import { EyeOutlined, MailOutlined, UploadOutlined } from "@ant-design/icons";
import { getApiArrayDetails, getPreSignedUrl, isValidArray } from "../../../utils/MyMUtils";
import { useDispatch, useSelector } from "react-redux";
import { getStateDeclarationData } from "redux/actions/stateDeclarationCertificate.action";
import { getUserList } from "redux/actions/getUserList.action";
import { uploadCertificate } from "redux/actions/uploadCertificate.action";
import { uploadStateDeclaration } from "redux/actions/uploadStateDeclaration.action";
import { toast } from "react-toastify";
import Email from "./../mail";

const { Option } = Select;
function StateDeclarationCertificate() {
  const [userId, setUserId] = useState("");
  const [stateData, setstateData] = useState([]);
  const [staffIdsList, setStaffIdsList] = useState("");
  const [staffIdForUpload, setStaffIdForUpload] = useState("");
  const [visibleMail, setVisibleMail] = useState(false);

  const dispatch = useDispatch();
  const stateDeclarationList = useSelector((state) =>
    getApiArrayDetails(state.stateDeclaration.data)
  );
  const getUserListData = useSelector((state) =>
    getApiArrayDetails(state.getUserList.data)
  );

  const fileLoadingState = useSelector((state) => state.fileLoadingReducer.fileLoadingState);

  useEffect(() => {
    dispatch(getUserList());
    // dispatch(getStateDeclarationData());
    // setstateData(stateDeclarationList);
    return () => {};
  }, []);

  // useEffect(() => {
  //   if (stateDeclarationList && stateDeclarationList.length > 2)
  //     setstateData(stateDeclarationList);
  //   return () => { };
  // }, [stateDeclarationList]);
  const getStaffIds = () => {
    if (isValidArray(getUserListData)) {
      let addIds = "";
      getUserListData.map((staff) => {
        addIds += staff.user_id + ",";
      });
      addIds = addIds.substring(0, addIds.length - 1);
      return addIds;
    }
    return null;
  };
  let StaffIds = getStaffIds();
  useEffect(() => {
    setStaffIdsList(StaffIds);
    dispatch(getStateDeclarationData(StaffIds));
  }, [StaffIds]);

  const stateDeclarationUpload = (elm) => {
    setUserId(elm?.user_id);
  };
  const getStateList = () => {
    if (isValidArray(getUserListData)) {
      return getUserListData.map((staff) => {
        return (
          <Fragment key={staff?.user_id}>
            <Option value={staff?.user_id}>
              {staff?.first_name} {staff?.last_name}
            </Option>
          </Fragment>
        );
      });
    }
    return null;
  };
  const handleOnChange = (event) => {
    if (event[event.length - 1] == "0") {
      setStaffIdForUpload(staffIdsList);
      dispatch(getStateDeclarationData(staffIdsList));
    }
    if (!isValidArray(event)) {
      setStaffIdForUpload(staffIdsList);
      dispatch(getStateDeclarationData(staffIdsList));
    } else {
      setStaffIdForUpload(event);
      dispatch(getStateDeclarationData(event));
    }
  };
  const uploadSettings = {
    accept: ".pdf",
    beforeUpload: (file) => {
      if (file.type !== "application/pdf") {
        message.error(`${file.name} is not a pdf file`);
      }
      //   uploadStatement(file.type !== "application/pdf" ? false : true);
      return false;
    },
    onChange: (info) => {
      let selectedFile = info.fileList;
      let file = null;
      let fileName;
      if (selectedFile.length > 0) {
        let fileToLoad = selectedFile[selectedFile.length - 1].originFileObj;
        fileName = fileToLoad.name;
        let reader = new FileReader();
        reader.onload = function (fileEvent) {
          file = fileEvent.target.result;
          let name = fileName.split(".");
          const uploadInfo = {
            filename: name[0],
            file: file,
            certificate_type: "statutory_doc",
          };
          dispatch(uploadCertificate(uploadInfo)).then((record) => {
            const uploadFinal = {
              user_id: userId,
              pdf: record.payload.result.result,
            };
            dispatch(uploadStateDeclaration(uploadFinal)).then((record) => {
              if (record.payload.result.success === true) {
                if (staffIdForUpload) {
                  dispatch(getStateDeclarationData(staffIdForUpload || userId));
                } else {
                  dispatch(getStateDeclarationData(StaffIds));
                }
                toast.success(record.payload.result.message);
              } else {
                toast.error(record.payload.result.message);
              }
              document.querySelector('.ant-upload-list-item-card-actions-btn').click()
            });
          });
        };
        reader.readAsDataURL(fileToLoad);
      }
    },
  };

  const closePopover = () => {
    setVisibleMail(false);
  };

  const setMail = (elm) => {
    setVisibleMail(elm.user_id);
    if (visibleMail === elm.user_id) {
      setVisibleMail(false);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "50%",
      render: (text, record) => {
        return `${text} ${" "}  ${record?.last_name}`;
      },
      sorter: (a, b) =>
        a.first_name.toUpperCase().charCodeAt(0) -
        b.first_name.toUpperCase().charCodeAt(0),
      filters: stateDeclarationList
        ?.map((f) => {
          const name = `${f.first_name} ${f.last_name}`;
          return {
            text: name,
            value: f.user_id,
          };
        })
        .sort(
          (a, b) =>
            a.text.toUpperCase().charCodeAt(0) -
            b.text.toUpperCase().charCodeAt(0)
        ),
      onFilter: (value, record) =>
        record.first_name.includes(value) && record.last_name.includes(value),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "50%",
      render: (_, elm) => (
        <div>
          <span className="mr-3">
            {stateDeclarationList
              .filter((item) => elm.user_id == item.user_id)
              .map((item) => {
                return item?.pdf ? (
                    <Tooltip title="View" color="rgb(20, 72, 129)">
                    <Button onClick={() => getPreSignedUrl(item.pdf)}  >
                        { fileLoadingState !== item.pdf ? <EyeOutlined /> : <Spin size="small" />}
                      </Button>
                    </Tooltip>
                ) : (
                  <a>
                    <Tooltip title="View" color="rgb(20, 72, 129)">
                      <Button disabled>
                        <EyeOutlined style={{ color: "#00000040" }} />
                      </Button>
                    </Tooltip>
                  </a>
                );
              })}
          </span>
          <span className="mr-3">
            {stateDeclarationList
              .filter((item) => elm.user_id == item.user_id)
              .map((item) => {
                return item?.pdf ? (
                  <Popover
                    title="Additional Emails"
                    content={
                      <Email passData={elm} visibleMailprop={closePopover} />
                    }
                    visible={elm.user_id === visibleMail}
                    trigger="click"
                  >
                    <Tooltip title="Mail" color="rgb(20, 72, 129)">
                      <Button onClick={() => setMail(elm)}>
                        <MailOutlined />
                      </Button>
                    </Tooltip>
                  </Popover>
                ) : (
                  <Tooltip title="Mail" color="rgb(20, 72, 129)">
                    <Button disabled>
                      <MailOutlined style={{ color: "#00000040" }} />
                    </Button>
                  </Tooltip>
                );
              })}
          </span>
          <span>
            <Tooltip title="Upload" color="rgb(20, 72, 129)">
              <Upload {...uploadSettings}>
                <Button onClick={() => stateDeclarationUpload(elm)}>
                  <UploadOutlined />
                </Button>
              </Upload>
            </Tooltip>
          </span>
        </div>
      ),
    },
  ];
  return (
    <Form layout="horizontal">
      <Row>
        <Col span={24}>
          <Form.Item>
            <div style={{ float: "right", width: "30%" }}>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Select Staff"
                onChange={handleOnChange}
                showSearch
                optionFilterProp="children"
              >
                <Option value="0">All</Option>
                {getStateList()}
              </Select>
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Table
        loading={!isValidArray(stateDeclarationList)}
        columns={columns}
        dataSource={stateDeclarationList}
        rowKey="id"
      />
    </Form>
  );
}

export default StateDeclarationCertificate;
