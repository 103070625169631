import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import AuthViews from "views/auth-views";
import Loading from "components/shared-components/Loading";
import { useThemeSwitcher } from "react-css-theme-switcher";

export const AuthLayout = ({ isAuthenticated }) => {
  const { status } = useThemeSwitcher();

  if (status === "loading") {
    return <Loading cover="page" />;
  }
console.log(isAuthenticated,"isAuthenticated");
  return (
    <div className="auth-container">
      <Switch>
        {!isAuthenticated ? (
          <Route path="/" component={AuthViews} />
        ) : (
          <Redirect to={"/auth/login"} />
        )}
      </Switch>
    </div>
  );
};

export default AuthLayout;
