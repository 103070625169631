import React, { memo } from "react";
import { Card, Tabs } from "antd";
import { StickyContainer, Sticky } from "react-sticky";
import VehicaleDetail from "./vehicalDetail";

const { TabPane } = Tabs;

const Vehicale = () => {
  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar
          {...props}
          style={{ ...style, zIndex: 1, background: "#fff" }}
        />
      )}
    </Sticky>
  );

  return (
    <Card>
      <StickyContainer>
        <Tabs
          defaultActiveKey="1"
          renderTabBar={renderTabBar}
        >
          <TabPane tab="Vehicle Details" key="1">
            <VehicaleDetail />
          </TabPane>
        </Tabs>
      </StickyContainer>
    </Card>
  );
};

export default memo(Vehicale);
