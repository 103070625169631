import { GET_ADHOC_FACILITY_SUCCESS, GET_ADHOC_FACILITY_FAILURE } from "redux/constants/MyM"
import { getApi } from "utils/apiCall"

const getAllAdhocFacilitySuccess = (data) => {
    return {
        type: GET_ADHOC_FACILITY_SUCCESS,
        payload: data
    }
}

const getAllAdhocFacilityFailure = (data) => {
    return {
        type: GET_ADHOC_FACILITY_FAILURE,
        payload: data
    }
}

export const getAllAdhocFacility = () => {
    return async (dispatch, getState) => {
        const { error, result } = await getApi(`/get_all_adhoc_facility`)
        if (!error) {
            return dispatch(getAllAdhocFacilitySuccess(result))
        }
        return dispatch(getAllAdhocFacilityFailure(result))
    }
}