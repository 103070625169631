import {
  POST_UPLOAD_POLICE_CHECK_SUCCESS,
  POST_UPLOAD_POLICE_CHECK_FAILURE,
  POST_UPLOAD_POLICE_CHECK_REQUEST,
} from "../constants/MyM";
import { postApi } from "../../utils/apiCall";
import { apiToastError, apiToastSuccess } from "../../utils/MyMUtils";

const uploadPoliceCheckSuccess = (data) => {
  return {
    type: POST_UPLOAD_POLICE_CHECK_SUCCESS,
    payload: data,
  };
};

const uploadPoliceCheckFailure = (data) => {
  return {
    type: POST_UPLOAD_POLICE_CHECK_FAILURE,
    payload: data,
  };
};

export const uploadPoliceCheckRequest = () => ({
  type: POST_UPLOAD_POLICE_CHECK_REQUEST,
});

export const uploadPoliceCheck = (uploadInfo) => (dispatch, getState) => {
  dispatch(uploadPoliceCheckRequest());
  return postApi(`/upload_policecheck_certificate`, {
    ...uploadInfo,
  })
    .then((data) => {
      apiToastSuccess(data);
      return dispatch(uploadPoliceCheckSuccess(data));
    })
    .catch((error) => {
      apiToastError(error);
      return dispatch(uploadPoliceCheckFailure(error));
    });
};
