import React, { memo } from "react";
import { Card, Tabs } from "antd";
import { StickyContainer, Sticky } from "react-sticky";
import Contractor from "./contractor";
import ManagementContact from "./managementContact";

const { TabPane } = Tabs;

const ContactDetail = () => {
  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: "#fff" }} />
      )}
    </Sticky>
  );

  return (
    <Card>
      <StickyContainer>
        <Tabs defaultActiveKey="1" renderTabBar={renderTabBar}>
          <TabPane tab="Contractor" key="1">
            <Contractor />
          </TabPane>
          <TabPane tab="Management" key="2">
            <ManagementContact />
          </TabPane>
        </Tabs>
      </StickyContainer>
    </Card>
  );
};

export default memo(ContactDetail);
